import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class OrderEmpty extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button type="button" className="hd-back" />
            <div className="hd-tit">룸서비스 주문</div>
          </div>
        </header>

        <section className="order">
          <div className="no-data active">
            <div className="txt-group">
              <div className="txt">목록이 비어있습니다.</div>
              <div className="txt2">주문할 음식을 담아주세요.</div>
            </div>

            <div className="btn-floating-bottom">
              <Link to="/" className="btn-custom">
                메뉴 담으러 가기
              </Link>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OrderEmpty));
