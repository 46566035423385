import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../assets/css/common.css';
import ClipLoader from 'react-spinners/ClipLoader';

class Spinner extends Component {
  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    if (this.props.loading) {
      this.setState({loading: this.props.loading});
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {loading} = this.state;
    return (
      <React.Fragment>
        <div className="spinner-view">
          <ClipLoader color={'#222'} loading={loading} size={30} />
        </div>
      </React.Fragment>
    );
  }
}

export default Spinner;
