import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {ReactComponent as ArrowRight} from '../../assets/img/ico_arrow_right1.svg';
import * as Utils from '../../shared/util';
import {api} from '../../api';

class NoticeList extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      noticeList: [],
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    this.getNoticeList();
  }

  // -- feature -------------------------------------------------------------------------------
  getNoticeList = () => {
    api
      .get(`/v1/notice`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({noticeList: data});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getNoticeList');
      });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  renderNoticeList = () => {
    const {noticeList} = this.state;
    const renderItem = [];

    noticeList.forEach((item, idx) => {
      renderItem.push(
        <Link key={`notice_list_${item.uuid}`} to={`/notice/${item.uuid}`}>
          <li style={{paddingLeft: '16px', paddingRight: '16px', borderBottom: '8px solid #F7F7F7'}}>
            <div className="tit notice-title">{item.title}</div>
            <div className="notice-date">{Utils.getDateString(item.show_date)}</div>
            <ArrowRight />
          </li>
        </Link>,
      );
    });

    return <ol className="list-coupon-history">{renderItem}</ol>;
  };

  render() {
    const {noticeList} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">공지사항</div>
          </div>
        </header>

        <section className="notice">
          <div className="wrap" style={{padding: 0}}>
            {noticeList && this.renderNoticeList()}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(NoticeList));
