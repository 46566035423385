import {SET_MENU_SCROLL_TOP, SET_MENU_POPUP_SHOW_1, SET_MENU_POPUP_SHOW_2, SET_MENU_POPUP_SHOW_3, SET_MENU_POPUP_SHOW_EMERGENCY, SET_MENU_POPUP_SHOW_HOLIDAY} from '../actionTypes';

export const setMenuScrollTop = value => {
  return {
    type: SET_MENU_SCROLL_TOP,
    value,
  };
};

export const setMenuPopupShow1 = value => {
  return {
    type: SET_MENU_POPUP_SHOW_1,
    value,
  };
};

export const setMenuPopupShow2 = value => {
  return {
    type: SET_MENU_POPUP_SHOW_2,
    value,
  };
};

export const setMenuPopupShow3 = value => {
  return {
    type: SET_MENU_POPUP_SHOW_3,
    value,
  };
};

export const setMenuAlertEmergency = value => {
  return {
    type: SET_MENU_POPUP_SHOW_EMERGENCY,
    value,
  };
};

export const setMenuAlertHoliday = value => {
  return {
    type: SET_MENU_POPUP_SHOW_HOLIDAY,
    value,
  };
};
