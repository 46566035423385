import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {ReactComponent as ArrowRight} from '../../assets/img/ico_arrow_right1.svg';
import * as Utils from '../../shared/util';
import {
  APP_KEY_USER_UUID,
  CONCIERGE_STATE_40_SERVICE_DONE,
  CONCIERGE_STATE_7_COMPLETE,
  ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY,
  ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND,
} from '../../shared/Constants';
import * as Service from '../../shared/apiService';
import {getCurrentConciergePoidByLogin, getCurrentPoidByLogin} from '../../shared/apiService';

class OrderMain extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      poidIngCountOfRoom: 0,
      poidIngCountOfConcierge: 0,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    Utils.getLocalStorageSync(APP_KEY_USER_UUID).then(userId => {
      this.getConciergeIngPoidList(userId);
      this.getIngPoidList(userId);
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getConciergeIngPoidList = userId => {
    Service.getCurrentConciergePoidByLogin(userId)
      .then(data => {
        console.log(data);
        let cnt = 0;
        data.forEach((item, idx) => {
          if (item.state > 0 && item.state < CONCIERGE_STATE_40_SERVICE_DONE) cnt++;
        });
        this.setState({poidIngCountOfConcierge: cnt});
      })
      .catch(e => {
        console.log(e);
      });
  };

  getIngPoidList = userId => {
    Service.getPoidList(userId)
      .then(data => {
        console.log(data);
        let cnt = 0;
        let prevPoid = '';
        data.forEach((item, idx) => {
          if (item.poid_state > 0 && item.poid_state < ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY && item.poid != prevPoid) {
            cnt++;
            prevPoid = item.poid;
          }
        });
        this.setState({poidIngCountOfRoom: cnt});
      })
      .catch(e => {
        console.log(e);
      });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------

  render() {
    const {poidIngCountOfRoom, poidIngCountOfConcierge} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                //this.props.history.goBack();
                // - TODO: 결제완료후에 이전 히스토리 문제로 절대값 루뜨로 가야함.
                // - TODO: 추후 이부분이 문제가 된다면 이전path를 기억해 놓고 특별 처리를 하도록 한다.
                this.props.history.replace('/');
              }}></button>
            <div className="hd-tit">주문내역</div>
          </div>
        </header>

        <section className="order-main">
          <div className="wrap" style={{padding: 0}}>
            <ol className="list-coupon-history">
              <Link to="/order/view">
                <li style={{paddingLeft: '16px', paddingRight: '16px', borderBottom: '8px solid #F7F7F7'}}>
                  <div className="tit notice-title">룸서비스 주문내역</div>
                  <div className="order-count-view">
                    <div className="order-count">{poidIngCountOfRoom}</div>
                  </div>
                  <ArrowRight />
                </li>
              </Link>
              <Link to="/concierge/order/view">
                <li style={{paddingLeft: '16px', paddingRight: '16px', borderBottom: '8px solid #F7F7F7'}}>
                  <div className="tit notice-title">컨시어지 주문내역</div>
                  <div className="order-count-view">
                    <div className="order-count">{poidIngCountOfConcierge}</div>
                  </div>
                  <ArrowRight />
                </li>
              </Link>
              <Link
                to="#"
                onClick={() => {
                  Utils.showAlertConfirm('[ 준비중 ]\n해당 서비스는 오픈 준비 중입니다.\n조금만 기다려주세요.');
                }}>
                <li style={{paddingLeft: '16px', paddingRight: '16px', borderBottom: '8px solid #F7F7F7'}}>
                  <div className="tit notice-title">공구위크 주문내역</div>
                  <div className="order-count-view">
                    <div className="order-count">0</div>
                  </div>
                  <ArrowRight />
                </li>
              </Link>
            </ol>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OrderMain));
