import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';

class LogOut extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <div className="hd-tit">로그아웃</div>
          </div>
        </header>

        <section className="payment-complete">
          <div className="complete-message">
            <div className="txt2">로그아웃 하시겠습니까?</div>
          </div>

          <div className="btn-floating-bottom">
            <div className="btn-group">
              <Link to="/setting" replace={true} className="btn btn-custom btn-custom-v1">
                취소
              </Link>
              <a
                className="btn btn-custom"
                onClick={() => {
                  Utils.processLogOut();
                  Utils.showAlertConfirm('로그아웃 되었습니다.').then(result => {
                    this.props.history.replace('/');
                  });
                }}>
                확인
              </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(LogOut));
