import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Login from './pages/login/Login';
import LogOut from './pages/login/LogOut';
import Withdraw from './pages/login/Withdraw';
import Terms from './pages/login/Terms';
import AuthPhone from './pages/login/AuthPhone';
import ReAuthPhone from './pages/login/ReAuthPhone';
import OnBoarding from './pages/login/OnBoarding';
import OnBoardingLogin from './pages/login/OnBoardingLogin';
import Menu from './pages/menu/Menu';
import MenuDetail from './pages/menu/MenuDetail';
import Payment from './pages/payment/Payment';
import PaymentComplete from './pages/payment/PaymentComplete';
import PaymentFail from './pages/payment/PaymentFail';
import OrderMain from './pages/order/OrderMain';
import Order from './pages/order/Order';
import OrderEmpty from './pages/order/OrderEmpty';
import OrderHistory from './pages/order/OrderHistory';
import OrderView from './pages/order/OrderView';
import OrderViewDetail from './pages/order/OrderViewDetail';
import OrderUseCoupon from './pages/order/OrderUseCoupon';
import OrderUsePoint from './pages/order/OrderUsePoint';
import MySetting from './pages/setting/MySetting';
import MyCoupon from './pages/setting/MyCoupon';
import MyPoint from './pages/setting/MyPoint';
import MyAddress from './pages/setting/MyAddress';
import MyInfo from './pages/setting/MyInfo';
import AddrSplash from './pages/setting/AddrSplash';
import CustomerCenter from './pages/setting/CustomerCenter';
import BrandStory from './pages/setting/BrandStory';
import CompanyInfo from './pages/setting/CompanyInfo';
import TermsView from './pages/setting/TermsView';
import Event from './pages/setting/Event';
import RequestList from './pages/setting/RequestList';
import EventSplash from './pages/setting/EventSplash';
import NoticeList from './pages/setting/NoticeList';
import NoticeView from './pages/setting/NoticeView';

import ConciergeMenu from './pages/concierge/ConciergeMenu';
import ConciergeMenuDetail from './pages/concierge/ConciergeMenuDetail';
import ConciergeOrder from './pages/concierge/ConciergeOrder';
import ConciergeOrderView from './pages/concierge/ConciergeOrderView';
import ConciergeOrderHistory from './pages/concierge/ConciergeOrderHistory';
import ConciergeOrderViewDetail from './pages/concierge/ConciergeOrderViewDetail';

import CommunityMenu from './pages/community/CommunityMenu';
import CommunityListView from './pages/community/CommunityListView';

import Home from './pages/home/Home';

import './assets/css/common.css';

class App extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  componentDidMount() {
    /* RN 메시지 수신 처리 부분 (iOS, AOS Object가 상이하여 별로 처리 필요) */
    if (window.ReactNativeWebView) {
      /* android */
      document.addEventListener('message', message => {
        this.handleRnMessage(message.data);
      });

      /* ios */
      window.addEventListener('message', message => {
        this.handleRnMessage(message.data);
      });
    }

    // 콘솔창 warn log 제거
    console.warn = function no_console() {};
  }

  /* RN에서 전달 메시지 실제 처리 부분 */
  handleRnMessage = msg => {
    console.log(msg);
    switch (msg) {
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={'/home'} />} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/menu/detail" component={MenuDetail} />
          <Route exact path="/event/splash" component={EventSplash} />

          <Route exact path="/login/terms" component={Terms} />
          <Route exact path="/login/authPhone" component={AuthPhone} />
          <Route exact path="/login/ReAuthPhone" component={ReAuthPhone} />
          <Route exact path="/login/:email" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={LogOut} />
          <Route exact path="/withdraw" component={Withdraw} />
          <Route exact path="/onBoarding" component={OnBoarding} />
          <Route exact path="/onBoardingLogin" component={OnBoardingLogin} />

          <Route exact path="/payment/:mode" component={Payment} />
          <Route exact path="/paymentComplete/:mode" component={PaymentComplete} />
          <Route exact path="/paymentFail/:mode" component={PaymentFail} />
          <Route exact path="/main/order" component={OrderMain} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/order/empty" component={OrderEmpty} />
          <Route exact path="/order/history" component={OrderHistory} />
          <Route exact path="/order/view" component={OrderView} />
          <Route exact path="/order/view/detail" component={OrderViewDetail} />
          <Route exact path="/order/coupon/:mode" component={OrderUseCoupon} />
          <Route exact path="/order/point" component={OrderUsePoint} />
          <Route exact path="/setting" component={MySetting} />
          <Route exact path="/setting/coupon" component={MyCoupon} />
          <Route exact path="/setting/point" component={MyPoint} />
          <Route exact path="/setting/address" component={MyAddress} />
          <Route exact path="/setting/myinfo" component={MyInfo} />
          <Route exact path="/setting/addrSplash" component={AddrSplash} />
          <Route exact path="/setting/addrSplash/:id" component={AddrSplash} />
          <Route exact path="/setting/cs" component={CustomerCenter} />
          <Route exact path="/setting/bs" component={BrandStory} />
          <Route exact path="/setting/company" component={CompanyInfo} />
          <Route exact path="/setting/terms" component={TermsView} />
          <Route exact path="/setting/request" component={RequestList} />
          <Route exact path="/notice/:uuid" component={NoticeView} />
          <Route exact path="/notice" component={NoticeList} />
          <Route exact path="/event" component={Event} />

          {/* 컨시어지 서비스 */}
          <Route exact path="/concierge/menu" component={ConciergeMenu} />
          <Route exact path="/concierge/menu/detail" component={ConciergeMenuDetail} />
          <Route exact path="/concierge/order" component={ConciergeOrder} />
          <Route exact path="/concierge/order/view" component={ConciergeOrderView} />
          <Route exact path="/concierge/order/history" component={ConciergeOrderHistory} />
          <Route exact path="/concierge/order/view/detail" component={ConciergeOrderViewDetail} />

          {/* 커뮤니티 서비스 */}
          <Route exact path="/community/menu" component={CommunityMenu} />
          <Route exact path="/community/menu/detail" component={CommunityListView} />
        </Switch>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(App));
