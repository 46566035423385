import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as Logo} from '../../assets/img/img_logo2.svg';
import {ReactComponent as IcoKakao} from '../../assets/img/ico_kakao1.svg';
import {ReactComponent as IcoNaver} from '../../assets/img/ico_naver1.svg';
import {
  API_SERVER,
  APP_KEY_SNS_TYPE,
  APP_KEY_USER_STATUS,
  APP_KEY_TOID,
  APP_KEY_POID,
  APP_KEY_USER_ROOM,
  APP_KEY_POID_CONCIERGE,
  APP_KEY_USER_FORCE_LOGOUT,
  APP_KEY_USER_ZONE_CODE,
  DEFAULT_ZONE_CODE,
} from '../../shared/Constants';
import * as Utils from '../../shared/util';
import {api} from '../../api';
import * as Service from '../../shared/apiService';
import {getLocalStorageSync} from '../../shared/util';
import {
  getConciergePoidByUser,
  getConciergeToidByUser,
  getCurrentConciergePoidByLogin,
  getCurrentPoidByLogin,
  getPoidByLogin,
  getPoidList,
  getPoidListByLogin,
  getToidList,
} from '../../shared/apiService';

class Login extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      currentOS: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const {params} = this.props.match;

    // - 강제로그아웃 처리
    Utils.setLocalStorage(APP_KEY_USER_FORCE_LOGOUT, 'N');

    // - 디폴트 구역 정보
    Utils.setLocalStorage(APP_KEY_USER_ZONE_CODE, DEFAULT_ZONE_CODE);

    // - TODO: 이메일과 함께 플랫폼으로 부여받은 토큰 정보도 함께 수신하여 DB의 내용과 매칭하여 동일하면 정말 로그인 성공으로 처리 하도록 한다. (현재는 시간 관계상 이메일만 들어오면 로그인으로 간주)
    if (params.email !== undefined) {
      if (params.email === 'fail') {
        // 로그인 실패
        Utils.showAlertConfirmType2('로그인에 실패 하였습니다.');
      } else {
        // SNS사이트에서 넘어오면서 로그인 성공
        console.log('## 로그인 처리 #############');
        console.log(params.email);

        const email = params.email;
        const snsType = Utils.getLocalStorage(APP_KEY_SNS_TYPE);
        console.log(email);
        console.log(snsType);

        // - 이메일 + SNS타입 사용자 정보 가져오기
        this.getUserInfo(email, snsType).then(() => {
          const status = this.state.userInfo.status;
          const alias = this.state.userInfo.nick_name;
          const uuid = this.state.userInfo.uuid;
          const room_id = this.state.userInfo.room_id;
          const zone_code = this.state.userInfo.zone_code;
          console.log('[getUserInfo] status : ' + status);

          // - 룸정보 셋팅
          if (room_id !== null && room_id !== undefined && room_id.length > 0) {
            Utils.setLocalStorage(APP_KEY_USER_ROOM, room_id);
          } else {
            console.log('room_id is Null.');
          }

          // - POID 셋팅
          Utils.getLocalStorageSync(APP_KEY_POID).then(result => {
            console.log('[Check Current POID]');
            if (result === null) {
              Service.getCurrentPoidByLogin(uuid).then(result => {
                if (result.length > 0) {
                  console.log(result[0].toid);
                  result[0].toid !== undefined && Utils.setLocalStorage(APP_KEY_POID, result[0].toid);
                } else {
                  console.log('Nothing POID.');
                }
              });
            } else {
              console.log(result);
            }
          });

          // - CONCIERGE POID 셋팅
          Utils.getLocalStorageSync(APP_KEY_POID_CONCIERGE).then(result => {
            console.log('[Check Current Concierge POID]');
            if (result === null) {
              Service.getCurrentConciergePoidByLogin(uuid).then(result => {
                if (result.length > 0) {
                  console.log(result[0].toid);
                  result[0].toid !== undefined && Utils.setLocalStorage(APP_KEY_POID_CONCIERGE, result[0].toid);
                } else {
                  console.log('Nothing Concierge POID.');
                }
              });
            } else {
              console.log(result);
            }
          });

          // -1: 휴먼계정 0: 회원가입(비활성) 1: 약관동의&본인인증 회원가입 (활성)
          if (status === 0) {
            Utils.processLogIn(email, alias, status, uuid, zone_code);
            this.props.history.replace('/login/terms');
          } else if (status === 1) {
            Utils.processLogIn(email, alias, status, uuid, zone_code);
            this.props.history.replace('/');
          } else {
            // TODO: 추후 휴먼계정 사용자 처리 프로세스..
          }
        });
      }
    } else {
      // - 부룸앱내에서 로그인 시도
      const user_state = Utils.getLocalStorage(APP_KEY_USER_STATUS);
      if (user_state === 0) {
        this.props.history.replace('/login/terms');
      } else if (user_state === 1) {
        this.props.history.replace('/');
      }
    }

    // OS정보
    this.setState({currentOS: Utils.getOS()});

    // Apple 로그인 초기화
    window.AppleID.auth.init({
      clientId: 'kr.vooroom.user.app',
      scope: 'email',
      redirectURI: 'https://api.vooroom.kr/v1/oauth/apple',
      state: 'vooroom_apple',
      usePopup: false,
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getUserInfo = async (email, type) => {
    await api
      .get(`/v1/user/get/info?email=${email}&type=${type}`)
      .then(res => {
        console.log('[OK] getUserInfo');
        const {data} = res;
        console.log(data);
        this.setState({userInfo: data[0]});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getUserInfo');
      });
  };

  // -- handler -------------------------------------------------------------------------------
  handleAppleLogin = () => {
    if (window.AppleID !== null) {
      Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'apple');
      window.AppleID.auth.signIn();
    } else {
      console.log('[handleAppleLogin] AppleID is Null');
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {currentOS} = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v2">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">간편로그인</div>
          </div>
        </header>
        <section className="login">
          <div className="wrap" style={{paddingBottom: '0px'}}>
            <Logo className="img-logo" />

            <ol className="list-sns-login">
              <li>
                <a
                  href={API_SERVER + '/v1/oauth/login?type=kakao'}
                  className="btn-sns-login btn-sns-login-kakao"
                  onClick={() => {
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'kakao');
                  }}>
                  <IcoKakao style={{marginRight: '6px'}} />
                  카카오톡으로 계속하기
                </a>
              </li>
              <li>
                <a
                  href={API_SERVER + '/v1/oauth/login?type=naver'}
                  className="btn-sns-login btn-sns-login-naver"
                  onClick={() => {
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'naver');
                  }}>
                  <IcoNaver style={{marginRight: '0px', marginTop: '3px'}} />
                  네이버로 계속하기
                </a>
              </li>
              {currentOS === 'ios' && (
                <li className="btn-sns-login-apple">
                  <a
                    onClick={() => {
                      this.handleAppleLogin();
                    }}>
                    <img src={require('../../assets/img/ico_login_apple.png').default} className="btn-sns-login" />
                  </a>
                </li>
              )}
              {/*<li>
                <a
                  className="btn-sns-login btn-sns-login-test"
                  onClick={() => {
                    localStorage.removeItem(APP_KEY_TOID);
                    localStorage.removeItem(APP_KEY_POID);
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'kakao');
                    Utils.processLogIn('test@test.com', '테스트아이디', 1, 17);
                    this.props.history.replace('/');
                  }}>
                  <IcoTest style={{marginRight: '6px'}} />
                  테스트ID로 계속하기
                </a>
              </li>*/}
            </ol>
          </div>
          <div
            className="login-onboarding-bottom"
            onClick={() => {
              this.props.history.replace('/');
            }}>
            조금 더 둘러볼게요
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Login));
