import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {ReactComponent as AptName1} from '../../assets/img/apt_splash_1.svg';
import {ReactComponent as AptName2} from '../../assets/img/apt_splash_2.svg';
import {ReactComponent as AptName3} from '../../assets/img/apt_splash_3.svg';
import {ReactComponent as AptName4} from '../../assets/img/apt_splash_4.svg';
import * as Utils from '../../shared/util';

class AddrSplash extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      aptCode: '',
      aptName: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // - 아파트 코드 (내설정)
    const mAptCode = this.props.match.params.id;
    console.log(mAptCode);
    this.setState({aptCode: mAptCode});

    if (mAptCode) {
      setTimeout(() => {
        this.props.history.replace('/setting');
        Utils.showAlertConfirm('정보가 수정 되었습니다.');
      }, 3000);
    } else {
      // - 아파트 네임 (메인화면)
      if (this.props.location.state !== undefined) {
        const mAptName = this.props.location.state.aptName;
        console.log(mAptName);
        this.setState({aptName: mAptName}, () => {
          setTimeout(() => {
            this.props.history.replace('/menu');
          }, 3000);
        });
      }
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {aptCode, aptName} = this.state;
    return (
      <React.Fragment>
        {(aptCode === '4_DT2_G1_A1' || aptName === '동탄역린스트라우스' || aptCode === '4_DT2_G1_S5' || aptName === '동탄역앨리스빌') && (
          <div className="apt_splash apt_splash_img1">
            <AptName1 style={{marginTop: '150px'}} />
          </div>
        )}
        {(aptCode === '4_DT2_G1_A2' || aptName === '동탄역시범한화꿈에그린프레스티지') && (
          <div className="apt_splash apt_splash_img2">
            <AptName2 style={{marginTop: '150px'}} />
          </div>
        )}
        {(aptCode === '4_DT2_G1_A3' || aptName === '동탄역시범더샵센트럴시티') && (
          <div className="apt_splash apt_splash_img3">
            <AptName3 style={{marginTop: '150px'}} />
          </div>
        )}
        {(aptCode === '4_DT2_G1_A4' || aptName === '동탄역롯데캐슬') && (
          <div className="apt_splash apt_splash_img4">
            <AptName4 style={{marginTop: '150px'}} />
          </div>
        )}
        {aptName === 'world_cup_event' && <div className="apt_splash event_splash_img"></div>}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(AddrSplash));
