import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';

class CompanyInfo extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">사업자 정보</div>
          </div>
        </header>

        <section className="setting-tv">
          <div className="setting-tv-wrap">
            <div className="setting-tv-text" style={{paddingLeft: '8px'}}>
              <br />
              <b>상호</b> : 주식회사 부룸
              <br />
              <br />
              <b>사업자등록번호</b> : 591-87-02499
              <br />
              <br />
              <b>통신판매번호</b> : 2022-화성동탄-1595 (통신판매업)
              <br />
              <br />
              <b>대표자명</b> : 장영훈
              <br />
              <br />
              <b>대표 전화번호</b> : 0507-1350-8303
              <br />
              <br />
              <b>판매방식</b> : 인터넷
              <br />
              <br />
              <b>취급품목</b> : 건강/식품, 기타
              <br />
              <br />
              <b>전자우편</b> : jaybee@vooroom.kr
              <br />
              <br />
              <b>통신판매업 신고일자</b> : 20220809
              <br />
              <br />
              <b>사업장 소재지</b>
              <br />
              경기도 화성시 동탄대로 635 SH타임스퀘어1 7층 701-4호
              <br />
              <br />
              <b>인터넷 도메인</b>
              <br />
              vooroom.kr
              <br />
              vooroom-concierge.kr
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(CompanyInfo));
