import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Service from '../../shared/apiService';
import * as Utils from '../../shared/util';
import {
  API_SERVER,
  APP_KEY_TOID,
  APP_KEY_TOID_CONCIERGE,
  APP_KEY_USER_ROOM,
  APP_KEY_USER_SETTING_BELL,
  APP_KEY_USER_UUID,
  APP_KEY_USER_ZONE_CODE,
  NICE_PAY_CLIENT_ID,
} from '../../shared/Constants';
import {api} from '../../api';
import {phoneRex} from '../../shared/util';

class Payment extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      basketList: [],
      couponPrice: 0,
      showHeader: true,
      aptName: '',
      aptDong: '',
      aptHo: '',
      isEmergency: null, // 비상모드 : 해당 구역 전체 서비스 불가
      isSangga: false,
      isBell: true,
      isEventMode: false, // 추석 이벤트
      payMode: null,
      roomServiceCharge: 1000,
      isOnlyTogetherProduct: false, // 공동 구매 제품만 존재
      myRealName: null, // 사용자 실제 이름
      phone: '', // 사용자 휴대폰 정보
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // - 네이티브 헤더 숨기기
    Utils.sendToRnMessage('RN_PAY_HEADER_CLOSE');

    let payMode = this.props.match.params.mode;
    this.setState({payMode: payMode});

    if (payMode === 'concierge') {
      this.getConciergeMyBasket();
    } else {
      this.getMyBasket();
    }

    // - 사용자 정보 가져오기
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    if (uuid) {
      Service.getUserInfoByUuid(uuid).then(result => {
        console.log(result);
        console.log('사용자 실제 이름 : ' + result.real_name);
        result.real_name && this.setState({myRealName: result.real_name});
        result.phone && this.setState({phone: Utils.getDecryptPhoneNumber(result.phone)});
      });
    } else {
      console.log('uuid is NULL');
    }

    // - 구역 비상선언 정보 가져오기
    this.setState({zoneCode: Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE)}, () => {
      Service.getZoneInfo(this.state.zoneCode).then(result => {
        this.setState({zoneInfo: result});

        if (result.is_emergency !== 'N') {
          this.setState({isEmergency: result.is_emergency});
        }
      });
    });

    // 쿠폰 사용 금액
    const useCoupon = this.props.location.state ? this.props.location.state.useCoupon : undefined;
    if (useCoupon !== undefined) {
      this.setState({couponPrice: useCoupon});
    }

    //  - 룸정보
    Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
      if (rInfo) {
        const parseInfo = rInfo.split('/');
        if (parseInfo.length > 2) {
          this.setState({aptName: parseInfo[0]}, () => {
            if (parseInfo[0] === '동탄역앨리스빌') {
              this.setState({isSangga: true});
            }
          });
          this.setState({aptDong: parseInfo[1]});
          this.setState({aptHo: parseInfo[2]});
        }
      }
    });

    // - 호출 밸정보
    Utils.getLocalStorageSync(APP_KEY_USER_SETTING_BELL).then(result => {
      if (result && result === 'N') {
        this.setState({isBell: false});
        console.log('isBell false');
      } else {
        console.log('isBell true');
      }
    });
  }

  // -- feature -------------------------------------------------------------------------------

  isConciergePay = () => {
    const {payMode} = this.state;
    return payMode === 'concierge';
  };

  requestPay = payPrice => {
    const {isEmergency, isOnlyTogetherProduct} = this.state;
    const myToid = this.isConciergePay() ? Utils.getLocalStorage(APP_KEY_TOID_CONCIERGE) : Utils.getLocalStorage(APP_KEY_TOID);
    const userId = Utils.getLocalStorage(APP_KEY_USER_UUID);
    const nowDate = Utils.getDateTimeString();
    const myUuid = myToid + '-' + userId + '-' + nowDate;
    const mGoodsName = this.isConciergePay() ? '부룸 컨시어지' : '부룸 룸서비스';

    // - 비상선언 (공동구매 제외)
    if (isOnlyTogetherProduct === false && isEmergency && this.isConciergePay() === false) {
      if (isEmergency !== 'N') {
        console.log('[비상모드] ' + isEmergency);
        switch (isEmergency) {
          case 'Y':
            Utils.showEmergencyAlertPopup(); // - 비상선언
            return;
          case 'J':
            Utils.showWeatherDelayAlertPopup(); // - 기상지연 (오픈)
            return;
          case 'F':
            Utils.showOrderHeavyAlertPopup(); // - 주문폭주
            return;
          case 'M':
            Utils.showEmergencyAlertPopupByManager(); // - (매니저 이슈) 비상선언
            return;
          case 'W':
            Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 비상선언
            return;
          default:
            break;
        }
      }
    }

    window.AUTHNICE.requestPay({
      clientId: NICE_PAY_CLIENT_ID,
      method: 'card',
      orderId: myUuid,
      amount: payPrice,
      goodsName: mGoodsName,
      returnUrl: API_SERVER + '/v1/pay/serverPayAuth', // - API를 호출할 Endpoint 입력
      fnError: function (result) {
        console.log('[requestPay] : ' + result.errorMsg);
        //alert("개발자확인용 : " + result.errorMsg + "");
      },
    });

    // RN PAY HEADER SHOW
    this.setState({showHeader: false});
    Utils.sendToRnMessage('RN_PAY_HEADER_SHOW');
  };

  getMyBasket = () => {
    Service.getMyBasket().then(result => {
      console.log(result);
      this.setState({basketList: result});

      // 공동구매 상품 체크
      let togetherCount = 0;
      let eventProductCount = 0;
      for (const item of result) {
        if (Utils.isTogetherProduct(item.product_id)) {
          togetherCount++;
        }
        // 추석 이벤트 상품 체크
        if (Utils.isEventProduct(item.product_id)) {
          eventProductCount++;
        }
      }

      // 공동구매 상품만 있는 경우 배달료 무료
      if (togetherCount === result.length) {
        console.log('ONLY 공동구매 상품만 존재.');
        this.setState({roomServiceCharge: 0});
        this.setState({isOnlyTogetherProduct: true});
        this.setState({payMode: 'together'});
      } else {
        console.log('룸서비스 상품 & 공동구매 상품 함께 존재.');
        this.setState({isOnlyTogetherProduct: false});
      }

      // 이벤트 상품만 존재시 배달료 0원
      if (eventProductCount === result.length) {
        console.log('ONLY 추석 이벤트 상품만 존재.');
        this.setState({roomServiceCharge: 0});
        this.setState({isEventMode: true});
      }
    });
  };

  getConciergeMyBasket = () => {
    Service.getConciergeMyBasket().then(result => {
      console.log(result);
      this.setState({basketList: result});
    });
  };

  calcTotalPrice = () => {
    const {basketList} = this.state;
    let mTotalPrice = 0;
    basketList.forEach((item, index) => {
      mTotalPrice += item.price * item.count;
    });
    console.log('[calcTotalPrice] ' + mTotalPrice);
    return mTotalPrice;
  };

  calcTotalCount = () => {
    const {basketList} = this.state;
    let mTotalCount = 0;
    basketList.forEach((item, index) => {
      mTotalCount += item.count;
    });
    console.log('[calcTotalCount] ' + mTotalCount);

    return mTotalCount;
  };

  fianlPayPrice = () => {
    const {couponPrice, roomServiceCharge} = this.state;
    let fee = roomServiceCharge;
    if (this.isConciergePay()) fee = 0;

    return this.calcTotalPrice() + fee - couponPrice;
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {basketList, couponPrice, showHeader, aptName, aptDong, aptHo, isSangga, isBell, payMode, roomServiceCharge, isOnlyTogetherProduct, myRealName, phone, isEventMode} =
      this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">결제하기</div>
          </div>
        </header>

        <section className="payment">
          {payMode !== 'concierge' && (
            <div className="box-payment-discount">
              <ol className="list-payment-discount">
                <li>
                  <div className="discount-type">
                    <button
                      type="button"
                      className="btn-discount"
                      onClick={() => {
                        if (isEventMode) {
                          // 이벤트 모드는 쿠폰 적용 X
                          Utils.showAlertConfirm('이벤트 상품은 쿠폰 사용되지 않습니다.');
                        } else {
                          this.props.history.replace('/order/coupon/' + payMode);
                        }
                      }}>
                      쿠폰함
                    </button>
                    <div className="discount-label">쿠폰 사용 금액</div>
                  </div>

                  <div className="discount-price">
                    <span>-{Utils.wonPriceStr(couponPrice)}</span>원
                  </div>
                </li>

                {/*<li>
                <div className="discount-type">
                  <button
                    type="button"
                    className="btn-discount"
                    onClick={() => {
                      this.props.history.push("/order/point");
                    }}
                  >
                    포인트
                  </button>
                  <div className="discount-label">포인트 사용 금액</div>
                </div>

                <div className="discount-price">
                  <span>2,000</span>원
                </div>
              </li>*/}
              </ol>
            </div>
          )}

          <div className="box-order-info">
            <div className="list-order-info">
              <li>
                <div className="tit">총 수량</div>
                <div className="txt">
                  <strong>{basketList && this.calcTotalCount()}</strong>개
                </div>
              </li>

              <li>
                <div className="tit">상품금액</div>
                <div className="txt">
                  <strong>{basketList && Utils.wonPriceStr(this.calcTotalPrice())}</strong>원
                </div>
              </li>

              <li>
                {this.isConciergePay() ? (
                  <>
                    <div className="tit">컨시어지 요금</div>
                    <div className="txt">
                      <strong>0</strong>원
                    </div>
                  </>
                ) : (
                  <>
                    <div className="tit">룸서비스 요금</div>
                    <div className="txt">
                      <strong>{Utils.wonPriceStr(roomServiceCharge)}</strong>원
                    </div>
                  </>
                )}
              </li>

              {couponPrice > 0 && (
                <li>
                  <div className="tit">쿠폰 사용 금액</div>
                  <div className="txt">
                    <strong>-1,000</strong>원
                  </div>
                </li>
              )}

              <li className="v1">
                <div className="tit">룸정보</div>
                <div className="txt2">
                  <p>{aptName}</p>
                  <p>
                    {isSangga ? (
                      <span>{aptDong}</span>
                    ) : (
                      <>
                        <span>{aptDong} 동</span>
                        <span>{aptHo} 호</span>
                      </>
                    )}
                  </p>
                </div>
              </li>

              {isOnlyTogetherProduct && (
                <li className="v1">
                  <div className="tit">수령인</div>
                  <div className="txt2">
                    <p style={{marginTop: '-8px'}}>{myRealName}</p>
                  </div>
                </li>
              )}

              {isOnlyTogetherProduct && (
                <li className="v1">
                  <div className="tit">연락처</div>
                  <div className="txt2">
                    <p style={{marginTop: '-8px'}}>{Utils.phoneRex(phone)}</p>
                  </div>
                </li>
              )}

              {!isBell && !this.isConciergePay() && !isOnlyTogetherProduct && (
                <li className="v1">
                  <div className="tit">요청사항</div>
                  <div className="txt2">
                    <p style={{marginTop: '-8px'}}>세대 현관문 벨 호출 X</p>
                  </div>
                </li>
              )}
            </div>
          </div>
          <div className="box-order-total">
            <div className="order-info">
              <ol className="list-total-price">
                <li>
                  <div className="tit">총 결제금액</div>
                  <div className="txt">
                    <strong>{basketList && Utils.wonPriceStr(this.fianlPayPrice())}</strong>원
                  </div>
                </li>
              </ol>

              {!this.isConciergePay() && (
                <div className="order-info-etc">
                  * 메뉴는 5개, 총 주문수량은 8개 이하 주문 가능.
                  <br />
                  * 엘리베이터 운영 불가시, 6층 이상 배달 불가. (5층 이하 가능)
                  <br />
                  * 여러 스토어의 음식을 묶어 주문한 경우,
                  <br />
                  &nbsp;&nbsp;&nbsp;일부 메뉴 접수 불가시 해당 음식만 부분 환불 처리됨.
                  <br />
                  &nbsp;&nbsp;&nbsp;(나머지 정상 접수 음식은 배달 진행됨)
                </div>
              )}
            </div>
          </div>
          <div className="btn-floating-bottom">
            <button
              type="button"
              className="btn-cart"
              onClick={() => {
                //this.requestPay(this.fianlPayPrice());
                Utils.showAlertConfirm('룸서비스 정규시즌 종료');
              }}>
              <span className="total-count">결제하기</span>
              <span className="total-price">{basketList && Utils.wonPriceStr(this.fianlPayPrice())}원</span>
            </button>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Payment));
