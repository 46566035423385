import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';

class BrandStory extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">브랜드 철학</div>
          </div>
        </header>

        <section className="setting-tv">
          <div className="setting-tv-wrap">
            <div className="setting-tv-text">
              <br />
              부룸의 메뉴는 부족한 것이 아닌
              <br />
              즐겨 먹는 메뉴만 있는 것입니다.
              <br />
              <br />
              부룸의 화려한 화면은 못하는 것이 아닌
              <br />
              하지 않는 것입니다.
              <br />
              <br />
              부룸의 배너 과대 광고는 사용자와 사장님들을 위하여
              <br />
              삭제한 것입니다.
              <br />
              <br />
              부룸의 존재 목적은
              <br />
              부룸 사용자, 스토어 사장님, 부룸 매니저
              <br />
              그리고 이를 이롭게 연결하는 플랫폼,
              <br />
              모두에게 도움이 되기 위함입니다.
              <br />
              <br />
              "우리집에서도 룸서비스가 된다면 얼마나 좋을까?"
              <br />
              "배달료 1000원이면 안될까?“
              <br />
              "커피 한잔만 주문 할 순 없을까?"
              <br />
              "여러 가게의 음식을 묶어서 주문할 순 없을까?"
              <br />
              "10분 배달이 가능할까?"
              <br />
              <br />
              이 모든 것이 가능한 서비스는
              <br />
              <b>VOOROOM</b> 입니다.
              <br />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(BrandStory));
