import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';

class MyCoupon extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      couponCount: 0,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const mCount = this.props.location.state.couponCount;
    if (mCount) {
      this.setState({couponCount: mCount});
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {couponCount} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v2">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">쿠폰함</div>
          </div>
        </header>

        <section className="mycoupon">
          <div className="top">
            <div className="inp-group-mycoupon">
              <input type="text" disabled={true} placeholder="쿠폰 코드를 입력해주세요" className="inp-frm" />
              <button
                type="button"
                className="btn-frm"
                onClick={() => {
                  Utils.showAlertConfirm('쿠폰\n준비중 입니다.');
                }}>
                등록
              </button>
            </div>
          </div>

          <div className="wrap">
            {couponCount > 0 && (
              <ol className="list-coupon-history">
                <li
                  onClick={() => {
                    Utils.showAlertConfirm('(결제하기 > 쿠폰함)에서\n사용 가능 합니다.');
                  }}>
                  <div className="tit">배달료 1,000원 할인</div>
                  <div className="date">고객 감사</div>
                  <div className="count" style={{marginRight: '10px'}}>
                    {couponCount}매
                  </div>
                </li>
              </ol>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(MyCoupon));
