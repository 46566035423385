import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {api} from '../../api';
import * as Utils from '../../shared/util';

class TermsView extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      uuid: null,
      headerTitle: '',
      content: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const mTitle = this.props.location.state.title;
    const mUuid = this.props.location.state.uuid;

    if (mTitle !== undefined && mUuid !== undefined) {
      this.setState({headerTitle: mTitle});
      this.setState({uuid: mUuid});

      this.getTermsContent(mUuid);
    }
  }

  // -- feature -------------------------------------------------------------------------------
  getTermsContent = uuid => {
    api
      .get(`/v1/terms/get/${uuid}`)
      .then(res => {
        const {data} = res;
        this.setState({content: data[0].content});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getTermsContent');
      });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {headerTitle, content} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">{headerTitle}</div>
          </div>
        </header>

        <section className="setting-tv">
          <div className="setting-tv-wrap">
            <div className="setting-tv-text">{Utils.nl2br(content)}</div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(TermsView));
