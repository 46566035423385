import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import {api} from '../../api';
import {APP_KEY_POID, APP_KEY_USER_UUID} from '../../shared/Constants';
import Spinner from '../../components/Spinner';

class OrderHistory extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      poid: null,
      poidList: [],
      historyList: [],
      isLoading: true,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    Utils.getLocalStorageSync(APP_KEY_POID).then(myPoid => {
      this.setState({poid: myPoid});
    });
    Utils.getLocalStorageSync(APP_KEY_USER_UUID).then(userId => {
      this.getPoidList(userId);
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getPoidList = userId => {
    api
      .get(`/v1/order/poidByUser?user_id=${userId}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({poidList: data}, () => {
          this.parsePoidData();
        });
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getPoidList');
      });
  };

  parsePoidData = () => {
    const {poidList} = this.state;

    const tempList = [];
    poidList.forEach((item, idx) => {
      let reIdx = null;
      if (tempList.length > 0) {
        reIdx = tempList.length - 1;
      }

      // 주문내역 메뉴에 DP되는 리스트는 제외
      if (reIdx === null || item.poid !== tempList[reIdx].hPoid) {
        const pData = {
          hPoid: item.poid,
          hState: item.poid_state,
          hDate: item.create_date,
          hOrderMenu: item.product_name,
          hMenuCount: 1,
          hOrderPrice: item.pay_price,
        };
        tempList.push(pData);
      } else {
        tempList[reIdx].hMenuCount++;
      }
    });

    console.log(tempList);

    this.setState({historyList: tempList});
    this.setState({isLoading: false});
  };

  parseCountStr = (menuName, count) => {
    return count > 1 ? menuName + ' 외 ' + count + '개' : menuName + ' ' + count + '개';
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  renderHistoryList = () => {
    const {historyList} = this.state;
    const renderItem = [];
    historyList.forEach((item, index) => {
      renderItem.push(
        <li
          key={`historyList_li_${index}`}
          onClick={() => {
            this.props.history.push({
              pathname: '/order/view/detail',
              state: {poid: item.hPoid},
            });
          }}>
          <div className="state">{Utils.cycleStateToStr(item.hState)}</div>
          <div className="date">{Utils.getOrderDateTimeStr(item.hDate, 1)}</div>
          <div className="detail">{this.parseCountStr(item.hOrderMenu, item.hMenuCount)}</div>
          <div className="price">{Utils.wonPriceStr(item.hOrderPrice)}원</div>
        </li>,
      );
    });

    return <ol className="list-order-history">{renderItem}</ol>;
  };

  render() {
    const {historyList, isLoading} = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">이전 주문내역</div>
          </div>
        </header>

        {isLoading && <Spinner loading={isLoading} />}

        <section className="order-history">
          {historyList.length > 0 ? (
            <div className="wrap">{historyList && this.renderHistoryList()}</div>
          ) : (
            !isLoading && (
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">목록이 비어있습니다.</div>
                  <div className="txt2">이전 주문 내역이 없습니다.</div>
                </div>
              </div>
            )
          )}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OrderHistory));
