// --------------------------------------
// system constants
// --------------------------------------

// - BACKEND
export const API_SERVER = 'https://api.vooroom.kr';
//export const API_SERVER = 'https://beta-api.vooroom.kr'; //TODO: 개발용

// - PAY
export const NICE_PAY_CLIENT_ID = 'R2_87abfa3e434e489d910a7092e151e470'; // TODO: 상용 리얼 결제
//export const NICE_PAY_CLIENT_ID = 'S2_29e38171fb1f4bc9afb623467a43d520'; // TODO: 개발 테스트 결제

// - COMMON
export const DEFAULT_ZONE_CODE = '0_VR_G1';
export const DT1_ZONE_CODE = '4_DT2_G1';

// - LocalStorage
export const APP_KEY_IS_LOGIN = 'isLogin';
export const APP_KEY_USER_ALIAS = 'alias';
export const APP_KEY_USER_EMAIL = 'email';
export const APP_KEY_USER_STATUS = 'status';
export const APP_KEY_USER_ZONE_CODE = 'zoneCode';
export const APP_KEY_USER_ROOM = 'room';
export const APP_KEY_SNS_TYPE = 'snsType';
export const APP_KEY_USER_UUID = 'uuid';
export const APP_KEY_USER_FORCE_LOGOUT = 'forceLogout';
export const APP_KEY_TOID = 'toid';
export const APP_KEY_POID = 'poid';
export const APP_KEY_TOID_CONCIERGE = 'toidConcierge';
export const APP_KEY_POID_CONCIERGE = 'poidConcierge';
export const APP_KEY_POPUP_24_DATE = 'popupDate';
export const APP_KEY_POPUP_24_DATE2 = 'popupDate2';
export const APP_KEY_POPUP_24_DATE3 = 'popupDate3';
export const APP_KEY_ON_BOARDING = 'onBoarding';
export const APP_KEY_USER_SETTING_BELL = 'isBell';
export const APP_KEY_FREE_COUPON = 'isUseFreeCoupon';

// - VOOROOM OPERATION TIME ( 24시간제 표기, "00", "09" 등으로 표현하지 말것!! 0 한자리 숫자로 표기해야함)
// - [11.07] 평일 오픈 11시 / 주말 오픈 10시
export const VOOROOM_OPERATION_TIME_WEEKEND_OPEN = '11:0';
export const VOOROOM_OPERATION_TIME_OPEN = '11:0';
export const VOOROOM_OPERATION_TIME_CLOSE = '20:0';
export const VOOROOM_OPERATION_BREAK_TIME_START = '14:0';
export const VOOROOM_OPERATION_BREAK_TIME_WEEKEND_START = '14:0';
export const VOOROOM_OPERATION_BREAK_TIME_END = '17:0';

// - 룸서비스 상태값
export const ROOM_SERVICE_STATE_0_BASKET = 0; // 장바구니
export const ROOM_SERVICE_STATE_1_DONE_PAY = 1; // 결제완료
export const ROOM_SERVICE_STATE_2_WAIT_ORDER = 10; // 접수대기
export const ROOM_SERVICE_STATE_3_1_NORMAL_RECEIPT = 20; // 정상접수
export const ROOM_SERVICE_STATE_3_2_DELAY_RECEIPT = 30; // 지연접수
export const ROOM_SERVICE_STATE_4_DELAY_DELIVERY = 40; // 배달지연
export const ROOM_SERVICE_STATE_5_COMPLETE_PICKUP = 50; // 픽업완료
export const ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY = 60; // 배달완료
export const ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND = -1; // 환불완료

// - 컨시어지 상태값
export const CONCIERGE_STATE_1_DONE_PAY = 1; // 결제완료
export const CONCIERGE_STATE_10_NORMAL_RECEIPT = 10; // 접수완료
export const CONCIERGE_STATE_20_SCHEDULE_ING = 20; // 일정조율
export const CONCIERGE_STATE_30_SCHEDULE_DONE = 30; // 일정확인
export const CONCIERGE_STATE_40_SERVICE_DONE = 40; // 시행완료
export const CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND = -1; // 환불처리
