import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {APP_KEY_FREE_COUPON, APP_KEY_USER_UUID} from '../../shared/Constants';

class OrderUseCoupon extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      couponPrice: 0,
      couPon1: false,
      couPon2: false,
      isFirstBuy: false,
      payMode: null,
      isOnlyTogetherProduct: false,
      freeCoupon: 0,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    const payMode = this.props.match.params.mode;

    if (payMode === 'together') {
      // 공동구매
      console.log('공동구매 모드');
      this.setState({isOnlyTogetherProduct: true});
    } else {
      this.setState({payMode: payMode});
    }

    localStorage.removeItem(APP_KEY_FREE_COUPON);

    if (uuid) {
      // - 최초 구매 쿠폰
      Service.getUserBuyCompleteList(uuid).then(result => {
        if (result.length === 0) {
          this.setState({isFirstBuy: true});
          console.log('first buy...user_id=' + uuid);
        } else {
          console.log('NOT first buy...user_id=' + uuid);
        }
      });

      // - 고객 감사 쿠폰
      Service.getUserInfoByUuid(uuid).then(result => {
        console.log(result);

        // 배달비 무료 쿠폰
        const mCount = result ? result.free_coupon : null;
        if (mCount) {
          this.setState({freeCoupon: mCount});
        }
      });
    } else {
      console.log('uuid is null...' + uuid);
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleCouponCheck = e => {
    console.log('handleCouponCheck');
    this.setState({couponPrice: 0});

    const {couPon1} = this.state;
    this.setState({couPon1: !couPon1});
    couPon1 === false && this.setState({couPon2: false});

    if (couPon1 === false) this.setState({couponPrice: 1000});
    else this.setState({couponPrice: 0});
  };

  handleCouponCheck2 = e => {
    console.log('handleCouponCheck2');
    this.setState({couponPrice: 0});

    const {couPon2} = this.state;
    this.setState({couPon2: !couPon2});
    couPon2 === false && this.setState({couPon1: false});

    if (couPon2 === false) this.setState({couponPrice: 1000});
    else this.setState({couponPrice: 0});
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {couponPrice, couPon1, couPon2, isFirstBuy, payMode, freeCoupon, isOnlyTogetherProduct} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.replace('/payment/' + payMode);
              }}
            />
            <div className="hd-tit">쿠폰함</div>
          </div>
        </header>
        <section className="coupon">
          {(isFirstBuy || freeCoupon > 0) && isOnlyTogetherProduct === false ? (
            <div className="box-coupon">
              <ol className="list-coupon">
                {isFirstBuy && (
                  <li>
                    <input id="coupon1_ck" type="radio" name="coupon_ck" className="ck-custom" checked={couPon1} onChange={() => {}} onClick={this.handleCouponCheck} />
                    <label htmlFor="coupon1_ck">
                      <div className="custom">
                        <div className="tit">배달료 1,000원 할인</div>
                        <div className="date">최초 주문</div>
                        <div className="count">1매</div>
                      </div>
                    </label>
                  </li>
                )}
                {freeCoupon > 0 && (
                  <li>
                    <input id="coupon2_ck" type="radio" name="coupon_ck2" className="ck-custom" checked={couPon2} onChange={() => {}} onClick={this.handleCouponCheck2} />
                    <label htmlFor="coupon2_ck">
                      <div className="custom">
                        <div className="tit">배달료 1,000원 할인</div>
                        <div className="date">고객 감사</div>
                        <div className="count">{freeCoupon}매</div>
                      </div>
                    </label>
                  </li>
                )}
              </ol>
            </div>
          ) : (
            <div className="wrap">
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">사용 가능한 쿠폰이 없습니다.</div>
                </div>
              </div>
            </div>
          )}

          <div className="btn-floating-bottom btn-floating-bottom-v1">
            <ol className="list-total-price">
              <li>
                <div className="tit">쿠폰 사용 금액</div>
                <div className="txt">
                  {couponPrice > 0 ? (
                    <>
                      <strong>-{Utils.wonPriceStr(couponPrice)}</strong>원
                    </>
                  ) : (
                    <>
                      <strong>0</strong>원
                    </>
                  )}
                </div>
              </li>
            </ol>

            <Link
              to={{
                pathname: '/payment/' + payMode,
                state: {
                  useCoupon: couponPrice,
                },
              }}
              onClick={() => {
                couPon2 && localStorage.setItem(APP_KEY_FREE_COUPON, true);
              }}
              replace={true}
              className="btn-custom btn-custom-v1">
              쿠폰 적용하기
            </Link>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OrderUseCoupon));
