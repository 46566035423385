import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import ImgTransparent1 from '../../assets/img/img_transparent1.png';
import {ReactComponent as ArrowRight} from '../../assets/img/ico_arrow_right1.svg';
import * as Utils from '../../shared/util';
import {setMenuScrollTop} from '../../redux/app/appActions';

class Terms extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      allCheck: false,
      termsCheck: [false, false, false],
      isNext: false,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleAllCheck = e => {
    console.log('handleAllCheck');

    this.setState({allCheck: !this.state.allCheck}, () => {
      const {allCheck} = this.state;
      if (allCheck) {
        this.setState({termsCheck: [true, true, true]});
        this.setState({isNext: true});
      } else {
        this.setState({termsCheck: [false, false, false]});
        this.setState({isNext: false});
      }
    });
  };

  handleItemCheck = idx => e => {
    console.log('handleItemCheck : ' + idx);
    const {termsCheck} = this.state;
    termsCheck[idx] = !termsCheck[idx];
    this.setState({termsCheck: termsCheck}, () => {
      if (termsCheck[0] === true && termsCheck[1] === true && termsCheck[2] === true) {
        this.setState({allCheck: true});
        this.setState({isNext: true});
      } else {
        this.setState({allCheck: false});
        this.setState({isNext: false});
      }
    });
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {allCheck, termsCheck, isNext} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">약관동의</div>
          </div>
        </header>

        <section className="terms">
          <div className="terms-info">
            <b>부룸</b> 서비스를 이용하기 위해서
            <br />
            약관동의가 필요합니다.
          </div>
          <div className="terms-area">
            <div className="terms-all">
              <input id="terms_all_ck" type="radio" name="terms_all_ck" className="ck-custom2" checked={allCheck} onChange={() => {}} onClick={this.handleAllCheck} />
              <label htmlFor="terms_all_ck" className="terms-all-txt">
                약관 전체 동의
              </label>
            </div>
            <div>
              <div className="terms-item">
                <input id="terms0_ck" type="radio" name="terms0_ck" className="ck-custom2" onChange={() => {}} checked={termsCheck[0]} onClick={this.handleItemCheck(0)} />
                <label htmlFor="terms0_ck" className="terms-item-txt">
                  이용약관 동의(필수)
                </label>
                <div className="terms-item-right">
                  <Link
                    to={{
                      pathname: '/setting/terms',
                      state: {
                        uuid: 1,
                        title: '서비스 이용약관',
                      },
                    }}>
                    {' '}
                    <ArrowRight />
                  </Link>
                </div>
              </div>
              <div className="terms-item">
                <input id="terms1_ck" type="radio" name="terms1_ck" className="ck-custom2" onChange={() => {}} checked={termsCheck[1]} onClick={this.handleItemCheck(1)} />
                <label htmlFor="terms1_ck" className="terms-item-txt">
                  개인정보 수집 및 이용동의(필수)
                </label>
                <div className="terms-item-right">
                  <Link
                    to={{
                      pathname: '/setting/terms',
                      state: {
                        uuid: 3,
                        title: '개인정보 처리방침',
                      },
                    }}>
                    {' '}
                    <ArrowRight />
                  </Link>
                </div>
              </div>
              <div className="terms-item">
                <input id="terms2_ck" type="radio" name="terms2_ck" className="ck-custom2" onChange={() => {}} checked={termsCheck[2]} onClick={this.handleItemCheck(2)} />
                <label htmlFor="terms2_ck" className="terms-item-txt">
                  전자금융거래 이용약관(필수)
                </label>
                <div className="terms-item-right">
                  <Link
                    to={{
                      pathname: '/setting/terms',
                      state: {
                        uuid: 2,
                        title: '전자금융거래 이용약관',
                      },
                    }}>
                    {' '}
                    <ArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-floating-bottom">
            <button
              type="button"
              className={!isNext ? 'btn-custom btn-custom-v3' : 'btn-custom btn-custom-v3-next'}
              onClick={() => {
                if (isNext) {
                  this.props.history.push('/login/authPhone');
                } else {
                  Utils.showAlertConfirm('필수 약관 동의가 필요합니다.');
                }
              }}>
              다음
            </button>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Terms));
