import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';

class UseCoupon extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">포인트 사용</div>
          </div>
        </header>

        <section className="point">
          <div className="wrap">
            <div className="inp-group-point">
              <input type="text" placeholder="0" className="inp-frm" />
              <button type="button" className="btn-frm">
                전액 사용
              </button>
            </div>

            <div className="point-info">
              보유 포인트<span>0Point</span>
            </div>
          </div>

          <div className="btn-floating-bottom">
            <button type="button" className="btn-custom btn-custom-v1">
              포인트 적용하기
            </button>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(UseCoupon));
