import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import {api} from '../../api';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {APP_KEY_TOID, APP_KEY_TOID_CONCIERGE, APP_KEY_USER_ZONE_CODE, DEFAULT_ZONE_CODE, DT1_ZONE_CODE} from '../../shared/Constants';
import {ReactComponent as StoreInfo} from '../../assets/img/store_info_arrow.svg';
import {getConciergeBoidByProduct} from '../../shared/apiService';
import Modal from 'react-modal';
import {ReactComponent as IcoClose} from '../../assets/img/ico_close_type2.svg';

// Swiper Issue.
SwiperCore.use([Pagination, Navigation]);
class ConciergeMenuDetail extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      orderCount: 0,
      orderPrice: 0,
      menuTitle: '',
      menuId: '',
      productList: [],
      basketList: [],
      menuPopup: false,
      popupImg: '',
      popupProductName: '',
      popupStoreName: '',
      zoneCode: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    this.setState({menuTitle: this.props.location.state.title});
    this.setState({menuId: this.props.location.state.menuId});
    this.getProductList(this.props.location.state.menuId);

    // page view update
    this.setCountPlus(this.props.location.state.menuId);

    // ZoneCode
    this.setState({zoneCode: Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE)});
  }

  // -- feature -------------------------------------------------------------------------------
  getProductList = menuId => {
    api
      .get(`/v1/menu/detail?menuId=${menuId}`)
      .then(res => {
        const {data} = res;
        const newObj = [];

        data.forEach((item, idx) => {
          item.count = 0;
          newObj.push(item);
        });
        console.log(newObj);

        this.setState({productList: newObj});
      })
      .finally(() => {});
  };

  setCountPlus = menuId => {
    api
      .get(`/v1/menu/count/plus?menuId=${menuId}`)
      .then(res => {
        const {data} = res;
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] setCountPlus');
      });
  };

  // -- handler -------------------------------------------------------------------------------
  handlePutMenuBtn = async () => {
    console.log('handlePutMenuBtn');
    const {productList} = this.state;

    const myToid = Utils.getLocalStorage(APP_KEY_TOID_CONCIERGE);
    for (const item of productList) {
      if (item.count > 0) {
        await Service.getConciergeBoidByProduct(myToid, item.product_id).then(result => {
          if (result.length === 0) {
            // 신규 추가
            Service.createConciergeBOID(myToid, item.product_id, item.store_id, item.price, item.count);
          } else {
            // 기존 업데이트
            Service.updateConciergeBOID(myToid, item.product_id, item.count);
          }
        });
      }
    }

    Utils.showAlertPutMenu().then(() => {
      this.props.history.push('/concierge/menu');
    });
  };

  handlePlus = idx => {
    console.log('handlePlus');
    const {productList, orderCount} = this.state;
    const newObj = productList;

    // - 상품 카운트
    newObj[idx].count++;
    // - 주문개수
    this.setState(prevState => ({
      orderCount: prevState.orderCount + 1,
    }));
    // - 주문금액
    this.setState(prevState => ({
      orderPrice: prevState.orderPrice + newObj[idx].price,
    }));
    this.setState({productList: newObj});

    /*if (orderCount < 8) {
      // - 상품 카운트
      newObj[idx].count++;
      // - 주문개수
      this.setState(prevState => ({
        orderCount: prevState.orderCount + 1,
      }));
      // - 주문금액
      this.setState(prevState => ({
        orderPrice: prevState.orderPrice + newObj[idx].price,
      }));
      this.setState({productList: newObj});
    } else {
      Utils.showAlertConfirm('메뉴는 5개\n총 수량은 8개\n이하 주문 가능합니다.');
    }*/
  };

  handleMinus = idx => {
    console.log('handleMinus');
    const {productList} = this.state;
    const newObj = productList;

    if (newObj[idx].count > 0) {
      // - 상품 카운트
      newObj[idx].count--;
      // - 주문개수
      this.setState(prevState => ({
        orderCount: prevState.orderCount - 1,
      }));
      // - 주문금액
      this.setState(prevState => ({
        orderPrice: prevState.orderPrice - newObj[idx].price,
      }));
      this.setState({productList: newObj});
    }
  };

  handleCloseModal = () => {
    this.setState({menuPopup: false});
  };

  // -- render --------------------------------------------------------------------------------
  renderProductList = () => {
    const {productList, orderCount, orderPrice} = this.state;
    const renderItem = [];
    const renderImg = [];
    const storeInfo = [];
    productList.forEach((item, index) => {
      renderImg.push(
        <SwiperSlide key={`slide_${index}`} className="swiper-slide">
          <img src={item.product_url} loading="lazy" className="thumb" />
          <div className="tit-item">
            {item.product_name} / {item.store_name}
          </div>
        </SwiperSlide>,
      );
      renderItem.push(
        <li key={`product_list_${index}`}>
          <div
            onClick={() => {
              this.setState({popupImg: item.product_url});
              this.setState({popupProductName: item.product_name});
              this.setState({popupStoreName: item.store_name});
            }}>
            <div
              className="item-name"
              onClick={() => {
                this.setState({menuPopup: true});
              }}>
              {item.product_name}
            </div>
            <div className="item-store">
              <div
                className="item-store-round"
                onClick={() => {
                  Utils.sendToRnMessage('RN_STORE_INFO_LINK', item.link_url);
                }}>
                {item.store_name} <StoreInfo style={{marginLeft: '4px'}} />
              </div>
              {item.store_id === '4_DT2_G1_S35' && (
                <div
                  className="item-store-round"
                  style={{marginLeft: '8px'}}
                  onClick={() => {
                    Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://blog.naver.com/vooroom/223161896742');
                  }}>
                  상세정보 <StoreInfo style={{marginLeft: '4px'}} />
                </div>
              )}
              {item.store_id === '4_DT2_G1_S37' && (
                <div
                  className="item-store-round"
                  style={{marginLeft: '8px'}}
                  onClick={() => {
                    Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://m.blog.naver.com/vooroom/223162529801');
                  }}>
                  상세정보 <StoreInfo style={{marginLeft: '4px'}} />
                </div>
              )}
              <div
                style={{width: '100%'}}
                onClick={() => {
                  this.setState({menuPopup: true});
                }}></div>
            </div>
            <div
              className="item-price"
              onClick={() => {
                this.setState({menuPopup: true});
              }}>
              {Utils.wonPriceStr(item.price)}원
            </div>
          </div>

          <div className="item-count">
            <button type="button" className="btn-count btn-count-minus" onClick={() => this.handleMinus(index)} />
            <span className="num">{item.count}</span>
            <button type="button" className="btn-count btn-count-plus" onClick={() => this.handlePlus(index)} />
          </div>
        </li>,
      );

      // - 스토어명 전체 배열
      storeInfo.push(item.store_name);
    });

    /* 스토어명 중복 제거 및 스트링화 */
    const set = new Set(storeInfo);
    const uniqueArr = [...set];
    let storeInfoNames = '';

    uniqueArr.forEach((item, idx) => {
      if (idx === 0) {
        storeInfoNames += item;
      } else {
        storeInfoNames += ', ' + item;
      }
    });

    return (
      <>
        <div className="slide-item">
          <div className="swiper">
            <Swiper
              className="swiper-wrapper"
              loop={true}
              initialSlide={1}
              pagination={{
                el: '.js-swiper-pagination',
                type: 'fraction',
              }}
              modules={[Pagination, Navigation]}>
              {renderImg}
            </Swiper>
          </div>
          <div className="pagination-group">
            <div className="swiper-pagination js-swiper-pagination"></div>
          </div>
        </div>

        <div className="wrap">
          <ol className="list-item">{renderItem}</ol>

          <div className="order-info">
            <ol className="list-order-info">
              <li>
                <div className="tit">주문개수</div>
                <div className="txt">
                  <strong>{orderCount}</strong>개
                </div>
              </li>

              <li>
                <div className="tit">상품금액</div>
                <div className="txt">
                  <strong>{Utils.wonPriceStr(orderPrice)}</strong>원
                </div>
              </li>
            </ol>

            <div className="order-info-etc">가게정보 | {storeInfoNames}</div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {productList, menuTitle, orderCount, orderPrice, menuPopup, popupImg, popupProductName, popupStoreName, zoneCode} = this.state;
    const customStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.4)',
        borderRadius: 0,
        padding: 0,
        backgroundColor: '#000000',
        border: 'none',
      },
    };

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">{menuTitle}</div>
          </div>
        </header>

        <section className="item-view">
          {/* 상품 정보 */}
          {productList && this.renderProductList()}

          <div className="btn-floating-bottom">
            <button
              type="button"
              className="btn-cart"
              onClick={() => {
                if (orderCount > 0) {
                  if (Utils.isLogin() === true) {
                    if (zoneCode === DEFAULT_ZONE_CODE) {
                      Utils.showAlertConfirm('[내설정] 아파트 설정 이후\n이용가능합니다.').then(() => {
                        this.props.history.push('/setting');
                      });
                    } else if (zoneCode === DT1_ZONE_CODE) {
                      this.handlePutMenuBtn();
                    } else {
                      //TODO: 컨시어지는 정식 출시 전까지 모든 구역에 준비중 팝업창으로 노출
                      Utils.showAlertConfirm('[ 준비중 ]\n컨시어지 서비스 오픈 준비 중입니다.\n조금만 기다려주세요.');
                    }
                  } else {
                    Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
                    this.props.history.push('/login');
                  }
                } else {
                  Utils.showAlertConfirm('상품을 먼저 선택해주세요.');
                }
              }}>
              <span className="total-count">{orderCount}개 담기</span>
              <span className="total-price">{Utils.wonPriceStr(orderPrice)}원</span>
            </button>
          </div>
        </section>

        {/* 메뉴 이미지 팝업창 모달 */}
        <Modal isOpen={menuPopup} style={customStyles} ariaHideApp={false} onRequestClose={this.handleCloseModal}>
          <div className="menu-popup-view">
            <IcoClose
              onClick={() => {
                this.handleCloseModal();
              }}
            />
            <img src={popupImg} loading="lazy" className="thumb" />
            <div className="menu-popup-bottom">
              {popupProductName} / {popupStoreName}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(ConciergeMenuDetail));
