import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as IcoStart} from '../../assets/img/onbording_start_btn.svg';
import {ReactComponent as IcoText} from '../../assets/img/onbording_start_text.svg';
import {ReactComponent as IcoNext} from '../../assets/img/ico_next.svg';
import {ReactComponent as IcoPrev} from '../../assets/img/ico_prev.svg';
import * as Utils from '../../shared/util';
import {APP_KEY_ON_BOARDING} from '../../shared/Constants';

class OnBoarding extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      stepValue: 0,
      BackImg: [
        require('../../assets/img/onboarding_1.jpg'),
        require('../../assets/img/onboarding_2.jpg'),
        require('../../assets/img/onboarding_3.jpg'),
        require('../../assets/img/onboarding_4.jpg'),
        require('../../assets/img/onboarding_5.jpg'),
        require('../../assets/img/onboarding_6.jpg'),
      ],
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {BackImg, stepValue} = this.state;

    return (
      <React.Fragment>
        {stepValue === 0 ? (
          <div className="onboarding-btn">
            <div
              className="onboarding-skip"
              onClick={() => {
                Utils.setLocalStorage(APP_KEY_ON_BOARDING, true);
                this.props.history.replace('/');
              }}>
              SKIP
            </div>
          </div>
        ) : (
          <div>
            <div className="onboarding-btn2">
              <div>STEP{stepValue}</div>
            </div>
            <div
              className="onboarding-btn-prev"
              onClick={() => {
                if (stepValue > 0) this.setState({stepValue: stepValue - 1});
              }}>
              <IcoPrev />
            </div>
            <div
              className="onboarding-btn-next"
              onClick={() => {
                if (stepValue < 5) {
                  this.setState({stepValue: stepValue + 1});
                } else {
                  this.props.history.replace('/onBoardingLogin');
                }
              }}>
              <IcoNext />
            </div>
          </div>
        )}

        {BackImg &&
          (stepValue === 0 ? (
            <>
              <div className="onboarding-view-step0">
                <img src={BackImg[stepValue].default} />
              </div>
              <div className="onboarding-view-step0-text">
                <IcoText />
              </div>
              <div
                className="onboarding-view-step0-btn"
                onClick={() => {
                  Utils.setLocalStorage(APP_KEY_ON_BOARDING, true);
                  this.setState({stepValue: stepValue + 1});
                }}>
                <IcoStart />
              </div>
            </>
          ) : (
            <div className="onboarding-view">
              <img src={BackImg[stepValue].default} />
            </div>
          ))}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OnBoarding));
