import React, {Component} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as Logo} from '../../assets/img/img_logo1.svg';
import {ReactComponent as BedgePop} from '../../assets/img/bedge_popular.svg';
import {ReactComponent as BedgeNew} from '../../assets/img/bedge_new.svg';
import {ReactComponent as BedgeRec} from '../../assets/img/bedge_recommend.svg';
import {ReactComponent as ExpandMore} from '../../assets/img/expand_more.svg';
import {ReactComponent as ExpandLess} from '../../assets/img/expand_less.svg';

import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {api} from '../../api';
import {APP_KEY_POPUP_24_DATE, APP_KEY_POPUP_24_DATE2, APP_KEY_USER_ROOM, APP_KEY_USER_ZONE_CODE, DEFAULT_ZONE_CODE, DT1_ZONE_CODE} from '../../shared/Constants';
import Spinner from '../../components/Spinner';
import {setMenuScrollTop} from '../../redux/app/appActions';
import BottomMenu from '../../components/BottomMenu';
import {checkConciergeToid} from '../../shared/util';
import {getConciergeMyBasket} from '../../shared/apiService';
import NewBottomMenu from '../../components/NewBottomMenu';

class ConciergeMenu extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      menuList: [],
      zoneInfo: {},
      basketCount: 0,
      theposition: window.pageYOffset,
      roomInfo: null,
      popupShow: false,
      popupShow2: false,
      aptName: '',
      isVooroomHoliday: false, // 부룸 정기 휴무일 : 매주 화
      isServiceTime: true,
      isEmergency: null, // 비상모드 : 해당 구역 전체 서비스 불가
      isAptNoService: false, // 특정 아파트 단지 서비스 제한 : 특정 아파트 단지 서비스 불가
      scrollY: null,
      isFooterCompanyInfo: false,
      isDevBetaMode: false, // 개발모드인가?
      isLoading: true,
      zoneCode: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // - 개발 모드 셋팅
    const isDev = Utils.isDebuggableEnv();
    if (isDev) {
      this.setState({isDevBetaMode: isDev});
      console.log('######### 부룸 개발 모드 #########');
    }

    // - 구역 정보 설정
    Utils.getLocalStorageSync(APP_KEY_USER_ZONE_CODE).then(zoneCode => {
      this.getMenuListByZoneCode(zoneCode); // 메뉴 정보 가져오기
      this.getZoneInfo(zoneCode); // 구역 정보 가져오기
      this.setState({zoneCode: zoneCode});

      // - TOID 발급 체크
      if (zoneCode !== DEFAULT_ZONE_CODE) {
        Utils.checkConciergeToid().then(result => {
          if (result) {
            Service.getConciergeMyBasket().then(result => {
              let bCount = 0;
              result.forEach((item, idx) => {
                bCount += item.count;
              });
              if (bCount > 0) this.setState({basketCount: bCount});
            });
          } else {
            console.log('checkConciergeToid Null');
          }
        });
      }
    });

    // - 이전 스크롤 위치 기억
    window.addEventListener('scroll', this.handleScroll);
    this.setState({isServiceTime: Utils.isVooroomCheckOnTime()});

    // - TODO: 임시로 룸정보를 가져온다. 추후 사용자가 아파트 설정할때까지 지역코드를 알수 없기에 공백 화면이다
    Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
      if (rInfo !== null && rInfo.length > 0) {
        this.setState({roomInfo: rInfo});

        // - 아파트
        const rInfoObj = rInfo.split('/');
        const aptName = rInfoObj[0];
        console.log('aptName : ' + aptName);
        this.setState({aptName: aptName});

        /*Service.getZoneAptList('4_DT2_G1').then(result => {
          result.forEach((item, idx) => {
            if (item.is_open === 'N' && item.apt_name === aptName) {
              console.log('[해당 아파트 단지 서비스 제한] ' + aptName);
              this.setState({isAptNoService: true});
              Utils.showEmergencyAlertPopup();
            }
          });
        });*/
      }
    });
  }

  componentDidUpdate() {
    const {scrollY} = this.state;
    const {app} = this.props;
    // - 스크롤 위치 기억
    if (scrollY === null) {
      this.setState({scrollY: app.menuScrollTop});
      setTimeout(() => {
        window.scrollTo(0, app.menuScrollTop);
      }, 100);
    }
  }

  // -- feature -------------------------------------------------------------------------------
  handleScroll = e => {
    const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
    if (scrollTop !== 0) {
      this.setState({scrollY: scrollTop});
    }
  };

  getMenuListByZoneCode = zoneCode => {
    api
      .get(`/v1/concierge/menu?zoneCode=${zoneCode}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({menuList: data});
        this.setState({isLoading: false});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getMenuListByZoneCode');
      });
  };

  getZoneInfo = zoneCode => {
    api
      .get(`/v1/zone/get/${zoneCode}`)
      .then(res => {
        const {data} = res;
        if (data.length > 0) {
          console.log(data[0]);
          this.setState({zoneInfo: data[0]});

          // TODO: 컨시어지 정식 출시전에 이멀전시는 막아두자
          /*if (data[0].is_emergency !== 'N') {
            console.log('비상모드 선언!');
            this.setState({isEmergency: data[0].is_emergency});

            switch (data[0].is_emergency) {
              case 'Y':
                Utils.showEmergencyAlertPopup(); // - (기본) 비상선언
                break;
              case 'M':
                Utils.showEmergencyAlertPopupByManager(); // - (매니저 이슈) 비상선언
                break;
              case 'W':
                Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 비상선언
                break;
              default:
                break;
            }
          }*/
        } else {
          console.log('error : getZoneInfo');
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getZoneInfo');
      });
  };

  // -- handler -------------------------------------------------------------------------------
  handleLoginCheck = path => {
    if (Utils.isLogin() === true) {
      this.props.history.push(path);
    } else {
      Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
      this.props.history.push('/login');
    }
  };

  /**
   * 룸서비스 주문 상태 체크
   */
  handleBasketCheck = () => {
    const {roomInfo, zoneCode} = this.state;
    console.log('handleBasketCheck', roomInfo);

    if (Utils.isLogin() === true) {
      if (zoneCode !== DT1_ZONE_CODE) {
        //TODO: 컨시어지는 정식 출시 전까지 모든 구역에 준비중 팝업창으로 노출
        Utils.showAlertConfirm('[ 준비중 ]\n컨시어지 서비스 오픈 준비 중입니다.\n조금만 기다려주세요.');
      }

      if (roomInfo !== null && roomInfo.length > 0) {
        this.props.history.push('/concierge/order');
      } else {
        Utils.showAlertConfirm('[내설정] 아파트 설정 이후\n이용가능합니다.').then(() => {
          this.props.history.push('/setting');
        });
      }
    } else {
      Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
      this.props.history.push('/login');
    }
  };

  handlePopup24Hour = modal => {
    if (modal === 2) {
      Utils.setLocalStorage(APP_KEY_POPUP_24_DATE2, Utils.getTodayDateString());
      this.setState({popupShow2: false});
    } else {
      Utils.setLocalStorage(APP_KEY_POPUP_24_DATE, Utils.getTodayDateString());
      this.setState({popupShow: false});
    }
  };

  // -- render --------------------------------------------------------------------------------
  renderBedgeSystem = flag => {
    if (flag === 'I') {
      return <BedgePop style={{marginRight: '8px'}} />;
    } else if (flag === 'S') {
      return <BedgeNew style={{marginRight: '8px'}} />;
    } else if (flag === 'C') {
      return <BedgeRec style={{marginRight: '8px'}} />;
    }
  };

  renderMenuList = () => {
    const {menuList, zoneInfo, isServiceTime, scrollY, isVooroomHoliday, isFooterCompanyInfo} = this.state;
    const {dispatch, app} = this.props;
    const renderItem = [];
    menuList.forEach((item, index) => {
      item.menu_id === '4_DT2_G1_C1' || item.menu_id === '4_DT2_G1_C7' || item.menu_id === '4_DT2_G1_C3'
        ? renderItem.push(
            <li key={`menu_list_li_${index}`}>
              <Link
                to={{
                  pathname: '/concierge/menu/detail',
                  state: {
                    title: item.menu_name,
                    menuId: item.menu_id,
                  },
                }}
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                }}
                /*style={{
              verticalAlign: 'center',
              paddingRight:
                item.menu_id === '4_DT2_G1_M35' ||
                item.menu_id === '4_DT2_G1_M33' ||
                item.menu_id === '4_DT2_G1_M26' ||
                item.menu_id === '4_DT2_G1_M23' ||
                item.menu_id === '4_DT2_G1_M40' ||
                item.menu_id === '4_DT2_G1_M8' ||
                item.menu_id === '4_DT2_G1_M21' ||
                item.menu_id === '4_DT2_G1_M30' ||
                item.menu_id === '4_DT2_G1_M19' ||
                item.menu_id === '4_DT2_G1_M4'
                  ? '44px'
                  : '',
            }}*/
                style={{
                  verticalAlign: 'center',
                  paddingRight: item.bedge !== 'N' ? '44px' : '',
                }}>
                {this.renderBedgeSystem(item.bedge)}
                {item.menu_name}
              </Link>
            </li>,
          )
        : renderItem.push(
            <li key={`menu_list_li_${index}`}>
              <Link
                to={'#'}
                onClick={() => {
                  Utils.showAlertConfirm('[ 준비중 ]\n해당 서비스는 오픈 준비 중입니다.\n조금만 기다려주세요.');
                }}
                style={{color: '#AAA'}}>
                {item.menu_name}
              </Link>
            </li>,
          );
    });

    let pBottom = '100px';
    if (isServiceTime === false || zoneInfo.is_weather !== 'N' || isVooroomHoliday === true) pBottom = '180px';
    return (
      <div className="wrap-list-category" onScroll={this.handleScroll} style={{paddingBottom: pBottom}}>
        {/*<Link
          to={{
            pathname: '/setting/addrSplash',
            state: {
              aptName: 'world_cup_event',
            },
          }}>
          <div className="world-cup-view">
            <WorldCupText />
          </div>
        </Link>*/}
        <ol className="list-category">{renderItem}</ol>

        <div className="footer-company-info">
          <div
            className="footer-company-info-name"
            onClick={() => {
              this.setState(prevState => ({
                isFooterCompanyInfo: !prevState.isFooterCompanyInfo,
              }));
            }}>
            <span>(주)부룸</span>&nbsp;사업자정보
            {!isFooterCompanyInfo ? <ExpandMore /> : <ExpandLess />}
          </div>
          {isFooterCompanyInfo && (
            <div className="footer-info">
              <p>
                상호명 : 주식회사 부룸 | 대표 : 장영훈
                <br />
                사업자등록번호 : 591-87-02499
                <br />
                통신판매업 신고번호 : 제 2022-화성동탄-1595 호
                <br />
                경기도 화성시 동탄첨단산업1로 27 금강펜테리움 IX타워 A동 2608호
                <br />
                이메일 : jaybee@vooroom.kr | 전화 : 0507-1350-8303
                <br />
                <span
                  onClick={() => {
                    console.log('개발자 모드');
                    Utils.sendToRnMessage('RN_BETA_DEV_MODE');
                  }}>
                  ⓒ VOOROOM Inc.
                </span>
              </p>
            </div>
          )}

          <div className="footer-company-info-menu">
            <div>
              <Link
                to={'/setting/cs'}
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                }}>
                고객센터
              </Link>
            </div>

            <div>
              <Link
                to={{
                  pathname: '/setting/terms',
                  state: {
                    uuid: 1,
                    title: '서비스 이용약관',
                  },
                }}
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                }}>
                서비스 이용약관
              </Link>
            </div>

            <div>
              <a
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                  Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://vooroom.kr/terms');
                }}>
                개인정보 처리방침
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {menuList, basketCount, popupShow, popupShow2, zoneInfo, aptName, isDevBetaMode, isLoading} = this.state;
    const {dispatch, app} = this.props;
    const customStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0px 2px 15px 2px rgba(0, 0, 0, 0.4)',
        borderRadius: 0,
        padding: 0,
        backgroundColor: '#000000',
        border: 'none',
      },
    };

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v1">
            <Link to={'/home'} className="hd-logo">
              <Logo />
            </Link>

            <div className="hd-basket">
              <a
                onClick={() => {
                  this.handleBasketCheck();
                }}
                className="hd-cart-concierge">
                <span className="count">{basketCount}</span>
              </a>
            </div>
          </div>
        </header>

        <section className="menu">
          <div className="wrap">
            <div className="wrap-top-page">
              <div className="top-page">컨시어지</div>
            </div>
            {menuList && this.renderMenuList()}
            {isLoading && <Spinner loading={isLoading} />}
          </div>
        </section>

        <footer className="footer">
          <NewBottomMenu history={this.props.history} menuItem={2} />
        </footer>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(ConciergeMenu));
