import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {ReactComponent as Logo} from '../../assets/img/img_logo1.svg';
import {ReactComponent as RoomService} from '../../assets/img/room_service_icon.svg';
import {ReactComponent as Concierge} from '../../assets/img/concierge_icon.svg';
import {ReactComponent as Community} from '../../assets/img/community_icon.svg';
import {ReactComponent as Shopping} from '../../assets/img/shopping_icon.svg';
import {ReactComponent as IcoOn} from '../../assets/img/ico_on.svg';
import {ReactComponent as IcoOff} from '../../assets/img/ico_off.svg';
import {ReactComponent as IcoSun} from '../../assets/img/ico_sun.svg';
import {ReactComponent as IcoMoon} from '../../assets/img/ico_moon.svg';
import ListViewBanner1 from '../../assets/img/home_list_01.jpg';
import ListViewBanner2 from '../../assets/img/home_list_02.jpg';
import ListViewBanner3 from '../../assets/img/home_list_03.jpg';
import NewBottomMenu from '../../components/NewBottomMenu';
import {isRunRoomService} from '../../shared/util';
import * as Utils from '../../shared/util';
import {setMenuScrollTop} from '../../redux/app/appActions';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import * as Service from '../../shared/apiService';
import {APP_KEY_USER_ZONE_CODE} from '../../shared/Constants';

SwiperCore.use([Pagination, Navigation, Autoplay]);

class Home extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      poidIngCountOfRoom: 0,
      poidIngCountOfConcierge: 0,
      isVooroomHoliday: false,
      isEmergency: null,
      isServiceTime: false,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const zoneCode = Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE);
    console.log('[부룸 구역 정보] ' + zoneCode);

    let isVrHoliday = Utils.isVooroomHoliday();
    this.setState({isVooroomHoliday: isVrHoliday});
    console.log('[부룸 정기 휴무 여부] ' + isVrHoliday);

    let isVrTime = Utils.isVooroomCheckOnTime();
    this.setState({isServiceTime: isVrTime});
    console.log('[부룸 서비스 타임 여부] ' + isVrTime);

    this.getZoneInfo(zoneCode);
  }

  // -- feature -------------------------------------------------------------------------------
  getZoneInfo = zoneCode => {
    Service.getZoneInfo(zoneCode).then(result => {
      console.log(result);
      this.setState({zoneInfo: result});
      this.setState({isEmergency: result.is_emergency});
      console.log('[부룸 이멀전시 여부] ' + result.is_emergency);
    });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------

  render() {
    const {isVooroomHoliday, isEmergency, isServiceTime} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v1" style={{backgroundColor: '#F6F6F6'}}>
            <div className="hd-logo">
              <Logo />
            </div>
          </div>
        </header>
        <section className="v15-home">
          <div className="v15-swiper-wrap">
            <Swiper
              className="v15-swiper-zone"
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              slidesPerView={1}>
              <SwiperSlide>
                <div className="v15-swiper-slide v15-swiper-slide-img-01" />
              </SwiperSlide>
              <SwiperSlide>
                <div className="v15-swiper-slide v15-swiper-slide-img-02" />
              </SwiperSlide>
              <SwiperSlide>
                <div className="v15-swiper-slide v15-swiper-slide-img-03" />
              </SwiperSlide>
            </Swiper>
          </div>

          <div style={{paddingLeft: '9px', paddingRight: '9px'}}>
            <div className="v15-menu-box-zone">
              <div
                className="v15-menu-box"
                onClick={() => {
                  this.props.history.push('/menu');
                }}>
                <div className="horizontal full-width" style={{marginBottom: '4px'}}>
                  <div className="v15-menu-box-icon">
                    <RoomService />
                  </div>
                  <div className="v15-menu-box-title">룸서비스</div>
                </div>
                <div className="horizontal full-width">
                  <div className="v15-menu-box-icon">
                    {/*isVooroomHoliday === false && isEmergency === 'N' && isServiceTime ? <IcoOn width={36} /> : <IcoOff width={36} />*/}
                    <IcoOff width={36} />
                  </div>
                  <div className="v15-menu-box-body-zone">
                    <div className="horizontal v15-menu-box-body" style={{fontSize: '12.8px', fontWeight: 400}}>
                      <IcoSun width={20} />
                      &nbsp;11:00~14:00
                    </div>
                    <div className="horizontal v15-menu-box-body" style={{fontSize: '12.4px', fontWeight: 400}}>
                      <IcoMoon width={20} />
                      &nbsp;17:00~20:00
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="v15-menu-box"
                onClick={() => {
                  this.props.history.push('/concierge/menu');
                }}>
                <div className="horizontal full-width" style={{marginBottom: '4px'}}>
                  <div className="v15-menu-box-icon">
                    <Concierge />
                  </div>
                  <div className="v15-menu-box-title">컨시어지</div>
                </div>
                <div className="horizontal full-width">
                  <div className="v15-menu-box-icon">
                    <IcoOn width={36} />
                  </div>
                  <div className="v15-menu-box-body-zone">
                    <div className="horizontal v15-menu-box-body">
                      24H&nbsp;<span style={{fontWeight: '600'}}>ON</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="v15-menu-box-zone">
              <div
                className="v15-menu-box"
                onClick={() => {
                  Utils.showAlertConfirm('[ 준비중 ]\n해당 서비스는 오픈 준비 중입니다.\n조금만 기다려주세요.');
                }}>
                <div className="horizontal full-width" style={{marginBottom: '4px'}}>
                  <div className="v15-menu-box-icon">
                    <Shopping />
                  </div>
                  <div className="v15-menu-box-title">공구위크</div>
                </div>
                <div className="horizontal full-width">
                  <div className="v15-menu-box-icon">
                    <IcoOff width={36} />
                  </div>
                  <div className="v15-menu-box-body-zone">
                    <div className="horizontal v15-menu-box-body">
                      <span style={{fontWeight: '400', color: '#888', fontSize: '14px'}}>(준비중)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="v15-menu-box"
                onClick={() => {
                  this.props.history.push('/community/menu');
                }}>
                <div className="horizontal full-width" style={{marginBottom: '4px'}}>
                  <div className="v15-menu-box-icon">
                    <Community />
                  </div>
                  <div className="v15-menu-box-title">커뮤니티</div>
                </div>
                <div className="horizontal full-width">
                  <div className="v15-menu-box-icon">
                    <IcoOn width={36} />
                  </div>
                  <div className="v15-menu-box-body-zone">
                    <div className="horizontal v15-menu-box-body">
                      24H&nbsp;<span style={{fontWeight: '600'}}>ON</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full-width">
            <div className="v15-card-list-wrap">
              <div className="v15-card">
                <div className="horizontal">
                  <div className="home-card-list-img-wrap">
                    <img src={ListViewBanner1} loading="lazy" className="home-card-list-img-tumb" />
                  </div>
                  <Link
                    to={{
                      pathname: '/menu/detail',
                      state: {
                        title: '떡볶이',
                        menuId: '4_DT2_G1_M6',
                      },
                    }}>
                    <div className="full-width">
                      <div className="v-ellipsis home-card-list-title">이달의 룸서비스 인기 메뉴</div>
                      <div className="home-card-list-body">아는 맛이 더 무섭다! 인기 메뉴 무엇?</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="v15-card-list-wrap">
              <div className="v15-card">
                <div className="horizontal">
                  <div className="home-card-list-img-wrap">
                    <img src={ListViewBanner2} loading="lazy" className="home-card-list-img-tumb" />
                  </div>
                  <Link
                    to={{
                      pathname: '/concierge/menu/detail',
                      state: {
                        title: '건강',
                        menuId: '4_DT2_G1_C7',
                      },
                    }}>
                    <div className="full-width">
                      <div className="v-ellipsis home-card-list-title">지금 행사중인 컨시어지 서비스</div>
                      <div className="home-card-list-body">어떤 서비스를 이용해 볼까? 청소?세탁?</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="v15-card-list-wrap">
              <div className="v15-card">
                <div className="horizontal">
                  <div className="home-card-list-img-wrap">
                    <img src={ListViewBanner3} loading="lazy" className="home-card-list-img-tumb" />
                  </div>
                  <Link
                    to={{
                      pathname: '/community/menu/detail',
                      state: {
                        title: '행사소식',
                        menuId: 'VR_COMMUNITY_M2',
                      },
                    }}>
                    <div className="full-width">
                      <div className="v-ellipsis home-card-list-title">우리동네 HOT NEWS</div>
                      <div className="home-card-list-body">우리동네의 소식을 가장 빨리 알아보기</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer" style={{backgroundColor: '#FFFFFF'}}>
          <NewBottomMenu history={this.props.history} menuItem={1} />
        </footer>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Home));
