import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {ReactComponent as Logo} from '../../assets/img/img_logo1.svg';
import {ReactComponent as ServiceTime} from '../../assets/img/vr_banner_time.svg';
import {ReactComponent as BreakTime} from '../../assets/img/vr_break_time.svg';
import {ReactComponent as VooroomHoliday} from '../../assets/img/vr_vooroom_holiday.svg';
import {ReactComponent as VooroomRainOff} from '../../assets/img/vr_rain_off.svg';
import {ReactComponent as BedgePop} from '../../assets/img/bedge_popular.svg';
import {ReactComponent as BedgeNew} from '../../assets/img/bedge_new.svg';
import {ReactComponent as BedgeRec} from '../../assets/img/bedge_recommend.svg';
import {ReactComponent as ExpandMore} from '../../assets/img/expand_more.svg';
import {ReactComponent as ExpandLess} from '../../assets/img/expand_less.svg';
import {ReactComponent as EventText} from '../../assets/img/top_baaner_event_text.svg';

import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {api} from '../../api';
import {
  APP_KEY_ON_BOARDING,
  APP_KEY_POPUP_24_DATE,
  APP_KEY_POPUP_24_DATE2,
  APP_KEY_POPUP_24_DATE3,
  APP_KEY_USER_EMAIL,
  APP_KEY_USER_FORCE_LOGOUT,
  APP_KEY_USER_ROOM,
  APP_KEY_USER_UUID,
  APP_KEY_USER_ZONE_CODE,
  DEFAULT_ZONE_CODE,
  DT1_ZONE_CODE,
} from '../../shared/Constants';
import Spinner from '../../components/Spinner';
import {setMenuAlertHoliday, setMenuPopupShow1, setMenuPopupShow2, setMenuPopupShow3, setMenuScrollTop} from '../../redux/app/appActions';
import BottomMenu from '../../components/BottomMenu';
import NewBottomMenu from '../../components/NewBottomMenu';

class Menu extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      menuList: [],
      zoneInfo: {},
      basketCount: 0,
      theposition: window.pageYOffset,
      roomInfo: null,
      popupShow: false,
      popupShow2: false,
      popupShow3: false,
      aptName: '',
      isVooroomHoliday: false, // 부룸 정기 휴무일 : 매주 월
      isServiceTime: true,
      isEmergency: null, // 비상모드 : 해당 구역 전체 서비스 불가
      isAptNoService: false, // 특정 아파트 단지 서비스 제한 : 특정 아파트 단지 서비스 불가
      scrollY: null,
      isFooterCompanyInfo: false,
      isDevBetaMode: false, // 개발모드인가?
      isLoading: true,
      zoneCode: null,
      userEmail: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const {dispatch, app} = this.props;

    // - 개발 모드 셋팅
    const isDev = Utils.isDebuggableEnv();
    if (isDev) {
      this.setState({isDevBetaMode: isDev});
      console.log('######### 부룸 개발 모드 #########');
    }

    // - 테스트 아이디 강제 로그아웃 처리
    const email = Utils.getLocalStorage(APP_KEY_USER_EMAIL);
    const zoneCode = Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE);
    this.setState({zoneCode: Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE)});

    if (email === 'test@test.com') {
      Utils.showAlertConfirm('정상적인 접근이 아닙니다.\n다시 로그인해 주세요.');
      Utils.processLogOut();
      this.props.history.replace('/login');
    } else if (!zoneCode) {
      // 강제 로그 아웃
      this.forceLogOut();
    } else {
      this.setState({userEmail: email});
      console.log('[정상 로그인] ' + email + ' / ' + zoneCode);
    }

    // - 팝업창 정보 삭제
    //if (Utils.getLocalStorage(APP_KEY_POPUP_24_DATE)) Utils.delLocalStorage(APP_KEY_POPUP_24_DATE);
    //if (Utils.getLocalStorage(APP_KEY_POPUP_24_DATE2)) Utils.delLocalStorage(APP_KEY_POPUP_24_DATE2);
    //if (Utils.getLocalStorage(APP_KEY_POPUP_24_DATE3)) Utils.delLocalStorage(APP_KEY_POPUP_24_DATE3);

    // - 온보딩 화면
    Utils.getLocalStorageSync(APP_KEY_ON_BOARDING).then(result => {
      /* if (result !== 'true') {
        this.props.history.replace('/onBoarding');
      } else {*/
      // - 강제 로그 아웃 처리 체크
      Utils.getLocalStorageSync(APP_KEY_USER_FORCE_LOGOUT).then(force => {
        console.log('강제 로그아웃 필드값 : ' + force);

        if (force !== 'N') {
          this.forceLogOut(); // 강제 로그 아웃
        }
      });

      // 메뉴 정보 가져오기
      this.getMenuListByZoneCode(zoneCode);

      // 구역 정보 가져오기
      this.getZoneInfo(zoneCode);

      // 이전 스크롤 위치 기억
      window.addEventListener('scroll', this.handleScroll);
      this.setState({isServiceTime: Utils.isVooroomCheckOnTime()});

      // - 부룸 휴무일 체크
      let isVrHoliday = Utils.isVooroomHoliday();

      // - 화이트리스트
      if (Utils.isWhiteList(email)) {
        console.log('[화이트리스트 - ON] ' + email);
        isVrHoliday = false;
      }

      this.setState({isVooroomHoliday: isVrHoliday});
      console.log('[부룸 휴무 여부] ' + isVrHoliday);

      if (isVrHoliday && app.holidayAlertShow && zoneCode) {
        // 부룸 휴무 팝업창 보여줌
        //Utils.showHolidayAlertPopup();
        dispatch(setMenuAlertHoliday(false));
      }

      // - TOID 발급 체크
      if (zoneCode !== DEFAULT_ZONE_CODE) {
        Utils.checkToid().then(result => {
          if (result) {
            Service.getMyBasket().then(result => {
              let bCount = 0;
              result.forEach((item, idx) => {
                bCount += item.count;
              });
              if (bCount > 0) this.setState({basketCount: bCount});
            });
          } else {
            console.log('checkToid Null');
          }
        });
      }

      // - TODO: 임시로 룸정보를 가져온다. 추후 사용자가 아파트 설정할때까지 지역코드를 알수 없기에 공백 화면이다
      Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
        if (rInfo !== null && rInfo.length > 0) {
          this.setState({roomInfo: rInfo});

          // - 아파트
          const rInfoObj = rInfo.split('/');
          const aptName = rInfoObj[0];
          console.log('aptName : ' + aptName);
          this.setState({aptName: aptName});
          Service.getZoneAptList(zoneCode).then(result => {
            result.forEach((item, idx) => {
              if (item.is_open === 'N' && item.apt_name === aptName) {
                console.log('[해당 아파트 단지 서비스 제한] ' + aptName);
                this.setState({isAptNoService: true});
                Utils.showEmergencyAlertPopup();
              }
            });
          });
        }
      });

      // - TODO: 임시로 앱시작시 POPUP창 동작 로직, 추후에는 DB에서 제어할수 있도록 해야함. (보여지지 않으면 해당 코드 주석 처리)
      // - [10월 28일] 11월 휴무 팝업창 (1구역 사용자에게만 노출)
      /*if (zoneCode === '4_DT2_G1') {
        Utils.getLocalStorageSync(APP_KEY_POPUP_24_DATE3).then(result => {
          if (result === null || result === undefined) {
            this.setState({popupShow3: app.popupShow3});
          } else {
            const diff = Utils.getDateDiff(result);
            // 날짜가 1일 이상 차이가 난다면..
            if (diff >= 1) {
              Utils.delLocalStorage(APP_KEY_POPUP_24_DATE3);
              this.setState({popupShow3: true});
              dispatch(setMenuPopupShow3(true));
            }
          }
        });
      }*/

      // - TODO: 임시로 앱시작시 POPUP창 동작 로직, 추후에는 DB에서 제어할수 있도록 해야함. (보여지지 않으면 해당 코드 주석 처리)
      // - [11월 30일] 부룸 서비스 종료 팝업
      if (zoneCode === '4_DT2_G1') {
        Utils.getLocalStorageSync(APP_KEY_POPUP_24_DATE2).then(result => {
          console.log(app);
          if (result === null || result === undefined) {
            this.setState({popupShow2: app.popupShow2});
          } else {
            const diff = Utils.getDateDiff(result);
            // 날짜가 1일 차이 난다면 다시 오픈
            if (diff >= 1) {
              Utils.delLocalStorage(APP_KEY_POPUP_24_DATE2);
              this.setState({popupShow2: true});
              dispatch(setMenuPopupShow2(true));
            }
          }
        });
      }

      // - TODO: 임시로 앱시작시 POPUP창 동작 로직, 추후에는 DB에서 제어할수 있도록 해야함. (보여지지 않으면 해당 코드 주석 처리)
      // - 추천 메뉴 팝업창으로 고정 사용
      /*if (!Utils.isVooroomHoliday()) {
        Utils.getLocalStorageSync(APP_KEY_POPUP_24_DATE).then(result => {
          if (result === null || result === undefined) {
            this.setState({popupShow: app.popupShow});
          } else {
            const diff = Utils.getDateDiff(result);
            // 하루 이상 차이가 난다면..
            if (diff >= 1) {
              Utils.delLocalStorage(APP_KEY_POPUP_24_DATE);
              this.setState({popupShow: true});
              dispatch(setMenuPopupShow1(true));
            }
          }
        });
      }*/
    });
  }

  componentDidUpdate() {
    const {scrollY} = this.state;
    const {app} = this.props;
    // - 스크롤 위치 기억
    if (scrollY === null) {
      this.setState({scrollY: app.menuScrollTop});
      setTimeout(() => {
        window.scrollTo(0, app.menuScrollTop);
      }, 100);
    }
  }

  // -- feature -------------------------------------------------------------------------------
  forceLogOut = () => {
    Utils.showAlertConfirm('다시 로그인해 주세요.');
    Utils.processLogOut();
    this.props.history.replace('/login');
  };

  handleScroll = e => {
    const {dispatch} = this.props;
    const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
    if (scrollTop !== 0) {
      //dispatch(setMenuScrollTop(scrollTop));
      this.setState({scrollY: scrollTop});
    }
  };

  getMenuListByZoneCode = zoneCode => {
    api
      .get(`/v1/menu/get?zoneCode=${zoneCode}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({menuList: data});
        this.setState({isLoading: false});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getMenuListByZoneCode');
      });
  };

  getZoneInfo = zoneCode => {
    Service.getZoneInfo(zoneCode).then(result => {
      this.setState({zoneInfo: result});

      this.setState({isEmergency: result.is_emergency});
      if (result.is_emergency !== 'N') {
        console.log('비상모드 선언!');

        // - 이멀전시 모드만 표시
        if (result.is_emergency === 'Y') {
          Utils.showEmergencyAlertPopup(); // - 비상선언
        }

        /*switch (result.is_emergency) {
          case 'Y':
            Utils.showEmergencyAlertPopup(); // - 비상선언
            break;
          case 'J':
            Utils.showWeatherDelayAlertPopup(); // - 기상지연 (오픈)
            break;
          case 'F':
            Utils.showOrderHeavyAlertPopup(); // - 주문폭주
            break;
          case 'M':
            Utils.showEmergencyAlertPopupByManager(); // - (매니저 이슈) 비상선언
            break;
          case 'W':
            Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 비상선언
            break;
          default:
            break;
        }*/
      }
    });

    /*api
      .get(`/v1/zone/get/${zoneCode}`)
      .then(res => {
        const {data} = res;
        if (data.length > 0) {
          console.log(data[0]);
          this.setState({zoneInfo: data[0]});

          if (data[0].is_emergency !== 'N') {
            console.log('비상모드 선언!');
            this.setState({isEmergency: data[0].is_emergency});

            switch (data[0].is_emergency) {
              case 'Y':
                Utils.showEmergencyAlertPopup(); // - 비상선언
                break;
              case 'J':
                Utils.showWeatherDelayAlertPopup(); // - 기상지연 (오픈)
                break;
              case 'F':
                Utils.showOrderHeavyAlertPopup(); // - 주문폭주
                break;
              case 'M':
                Utils.showEmergencyAlertPopupByManager(); // - (매니저 이슈) 비상선언
                break;
              case 'W':
                Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 비상선언
                break;
              default:
                break;
            }
          }

          // - (이멀전시) 화이트 리스트
          Utils.getLocalStorageSync(APP_KEY_USER_EMAIL).then(result => {
            console.log(result);
            if (Utils.isWhiteList(result)) {
              console.log('whiteList...pass..');
              this.setState({isEmergency: 'N'});
            } else {
              console.log('whiteList...not..');
            }
          });
        } else {
          console.log('error : getZoneInfo');
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getZoneInfo');
      });*/
  };

  // -- handler -------------------------------------------------------------------------------
  handleLoginCheck = path => {
    if (Utils.isLogin() === true) {
      this.props.history.push(path);
    } else {
      Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
      this.props.history.push('/login');
    }
  };

  /**
   * 룸서비스 주문 상태 체크
   */
  handleBasketCheck = () => {
    const {roomInfo, isEmergency, isAptNoService, isVooroomHoliday, zoneCode, userEmail} = this.state;
    console.log('handleBasketCheck', roomInfo);

    // TODO: 동탄1구역을 제외한 나머지는 룸서비스 준비중으로 표시함.
    console.log('handleBasketCheck', userEmail);
    if (zoneCode && zoneCode !== DT1_ZONE_CODE && zoneCode !== DEFAULT_ZONE_CODE) {
      Utils.showAlertConfirm("[ 이용 가능 서비스 안내 ]\n\n'룸서비스' :  준비중\n'컨시어지' : 준비중");
      return;
    }

    // - 부룸 휴무일
    /*if (isVooroomHoliday) {
      // 부룸 휴무 팝업창 보여줌
      Utils.showHolidayAlertPopup();
      return;
    }*/

    // - 비상선언
    /*if ((isEmergency && isEmergency !== 'N') || isAptNoService) {
      console.log('isEmergency : ' + isEmergency);
      console.log('isAptNoService : ' + isAptNoService);

      switch (isEmergency) {
        case 'Y':
          Utils.showEmergencyAlertPopup(); // - 비상선언
          break;
        case 'F':
          Utils.showOrderHeavyAlertPopup(); // - 주문폭주
          break;
        case 'M':
          Utils.showEmergencyAlertPopupByManager(); // - (내부 사정) 임시휴무
          break;
        case 'W':
          Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 임시휴무
          break;
        default:
          break;
      }
      return;
    }*/

    if (Utils.isLogin() === true) {
      if (roomInfo !== null && roomInfo.length > 0) {
        this.props.history.push('/order');
      } else {
        Utils.showAlertConfirm('[내설정] 아파트 설정 이후\n이용가능합니다.').then(() => {
          this.props.history.push('/setting');
        });
      }
    } else {
      Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
      this.props.history.push('/login');
    }
  };

  handlePopup24Hour = modal => {
    if (modal === 3) {
      Utils.setLocalStorage(APP_KEY_POPUP_24_DATE3, Utils.getTodayDateString());
      this.setState({popupShow3: false});
    } else if (modal === 2) {
      Utils.setLocalStorage(APP_KEY_POPUP_24_DATE2, Utils.getTodayDateString());
      this.setState({popupShow2: false});
    } else {
      Utils.setLocalStorage(APP_KEY_POPUP_24_DATE, Utils.getTodayDateString());
      this.setState({popupShow: false});
    }
  };

  // -- render --------------------------------------------------------------------------------
  renderBedgeMenu = code => {
    if (code === '4_DT2_G1_M35' || code === '4_DT2_G1_M33' || code === '4_DT2_G1_M26' || code === '4_DT2_G1_M23') {
      return <BedgePop style={{marginRight: '8px'}} />;
    } else if (code === '4_DT2_G1_M40' || code === '4_DT2_G1_M8' || code === '4_DT2_G1_M19') {
      return <BedgeNew style={{marginRight: '8px'}} />;
    } else if (code === '4_DT2_G1_M21' || code === '4_DT2_G1_M30' || code === '4_DT2_G1_M4') {
      return <BedgeRec style={{marginRight: '8px'}} />;
    }
  };

  renderBedgeSystem = flag => {
    if (flag === 'I') {
      return <BedgePop style={{marginRight: '8px'}} />;
    } else if (flag === 'S') {
      return <BedgeNew style={{marginRight: '8px'}} />;
    } else if (flag === 'C') {
      return <BedgeRec style={{marginRight: '8px'}} />;
    }
  };

  renderMenuList = () => {
    const {menuList, zoneInfo, isServiceTime, scrollY, isVooroomHoliday, isFooterCompanyInfo} = this.state;
    const {dispatch, app} = this.props;
    const renderItem = [];
    menuList.forEach((item, index) => {
      renderItem.push(
        <li key={`menu_list_li_${index}`}>
          <Link
            to={{
              pathname: '/menu/detail',
              state: {
                title: item.menu_name,
                menuId: item.menu_id,
              },
            }}
            onClick={() => {
              dispatch(setMenuScrollTop(scrollY));
            }}
            style={{
              verticalAlign: 'center',
              paddingRight: item.bedge !== 'N' ? '44px' : '',
            }}>
            {/*{this.renderBedgeMenu(item.menu_id)}*/}
            {this.renderBedgeSystem(item.bedge)}
            {item.menu_name}
          </Link>
        </li>,
      );
    });

    let pBottom = '100px';
    if (isServiceTime === false || zoneInfo.is_weather !== 'N' || isVooroomHoliday === true) pBottom = '180px';
    return (
      <div className="wrap-list-category" onScroll={this.handleScroll} style={{paddingBottom: pBottom}}>
        {/* TODO: 부룸 공구 WEEK 상단 배너 */}
        {/*<Link
          to={{
            pathname: '/menu/detail',
            state: {
              title: '부룸 공구 WEEK (~7.9)',
              menuId: '4_DT2_G1_M42',
            },
          }}>
          <div className="world-cup-view">
            <EventText />
          </div>
        </Link>*/}

        <ol className="list-category">{renderItem}</ol>
        <div className="footer-company-info">
          <div
            className="footer-company-info-name"
            onClick={() => {
              this.setState(prevState => ({
                isFooterCompanyInfo: !prevState.isFooterCompanyInfo,
              }));
            }}>
            <span>(주)부룸</span>&nbsp;사업자정보
            {!isFooterCompanyInfo ? <ExpandMore /> : <ExpandLess />}
          </div>
          {isFooterCompanyInfo && (
            <div className="footer-info">
              <p>
                상호명 : 주식회사 부룸 | 대표 : 장영훈
                <br />
                사업자등록번호 : 591-87-02499
                <br />
                통신판매업 신고번호 : 제 2022-화성동탄-1595 호
                <br />
                경기도 화성시 동탄첨단산업1로 27 금강펜테리움 IX타워 A동 2608호
                <br />
                이메일 : jaybee@vooroom.kr | 전화 : 0507-1350-8303
                <br />
                <span
                  onClick={() => {
                    console.log('개발자 모드');
                    Utils.sendToRnMessage('RN_BETA_DEV_MODE');
                  }}>
                  ⓒ VOOROOM Inc.
                </span>
              </p>
            </div>
          )}

          <div className="footer-company-info-menu">
            <div>
              <Link
                to={'/setting/cs'}
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                }}>
                고객센터
              </Link>
            </div>

            <div>
              <Link
                to={{
                  pathname: '/setting/terms',
                  state: {
                    uuid: 1,
                    title: '서비스 이용약관',
                  },
                }}
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                }}>
                서비스 이용약관
              </Link>
            </div>

            <div>
              <a
                onClick={() => {
                  dispatch(setMenuScrollTop(scrollY));
                  Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://vooroom.kr/terms');
                }}>
                개인정보 처리방침
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderBottomBanner = () => {
    const {zoneInfo, menuBottomValue} = this.state;

    // 1순위 - 부룸 휴무 체크
    if (Utils.isVooroomHoliday()) {
      return (
        <div className="ft-weather-view bg-vooroom-holiday">
          <VooroomHoliday />
        </div>
      );
    }

    // 2순위 - 부룸 시간 체크
    if (Utils.isVooroomCheckBreakTime()) {
      // 부룸 브레이크 타임
      return (
        /*<Link
              to={{
                pathname: '/menu/detail',
                state: {
                  title: '부룸 공구 WEEK (~7.9)',
                  menuId: '4_DT2_G1_M42',
                },
              }}>
              <div className="ft-weather-view bg-break-time">
                <BreakTime />
              </div>
            </Link>*/
        <div className="ft-weather-view bg-break-time">
          <BreakTime />
        </div>
      );
    } else if (!Utils.isVooroomCheckOnTime()) {
      // 부룸 운영외 시간
      return (
        /*<Link
              to={{
                pathname: '/menu/detail',
                state: {
                  title: '부룸 공구 WEEK (~7.9)',
                  menuId: '4_DT2_G1_M42',
                },
              }}>
              <div className="ft-weather-view bg-out-time">
                <ServiceTime />
              </div>
            </Link>*/
        <div className="ft-weather-view bg-out-time">
          <ServiceTime />
        </div>
      );
    }

    // 3순위 - 각종 상황 배너
    switch (zoneInfo.is_weather) {
      case 'H': // 주문량이 많아 배달 연기
        return (
          <div className="ft-weather-view bg-heavy">
            <div className="ft-weather-text1-1">
              현재 주문량이 많아 배달이 지연될 수 있습니다.
              <br />
              <span className="ft-weather-text2-1">고객님의 너그러운 양해부탁드립니다.</span>
            </div>
          </div>
        );
      case 'Z': // 기상 지연 (오픈)
        return (
          <div className="ft-weather-view bg-rain">
            <div className="ft-weather-text1">
              기상변화로 잠시 주문이 불가합니다.
              <br />
              <span className="ft-weather-text2">기상상황에 따라 다시 운영되오니 조금만 기다려주세요.</span>
            </div>
          </div>
        );
      case 'R': // 강우 배달 지연
        return (
          <div className="ft-weather-view bg-rain">
            <div className="ft-weather-text1">
              기상변화로 배달이 지연될 수 있습니다.
              <br />
              <span className="ft-weather-text2">배달 매니저의 안전을 위해 양해부탁드립니다.</span>
            </div>
          </div>
        );
      case 'DR': // 강우 배달 불가
        return (
          /*<Link
            to={{
              pathname: '/menu/detail',
              state: {
                title: '부룸 공구 WEEK (~7.9)',
                menuId: '4_DT2_G1_M42',
              },
            }}>
            <div className="ft-weather-view bg-rain-stop">
              <div className="ft-weather-text1" style={{paddingTop: '6px'}}>
                <VooroomRainOff />
              </div>
            </div>
          </Link>*/
          <div className="ft-weather-view bg-rain-stop">
            <div className="ft-weather-text1">
              <VooroomRainOff />
            </div>
          </div>
        );
      case 'S': // 강설 배달 지연
        return (
          <div className="ft-weather-view bg-snow">
            <div className="ft-weather-text1" style={{color: '#222222'}}>
              기상변화로 배달이 지연될 수 있습니다.
              <br />
              <span className="ft-weather-text2" style={{color: '#222222'}}>
                배달 매니저의 안전을 위해 양해부탁드립니다.
              </span>
            </div>
          </div>
        );
      case 'DS': // 강설 배달 불가
        return (
          <div className="ft-weather-view bg-snow-stop">
            <div className="ft-weather-text1" style={{color: '#222222'}}>
              기상악화로 배달이 불가합니다.
              <br />
              <span className="ft-weather-text2" style={{color: '#222222'}}>
                배달 매니저의 안전을 위해 양해부탁드립니다.
              </span>
            </div>
          </div>
        );
      case 'DI': // 빙판길로 인한 배달 불가
        return (
          <div className="ft-weather-view bg-snow-stop">
            <div className="ft-weather-text1" style={{color: '#222222'}}>
              빙판길로 인해 배달이 불가합니다.
              <br />
              <span className="ft-weather-text2" style={{color: '#222222'}}>
                배달 매니저의 안전을 위해 양해부탁드립니다.
              </span>
            </div>
          </div>
        );
      case 'D': // 메니저 휴무로 임시 휴무
        return (
          <div className="ft-weather-view bg-heavy">
            <div className="ft-weather-text1-1">
              {Utils.isTimeA() ? <>룸서비스 오전 파트 주문 불가 합니다.</> : <>룸서비스 오후 파트 주문 불가 합니다.</>}
              <br />
              <span className="ft-weather-text2-1">고객님의 너그러운 양해부탁드립니다.</span>
              {Utils.isTimeA() && (
                <>
                  <br />
                  <span className="ft-weather-text2-1">(오후 파트 운영 예정)</span>
                </>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const {menuList, basketCount, popupShow, popupShow2, popupShow3, zoneInfo, aptName, isDevBetaMode, isLoading, isEmergency, isServiceTime, isVooroomHoliday} = this.state;
    const {dispatch, app} = this.props;
    const customStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0px 2px 15px 2px rgba(0, 0, 0, 0.4)',
        borderRadius: 0,
        padding: 0,
        backgroundColor: '#000000',
        border: 'none',
      },
    };

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v1">
            <Link to={'/home'} className="hd-logo">
              <Logo />
            </Link>
            <div className="hd-basket-type2">
              {/*isVooroomHoliday === false && isEmergency === 'N' && isServiceTime ? <div className="hd-on"></div> : <div className="hd-off"></div>*/}
              <div className="hd-off" />
            </div>
            <div className="hd-basket">
              <a
                onClick={() => {
                  Utils.showAlertConfirm('룸서비스 정규시즌 종료');
                  //this.handleBasketCheck();
                }}
                className="hd-cart">
                <span className="count">{basketCount}</span>
              </a>
            </div>
          </div>
        </header>
        <section className="menu">
          <div className="wrap">
            <div className="wrap-top-page">
              <div className="top-page">룸서비스</div>
            </div>

            {menuList && this.renderMenuList()}
            {isLoading && <Spinner loading={isLoading} />}
          </div>
        </section>
        <footer className="footer">
          {/* 하단 배너 */}
          {/*zoneInfo && this.renderBottomBanner()*/}

          {/*<div className="ft">
            <div className="ft-menu">
              <a
                onClick={() => {
                  this.handleLoginCheck('/order/view');
                }}>
                <div className="ico"></div>
                <div className="tit">주문내역</div>
              </a>
              <Link to="/menu" className="active">
                <div className="ico"></div>
                <div className="tit">메뉴</div>
              </Link>
              <a
                onClick={() => {
                  this.handleLoginCheck('/setting');
                }}>
                <div className="ico"></div>
                <div className="tit">내설정</div>
              </a>
            </div>
          </div>*/}

          <NewBottomMenu history={this.props.history} menuItem={1} />
        </footer>
        {/* TODO - 평일 */}
        {!Utils.isWeekend() && (
          <Modal isOpen={popupShow} style={customStyles} ariaHideApp={false}>
            {Utils.isTimeA() ? (
              <div className="popup-notice-view">
                <Link
                  to={{
                    pathname: '/menu/detail',
                    state: {
                      title: '빵',
                      menuId: '4_DT2_G1_M18',
                    },
                  }}>
                  <div className="popup-notice-open-img-time-a"></div>
                </Link>
                <div className="popup-notice-open-btn">
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      this.handlePopup24Hour();
                    }}>
                    오늘 그만 보기
                  </div>
                  <div>|</div>
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      dispatch(setMenuPopupShow1(false));
                      this.setState({popupShow: false});
                    }}>
                    닫기
                  </div>
                </div>
              </div>
            ) : (
              <div className="popup-notice-view">
                <Link
                  to={{
                    pathname: '/menu/detail',
                    state: {
                      title: '베트남쌀국수',
                      menuId: '4_DT2_G1_M33',
                    },
                  }}>
                  <div className="popup-notice-open-img-time-b"></div>
                </Link>
                <div className="popup-notice-open-btn">
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      this.handlePopup24Hour();
                    }}>
                    오늘 그만 보기
                  </div>
                  <div>|</div>
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      dispatch(setMenuPopupShow1(false));
                      this.setState({popupShow: false});
                    }}>
                    닫기
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
        {/* TODO - 주말 */}
        {Utils.isWeekend() && (
          <Modal isOpen={popupShow} style={customStyles} ariaHideApp={false}>
            {Utils.isTimeA() ? (
              <div className="popup-notice-view">
                <Link
                  to={{
                    pathname: '/menu/detail',
                    state: {
                      title: '떡볶이',
                      menuId: '4_DT2_G1_M6',
                    },
                  }}>
                  <div className="popup-notice-open-img-time-a-weekend"></div>
                </Link>
                <div className="popup-notice-open-btn">
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      this.handlePopup24Hour();
                    }}>
                    오늘 그만 보기
                  </div>
                  <div>|</div>
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      dispatch(setMenuPopupShow1(false));
                      this.setState({popupShow: false});
                    }}>
                    닫기
                  </div>
                </div>
              </div>
            ) : (
              <div className="popup-notice-view">
                <Link
                  to={{
                    pathname: '/menu/detail',
                    state: {
                      title: '탕수육',
                      menuId: '4_DT2_G1_M25',
                    },
                  }}>
                  <div className="popup-notice-open-img-time-b-weekend"></div>
                </Link>
                <div className="popup-notice-open-btn">
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      this.handlePopup24Hour();
                    }}>
                    오늘 그만 보기
                  </div>
                  <div>|</div>
                  <div
                    style={{width: '49.5%'}}
                    onClick={() => {
                      dispatch(setMenuPopupShow1(false));
                      this.setState({popupShow: false});
                    }}>
                    닫기
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
        <Modal isOpen={popupShow2} style={customStyles} ariaHideApp={false}>
          <div className="popup-notice-view">
            <Link to={'/notice/7'}>
              <div className="popup-notice-open-img2"></div>
            </Link>
            <div className="popup-notice-open-btn" style={{backgroundColor: '#EEE'}}>
              <div
                style={{width: '49.5%'}}
                onClick={() => {
                  this.handlePopup24Hour(2);
                }}>
                오늘 그만 보기
              </div>
              <div>|</div>
              <div
                style={{width: '49.5%'}}
                onClick={() => {
                  dispatch(setMenuPopupShow2(false));
                  this.setState({popupShow2: false});
                }}>
                닫기
              </div>
            </div>
          </div>
        </Modal>
        {/* <Modal isOpen={popupShow3} style={customStyles} ariaHideApp={false}>
          <div className="popup-notice-view">
            <div className="popup-notice-open-img3"></div>
            <div className="popup-notice-open-btn">
              <div
                style={{width: '49.5%'}}
                onClick={() => {
                  this.handlePopup24Hour(3);
                }}>
                오늘 그만 보기
              </div>
              <div>|</div>
              <div
                style={{width: '49.5%'}}
                onClick={() => {
                  dispatch(setMenuPopupShow3(false));
                  this.setState({popupShow3: false});
                }}>
                닫기
              </div>
            </div>
          </div>
        </Modal>*/}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Menu));
