import React, {Component} from 'react';
import '../assets/css/common.css';
import {Link} from 'react-router-dom';
import * as Utils from '../shared/util';
import {APP_KEY_USER_ZONE_CODE, DEFAULT_ZONE_CODE} from '../shared/Constants';

class NewBottomMenu extends Component {
  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      menuItem: 0,
      zoneCode: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    let zone = Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE);
    this.setState({zoneCode: zone});

    if (this.props.menuItem) {
      this.setState({menuItem: this.props.menuItem});
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleLoginCheck = path => {
    const {zoneCode} = this.state;
    if (Utils.isLogin() === true) {
      if (zoneCode === DEFAULT_ZONE_CODE && path === '/main/order') {
        Utils.showAlertConfirm('[내설정] 아파트 설정 이후\n이용가능합니다.').then(() => {
          this.props.history.push('/setting');
        });
      } else {
        this.props.history.push(path);
      }
    } else {
      Utils.showAlertConfirmType2('로그인 후\n이용가능합니다.');
      this.props.history.push('/login');
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {menuItem} = this.state;
    return (
      <React.Fragment>
        {menuItem !== null && (
          <div className="ft" style={{backgroundColor: '#FFF', borderTop: '1px solid #EEE'}}>
            <div className="ft-menu2">
              <a
                className="item1_on"
                onClick={() => {
                  this.setState({menuItem: 0});
                  this.handleLoginCheck('/main/order');
                }}>
                <div className="ico"></div>
                <div className="tit">주문내역</div>
              </a>
              <Link to="/">
                <div className="ft-home">HOME</div>
              </Link>
              <a
                className="item2_on"
                onClick={() => {
                  this.setState({menuItem: 1});
                  this.handleLoginCheck('/setting');
                }}>
                <div className="ico"></div>
                <div className="tit">내설정</div>
              </a>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default NewBottomMenu;
