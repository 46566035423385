import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as Event1} from '../../assets/img/event_week.svg';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';

class Event extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">이벤트</div>
          </div>
        </header>

        <section className="setting-tv">
          <div className="setting-tv-wrap">
            <div className="setting-tv-text">
              <div className="event-view">
                <Event1 style={{marginTop: '6px', marginBottom: '6px'}} />
              </div>
            </div>
          </div>

          {/*<div className="wrap">
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">이벤트 준비중입니다.</div>
                  <div className="txt2">조금만 기다려 주세요 :)</div>
                </div>
              </div>
            </div>*/}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Event));
