import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as Call} from '../../assets/img/ico_cs_call.svg';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';

class CustomerCenter extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">고객센터</div>
          </div>
        </header>

        <section className="setting-tv">
          <div className="setting-tv-wrap">
            <div className="setting-tv-text">
              <div className="cs-item-view">
                <div className="cs-item-title" style={{fontSize: '15.4px', fontWeight: '700'}}>
                  이메일 문의
                </div>
                <div className="cs-item-body" style={{fontSize: '15.4px'}}>
                  jaybee@vooroom.kr
                </div>
              </div>
              <div
                className="cs-item-view"
                onClick={() => {
                  Utils.sendToRnMessage('RN_PHONE_LINK', 'tel:050713508303');
                }}>
                <div className="cs-item-title" style={{fontSize: '15.4px', fontWeight: '700'}}>
                  고객센터
                </div>
                <div className="cs-item-body" style={{fontSize: '15.4px'}}>
                  0507-1350-8303
                </div>
                <div className="cs-item-call" style={{width: '40%'}}>
                  <Call />
                </div>
              </div>

              <div className="cs-faq">
                <div className="cs-item-title" style={{marginBottom: '16px', fontSize: '15.4px', fontWeight: '700'}}>
                  자주 하는 질문
                </div>
                <div className="cs-item-title" style={{marginBottom: '8px'}}>
                  1. 음식 주문 취소/환불은 어떻게 하나요?
                </div>
                <div className="cs-item-body" style={{marginBottom: '24px', color: '#777'}}>
                  앱 결제완료 후 바로 음식 조리가 시작되기 때문에
                  <br />
                  취소/환불은 사용자 앱에서는 불가하며
                  <br />
                  음식점 or 고객센터를 통해서 전화로 취소 요청을 한 후
                  <br />
                  음식점에서 음식 조리 상태를 확인하여
                  <br /> 취소/환불 여부를 최종 결정 할 수 있습니다.
                  <br /> 양해 부탁 드립니다.
                </div>
                <div className="cs-item-title" style={{marginBottom: '8px'}}>
                  2. 음식 배달은 비대면으로 이루어지나요?
                </div>
                <div className="cs-item-body" style={{marginBottom: '24px', color: '#777'}}>
                  부룸의 음식 배달은 비대면으로 이루어집니다.
                  <br />
                  최종 배달 음식을 고객님의 문앞에 둔 후
                  <br />
                  초인종을 눌러 음식이 도착하였음을 안내 드리고 있으니
                  <br />
                  비대면으로 음식 수령 부탁 드립니다.
                </div>
                <div className="cs-item-title" style={{marginBottom: '8px'}}>
                  3. 묶음 주문 시, 여러 가지 음식이 한 번에 배달 되나요?
                </div>
                <div className="cs-item-body" style={{marginBottom: '24px', color: '#777'}}>
                  묶음 주문 된 음식은 조리완료 시점이 유사한 경우에만
                  <br />
                  묶음 배달되며, 시점의 차이가 많이 나는 경우에는
                  <br />
                  조리 시점에 따라 시차 배달 진행됩니다.
                  <br />
                  양해 부탁 드립니다.
                  <br />
                  <br />
                  (예) 아메리카노 [ 조리 5분 ] + 파스타 [ 조리 20분 ] <br />
                  묶음 주문 시 → 아메리카노 우선 배달
                </div>
              </div>

              {/*              [ 부룸 고객센터 ]<br />
              <br />
              부룸 플랫폼, 음식, 서비스, 환불요청, 결제오류 외 여러가지 서비스
              관련한 만족스럽지 못한 사항에 대하여, 언제든지 부룸 고객센터로
              문의해 주세요.
              <br />
              <br />
              - 대표번호 : 0507-1350-8303
              <br />
              - 이메일 : jaybee@vooroom.kr
              <br />
              <br />[ 자주묻는 질문 ]<br />
              <br />
              1. 음식 주문 취소/환불은 어떻게 하나요?
              <br />
              앱 결제완료 후 바로 음식 조리가 시작되기 때문에, 취소/환불은
              사용자 앱에서는 불가하며, 음식점 or 고객센터를 통해서, 전화로
              최소요청을 한 후, 음식점에서 음식 조리 상태를 확인하여, 최소/환불
              여부를 최종 결정 할 수 있습니다. 양해 부탁 드립니다.
              <br />
              <br />
              2. 회원 탈퇴는 어떻게 하나요?
              <br />
              부룸 고객센터(이메일)을 통하여, 회원 탈퇴 신청을 해주시면,
              담당자가 확인 후 처리 및 안내 연락 송부 드립니다.
              <br />*/}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(CustomerCenter));
