import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import {api} from '../../api';
import {APP_KEY_POID} from '../../shared/Constants';
import {cycleConciergeStateToStr, cycleStateToStr, getOrderDateTimeStr} from '../../shared/util';

class ConciergeOrderViewDetail extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      poidList: [],
      vPoid: null,
      vDate: '',
      vServiceDate: null,
      vTotalCount: 0,
      vTotalProductPrice: 0,
      vFinalPayPrice: 0,
      vStoreId: [],
      vStoreName: '',
      vStateLabel: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    console.log('poid : ' + this.props.location.state.poid);
    this.setState({vPoid: this.props.location.state.poid}, () => {
      this.getConciergePoidList(this.state.vPoid);
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getConciergePoidList = poid => {
    api
      .get(`/v1/concierge/poid?poid=${poid}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({poidList: data}, () => {
          this.parsePoidData();
        });
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getConciergePoidList');
      });
  };
  parsePoidData = () => {
    const {poidList, vDate, vStoreId, vStoreName} = this.state;

    // - 주문 일시
    this.setState({
      vDate: Utils.getOrderDateTimeStr(poidList[0].create_date),
    });
    // - 총 수량
    this.setState({vTotalCount: poidList.length});
    // - 총 상품금액
    this.setState({vFinalPayPrice: poidList[0].pay_price});
    // - 주문사이클상태
    this.setState({
      vStateLabel: Utils.cycleConciergeStateToStr(poidList[0].poid_state),
    });
    // - 예약일정
    this.setState({vServiceDate: poidList[0].service_date});

    let tempPrice = 0;
    let tempStoreName = '';
    poidList.forEach((item, idx) => {
      tempPrice += item.boid_price * item.count;

      if (vStoreId.indexOf(item.store_id) === -1) {
        vStoreId.push(item.store_id);
        tempStoreName === '' ? (tempStoreName += item.store_name) : (tempStoreName += ', ' + item.store_name);
      }
    });
    this.setState({vTotalProductPrice: tempPrice});
    this.setState({vStoreName: tempStoreName});
  };
  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  renderBoidList = () => {
    const {poidList} = this.state;

    const renderItem = [];
    poidList.forEach((item, index) => {
      renderItem.push(
        <li key={`orderDetailboidList_li_${index}`}>
          <div className="tit">{item.product_name}</div>
          <div className="count">
            <span>{item.count}</span>개
          </div>
          <div className="price">
            <span>{Utils.wonPriceStr(item.boid_price * item.count)}</span>원
          </div>
        </li>,
      );
    });

    return <ol className="list-order-menu">{renderItem}</ol>;
  };

  render() {
    const {poidList, vPoid, vDate, vTotalCount, vTotalProductPrice, vFinalPayPrice, vStoreName, vStateLabel, vServiceDate} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">상세내역</div>
          </div>
        </header>

        <section className="order-view">
          <div className="wrap">
            <div className="box">
              <div className="order-view-detail-label">{vStateLabel}</div>
              <ol className="list-order-data">
                <li>
                  <div className="tit">주문일시</div>
                  <div className="txt">
                    <span>{vDate}</span>
                  </div>
                </li>

                <li>
                  <div className="tit">주문번호</div>
                  <div className="txt">
                    <span>{vPoid}</span>
                  </div>
                </li>

                <li>
                  <div className="tit">가게정보</div>
                  <div className="txt">
                    <span>{vStoreName}</span>
                  </div>
                </li>

                {vServiceDate && (
                  <li>
                    <div className="tit">예약일정</div>
                    <div className="txt">
                      <span>{Utils.getOrderDateTimeStr(vServiceDate)}</span>
                    </div>
                  </li>
                )}
              </ol>
            </div>

            <div className="box">
              <div className="box-title">주문 내역</div>
              {poidList && this.renderBoidList()}
            </div>

            <div className="box">
              <div className="box-title">결제 내역</div>
              <ol className="list-order-menu">
                <li>
                  <div className="tit">총 수량</div>
                  <div className="price">
                    <span>{vTotalCount}</span>개
                  </div>
                </li>

                <li>
                  <div className="tit">총 상품금액</div>
                  <div className="price">
                    <span>{Utils.wonPriceStr(vTotalProductPrice)}</span>원
                  </div>
                </li>

                <li>
                  <div className="tit">컨시어지 요금</div>
                  <div className="price">
                    <span>0</span>원
                  </div>
                </li>

                {/*<li>
                  <div className="tit">포인트 사용</div>
                  <div className="price">
                    <span>1,000</span>원
                  </div>
                </li>

                <li>
                  <div className="tit">쿠폰 할인</div>
                  <div className="price">
                    <span>1,000</span>원
                  </div>
                </li>*/}
              </ol>
            </div>

            <div className="box">
              <ol className="list-order-menu">
                <li>
                  <div className="box-txt-bold">총 결제금액</div>
                  <div className="box-txt-bold">
                    <span>{Utils.wonPriceStr(vFinalPayPrice)}</span>&nbsp;
                    <span style={{fontWeight: 400}}>원</span>
                  </div>
                </li>

                <li>
                  <div className="tit">결제 방법</div>
                  <div className="price">
                    <span>신용/체크카드</span>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(ConciergeOrderViewDetail));
