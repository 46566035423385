import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';

class MyPoint extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v2">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">포인트</div>
          </div>
        </header>

        <section className="mypoint">
          <div className="top">
            <div className="point-state">
              <div className="tit">보유 포인트</div>
              <div className="num">
                <span>0</span>P
              </div>
            </div>
          </div>

          <div className="wrap">
            <div className="no-data">
              <div className="txt-group">
                <div className="txt">포인트 적립 시스템 준비중입니다</div>
                <div className="txt2">조금만 기다려 주세요 :)</div>
              </div>
            </div>

            {/*<ol className="list-point-history">*/}
            {/*  <li>*/}
            {/*    <div className="sort">적립 포인트</div>*/}
            {/*    <div className="date">22.06.01</div>*/}
            {/*    <div className="num">1000P</div>*/}
            {/*  </li>*/}

            {/*  <li>*/}
            {/*    <div className="sort">적립 포인트</div>*/}
            {/*    <div className="date">22.06.01</div>*/}
            {/*    <div className="num">1000P</div>*/}
            {/*  </li>*/}

            {/*  <li>*/}
            {/*    <div className="sort">적립 포인트</div>*/}
            {/*    <div className="date">22.06.01</div>*/}
            {/*    <div className="num num-v1">-1000P</div>*/}
            {/*  </li>*/}

            {/*  <li>*/}
            {/*    <div className="sort">적립 포인트</div>*/}
            {/*    <div className="date">22.06.01</div>*/}
            {/*    <div className="num num-v1">-1000P</div>*/}
            {/*  </li>*/}
            {/*</ol>*/}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(MyPoint));
