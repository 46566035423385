import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {api} from '../../api';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {APP_KEY_USER_ROOM, APP_KEY_USER_STATUS, APP_KEY_USER_UUID, APP_KEY_USER_ZONE_CODE, DEFAULT_ZONE_CODE} from '../../shared/Constants';
import {isCheckLotteAddr, showAlertConfirm} from '../../shared/util';

class MyAddress extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      user_id: null,
      checkedAPT: false,
      chkAptIdx: null,
      aptCode: '',
      aptName: '',
      aptDong: '',
      aptHo: '',
      aptList: [],
      zoneCode: null,
      prevZoneCode: null,
      showAptList: [],
      searchText: '',
      addrFixZoneView: true,
      isOnlyOne: false,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    this.getTotalAptList();

    // room id 정보 업데이트
    this.setState({user_id: Utils.getLocalStorage(APP_KEY_USER_UUID)}, () => {
      Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
        if (rInfo) {
          const parseInfo = rInfo.split('/');
          if (parseInfo.length > 2) {
            this.setState({aptName: parseInfo[0]});
            this.setState({aptDong: parseInfo[1]});
            this.setState({aptHo: parseInfo[2]});
            this.setState({checkedAPT: true});
          }
        }
      });
    });

    // zone_code 디폴트값 설정
    Utils.getLocalStorageSync(APP_KEY_USER_ZONE_CODE).then(result => {
      if (result) {
        this.setState({zoneCode: result});
        this.setState({prevZoneCode: result});
      }
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getTotalAptList = () => {
    api
      .get(`/v1/zone/apt/list`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({aptList: data});
        this.setState({showAptList: data});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getTotalAptList');
      });
  };

  updateUserRoomInfo = uuid => {
    const {aptName, aptDong, aptHo, zoneCode} = this.state;
    const room_id = aptName + '/' + aptDong + '/' + aptHo;
    api
      .get(`/v1/user/update/room?uuid=${uuid}&room_id=${room_id}&zone_code=${zoneCode}`)
      .then(res => {
        console.log('[OK] updateUserRoomInfo');
        Utils.setLocalStorage(APP_KEY_USER_ROOM, room_id);
        Utils.setLocalStorage(APP_KEY_USER_ZONE_CODE, zoneCode);
        Utils.showAlertConfirm('정보가 수정 되었습니다.');
        this.props.history.goBack();
        //this.props.history.replace('/setting/addrSplash/' + aptCode);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] updateUserRoomInfo');
      });
  };

  updateUserDefaultRoom = uuid => {
    const {aptName, aptDong, aptHo, zoneCode} = this.state;
    const room_id = '';
    api
      .get(`/v1/user/update/room?uuid=${uuid}&room_id=${room_id}&zone_code=${zoneCode}`)
      .then(res => {
        console.log('[OK] updateUserDefaultRoom');
        Utils.delLocalStorage(APP_KEY_USER_ROOM);
        Utils.setLocalStorage(APP_KEY_USER_ZONE_CODE, zoneCode);
        Utils.showAlertConfirm('정보가 수정 되었습니다.');
        this.props.history.goBack();
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] updateUserDefaultRoom');
      });
  };

  // -- handler -------------------------------------------------------------------------------
  handleItemCheck = (item, idx) => {
    console.log('handleItemCheck : ' + idx);
    this.setState({addrFixZoneView: true});
    this.setState({chkAptIdx: idx});
    this.setState({aptName: item.apt_name});
    this.setState({aptCode: item.apt_id});
    this.setState({zoneCode: item.zone_code});

    if (item.zone_code === DEFAULT_ZONE_CODE) {
      this.setState({addrFixZoneView: false});
    }

    // 동이 1개뿐인 아파트/오피 인가?
    if (item.is_only_one === 'Y') {
      this.setState({isOnlyOne: true});
      this.setState({aptDong: '1'});
    } else {
      this.setState({isOnlyOne: false});
      this.setState({aptDong: ''});
    }
  };

  handleDong = e => {
    e.target.value = e.target.value.trim().toUpperCase();
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.substring(0, 5);
    }
    this.setState({aptDong: e.target.value});
  };

  handleHo = e => {
    e.target.value = e.target.value.trim().toUpperCase();
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.substring(0, 5);
    }
    this.setState({aptHo: e.target.value});
  };

  handleRegInfo = () => e => {
    const {aptName, aptDong, aptHo, user_id, zoneCode, prevZoneCode} = this.state;

    if (zoneCode === DEFAULT_ZONE_CODE) {
      this.updateUserDefaultRoom(user_id);
    } else {
      if (zoneCode && user_id && aptName.length > 0 && aptDong.length > 0 && aptHo.length > 0 && zoneCode.length > 0) {
        if (Utils.isValidAddress(aptDong) && Utils.isValidAddress(aptHo)) {
          // - 구역이 변경되면 기존 장바구니 비우기 함.
          if (prevZoneCode && prevZoneCode !== zoneCode) {
            Service.clearMyBasket().then();
          } else {
            console.log('[구역동일]--------------------');
            console.log('[이전구역] ' + prevZoneCode);
            console.log('[현재구역] ' + zoneCode);
          }

          // 정보 업데이트
          this.updateUserRoomInfo(user_id);
        } else {
          Utils.showAlertConfirm('숫자 또는 영어만 입력해 주세요.');
        }
      } else {
        Utils.showAlertConfirm('모든 정보를 입력해 주세요.');
      }
    }
  };

  handleSearchText = e => {
    const {aptList} = this.state;
    this.setState({searchText: e.target.value});

    const tempItem = [];
    aptList.forEach((item, idx) => {
      if (item.apt_name.indexOf(e.target.value) > -1) {
        tempItem.push(item);
      }
    });

    this.setState({showAptList: tempItem});
  };

  // -- render --------------------------------------------------------------------------------
  renderAptList = () => {
    const {showAptList, chkAptIdx, addrFixZoneView} = this.state;
    const renderItem = [];
    showAptList.forEach((item, idx) => {
      renderItem.push(
        <li key={`${item.apt_id}`}>
          <input
            id={'address_ck_' + idx}
            type="radio"
            name="address_ck"
            className="ck-custom"
            checked={chkAptIdx === idx ? true : false}
            onChange={() => {}}
            onClick={() => {
              this.handleItemCheck(item, idx);
            }}
          />
          <label htmlFor={'address_ck_' + idx}>{item.apt_name}</label>
        </li>,
      );
    });

    return (
      <ol className="list-address" style={{paddingTop: addrFixZoneView ? '250px' : '100Px'}}>
        {renderItem}
      </ol>
    );
  };

  render() {
    const {aptList, showAptList, checkedAPT, aptName, aptDong, aptHo, searchText, addrFixZoneView, isOnlyOne} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">주소 설정</div>
          </div>
        </header>

        <section className="address">
          <div className="address-fixed">
            <div className="box-search">
              <div className="inp-group-search">
                <input
                  type="text"
                  className="inp-search"
                  defaultValue={searchText}
                  onChange={this.handleSearchText}
                  onFocus={() => {
                    this.setState({addrFixZoneView: false});
                  }}
                />
                <button type="button" className="btn-search" />
              </div>
            </div>

            {addrFixZoneView && (
              <div className="box-address">
                <input type="text" className="inp-frm" disabled={true} defaultValue={aptName} />
                {!isOnlyOne ? (
                  <div className="inp-group-address">
                    <div className="inp-addr">
                      <input type="text" defaultValue={aptDong} onChange={this.handleDong} />
                      <span>동</span>
                    </div>

                    <div className="inp-addr">
                      <input type="text" defaultValue={aptHo} onChange={this.handleHo} />
                      <span>호</span>
                    </div>
                  </div>
                ) : (
                  <div className="inp-group-address">
                    <div className="inp-addr" style={{width: '100%'}}>
                      <input type="text" defaultValue={aptHo} onChange={this.handleHo} />
                      <span>호</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {showAptList && this.renderAptList()}

          <div className="address-temp-info">
            현재 목록에 있는 아파트/오피스텔만 등록 가능합니다.
            <br />
            서비스 가능 지역 아파트를 확장 중이오니
            <br />
            조금만 기다려 주세요 :)
          </div>

          <div className="btn-floating-bottom">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-custom btn-custom-v1"
                onClick={() => {
                  this.props.history.goBack();
                }}>
                취소
              </button>
              <button type="button" className="btn btn-custom" onClick={this.handleRegInfo()}>
                등록
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(MyAddress));
