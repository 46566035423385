import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {api} from '../../api';
import {APP_KEY_POID, APP_KEY_TOID, APP_KEY_USER_ROOM, APP_KEY_USER_SETTING_BELL, APP_KEY_USER_UUID, APP_KEY_USER_ZONE_CODE} from '../../shared/Constants';
import {getDecryptPhoneNumber, isEventProduct, isVooroomCheckOnTime, showAlertConfirmType3} from '../../shared/util';
import Spinner from '../../components/Spinner';

class Order extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      basketList: [],
      noServiceList: [],
      myToid: null,
      myRealName: null, // 사용자 실제 이름
      userId: null,
      aptName: '',
      aptDong: '',
      aptHo: '',
      zoneCode: null,
      isEmergency: 'N', // 비상모드 : 해당 구역 전체 서비스 불가
      isStoreService: true,
      isVooroomService: true,
      isVooroomHoliday: false, // 부룸 정기 휴무일 : 매주 화
      isLoading: true,
      isBell: true,
      isMenuPriceChange: false,
      roomServiceCharge: 1000,
      isOnlyTogetherProduct: false, // 공동 구매 제품만 존재
      isOnlyRoomServiceProduct: false, // 룸서비스 제품만 존재
      isNullPhoneNumber: true, // 고객 휴대폰 번호 등록 여부
    };
  }
  증;
  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    window.scrollTo(0, 0);

    // - 사용자 정보 가져오기
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    this.setState({userId: uuid});
    if (uuid) {
      Service.getUserInfoByUuid(uuid).then(result => {
        console.log('###########################');
        console.log(result);
        console.log('사용자 실제 이름 : ' + result.real_name);
        if (result.real_name) {
          this.setState({myRealName: result.real_name});
        }

        // 고객 전화 등록 유무 체크
        if (result.phone) {
          const parsePhone = Utils.getDecryptPhoneNumber(result.phone);
          console.log('사용자 전화번호 : ' + parsePhone);
          parsePhone.length > 8 && this.setState({isNullPhoneNumber: false});
        } else {
          console.log('등록된 사용자 전화번호 없음.');
        }
      });
    } else {
      console.log('uuid is NULL');
    }

    // - 구역 비상선언 정보 가져오기
    this.setState({zoneCode: Utils.getLocalStorage(APP_KEY_USER_ZONE_CODE)}, () => {
      Service.getZoneInfo(this.state.zoneCode).then(result => {
        this.setState({zoneInfo: result});
        this.setState({isEmergency: result.is_emergency});
        this.getMyBasket();
      });
    });
    this.setState({myToid: Utils.getLocalStorage(APP_KEY_TOID)});

    // - 부룸 운영시간 정보
    this.setState({isVooroomService: Utils.isVooroomCheckOnTime()});

    //  - 룸정보
    Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
      if (rInfo) {
        const parseInfo = rInfo.split('/');
        if (parseInfo.length > 2) {
          this.setState({aptName: parseInfo[0]});
          this.setState({aptDong: parseInfo[1]});
          this.setState({aptHo: parseInfo[2]});
        }
      }
    });

    // - 호출 밸정보
    Utils.getLocalStorageSync(APP_KEY_USER_SETTING_BELL).then(result => {
      if (result && result === 'N') {
        this.setState({isBell: false});
        console.log('isBell false');
      } else {
        console.log('isBell true');
      }
    });

    // - 부룸 휴무
    let isVrHoliday = Utils.isVooroomHoliday();
    this.setState({isVooroomHoliday: isVrHoliday});
    console.log('[부룸 휴무 여부] ' + isVrHoliday);
  }

  // -- feature -------------------------------------------------------------------------------
  getMyBasket = () => {
    const {isEmergency} = this.state;
    Service.getMyBasket().then(async result => {
      console.log(result);

      // - 서비스 상태 업데이트
      let sOutCount = 0;
      let togetherCount = 0;
      let eventProductCount = 0;
      for await (const item of result) {
        Utils.isCheckVRService(item, false).then(result => {
          if (result === false) {
            item.is_service = 'N';
            sOutCount++;
          } else {
            if (isEmergency === 'N') {
              item.is_service = 'Y';
            } else {
              item.is_service = 'N';
              sOutCount++;
            }
          }
        });

        // 공동구매 상품 체크
        if (Utils.isTogetherProduct(item.product_id)) {
          togetherCount++;
        }

        // 추석 이벤트 상품 체크
        if (Utils.isEventProduct(item.product_id)) {
          eventProductCount++;
        }
      }
      sOutCount > 0 ? this.setState({isStoreService: false}) : this.setState({isStoreService: true});
      this.setState({basketList: result}, () => {
        this.setState({isLoading: false});
      });

      // 공동구매 상품만 있는 경우 배달료 무료
      if (togetherCount === result.length) {
        console.log('ONLY 공동구매 상품만 존재.');
        this.setState({roomServiceCharge: 0});
        this.setState({isOnlyTogetherProduct: true});
      } else {
        console.log('룸서비스 상품 & 공동구매 상품 함께 존재.');
        this.setState({isOnlyTogetherProduct: false});
      }

      // 룸서비스 상품만 존재
      if (togetherCount === 0) {
        this.setState({isOnlyRoomServiceProduct: true});

        // 이벤트 상품만 존재시 배달료 0원
        if (eventProductCount === result.length) {
          console.log('ONLY 추석 이벤트 상품만 존재.');
          this.setState({roomServiceCharge: 0});
        }
      }
    });
  };

  calcTotalPrice = () => {
    const {basketList} = this.state;
    let mTotalPrice = 0;
    basketList.forEach((item, index) => {
      const finalPrice = item.boid_price ? item.boid_price : item.price;
      mTotalPrice += finalPrice * item.count;
    });
    console.log('[calcTotalPrice] ' + mTotalPrice);
    return mTotalPrice;
  };

  calcTotalCount = () => {
    const {basketList} = this.state;
    let mTotalCount = 0;
    basketList.forEach((item, index) => {
      mTotalCount += item.count;
    });
    console.log('[calcTotalCount] ' + mTotalCount);

    return mTotalCount;
  };

  showMaxLimitAlert = () => {
    Utils.showAlertConfirm('메뉴는 5개\n총 수량은 8개\n이하 주문 가능합니다.');
  };

  refreshBasket = async () => {
    const {basketList, isStoreService, isEmergency} = this.state;
    let sOutList = [];
    let sOutCount = 0;
    for await (const item of basketList) {
      Utils.isCheckVRService(item, false).then(result => {
        if (result === false) {
          item.is_service = 'N';
          sOutCount++;
        } else {
          if (isEmergency === 'N') {
            item.is_service = 'Y';
          } else {
            item.is_service = 'N';
            sOutCount++;
          }
        }
      });
      sOutList.push(item);
    }
    console.log(sOutList);
    sOutCount > 0 ? this.setState({isStoreService: false}) : this.setState({isStoreService: true});
    this.setState({basketList: sOutList});
  };

  // -- handler -------------------------------------------------------------------------------
  /**
   * 주문하기 버튼
   */
  handlePayment = async () => {
    const {
      basketList,
      isStoreService,
      isVooroomService,
      isNullPhoneNumber,
      isMenuPriceChange,
      isVooroomHoliday,
      isOnlyTogetherProduct,
      isOnlyRoomServiceProduct,
      isEmergency,
      myRealName,
    } = this.state;

    // 상태 업데이트
    await this.refreshBasket();

    // - 공동구매 상품 체크
    if (isOnlyTogetherProduct === false && isOnlyRoomServiceProduct === false) {
      Utils.showAlertConfirm('공동구매 상품은 해당 상품들만\n묶음 주문이 가능합니다.\n재주문 부탁드립니다.');
      return;
    }

    // - 룸서비스 이멀전시 체크
    if (isOnlyRoomServiceProduct && isEmergency) {
      if (isEmergency !== 'N') {
        console.log('[비상모드] ' + isEmergency);
        switch (isEmergency) {
          case 'Y':
            Utils.showEmergencyAlertPopup(); // - 비상선언
            return;
          case 'J':
            Utils.showWeatherDelayAlertPopup(); // - 기상지연 (오픈)
            return;
          case 'F':
            Utils.showOrderHeavyAlertPopup(); // - 주문폭주
            return;
          case 'M':
            Utils.showEmergencyAlertPopupByManager(); // - (매니저 이슈) 비상선언
            return;
          case 'W':
            Utils.showEmergencyAlertPopupByWeather(); // - (기상 이슈) 비상선언
            return;
          default:
            break;
        }
      }
    }

    // 가격 변동 체크
    if (isMenuPriceChange) {
      Utils.showAlertConfirm('일부 상품 가격이 변동되었습니다.\n재주문 부탁드립니다.');
      return;
    }

    // 고객 휴대폰 번호 유무 체크

    // 공동구매 상품은 시간 제약 없음
    if (Utils.isVooroomCheckOnTime() === false && isOnlyTogetherProduct === false) {
      Utils.isWeekend()
        ? Utils.showAlertConfirmType3('지금은 부룸 서비스 운영 준비로\n주문이 어렵습니다.\n\n[부룸 운영시간 안내]\n☀️️ 11:00~14:00\n🌙 17:00~20:00')
        : Utils.showAlertConfirmType3('지금은 부룸 서비스 운영 준비로\n주문이 어렵습니다.\n\n[부룸 운영시간 안내]\n☀️️ 11:00~14:00\n🌙 17:00~20:00');
    } else if (isStoreService === false) {
      Utils.showAlertConfirm('스토어 사정으로\n일부 메뉴 주문이 불가합니다.\n\n주문이 불가한 메뉴를 삭제 후\n주문을 진행해 주시기 바랍니다.');
    } else if (isVooroomHoliday && isOnlyTogetherProduct === false) {
      Utils.showHolidayAlertPopup();
    } else if (isNullPhoneNumber) {
      Utils.showAlertConfirm('연락처 정보 등록 후\n주문을 진행해 주시기 바랍니다.').then(() => {
        this.props.history.push('/login/ReAuthPhone');
      });
    } else {
      // 모든 메뉴 주문 가능
      const totalItemCount = this.calcTotalCount();
      const totalMenuCount = basketList.length;

      if (totalItemCount <= 8 && totalMenuCount <= 5) {
        if (isOnlyTogetherProduct) {
          // 공동구매 진행
          if (myRealName === null) {
            // 수령인 정보 입력
            Utils.showAlertConfirm('상품 수령인 정보를 입력해 주세요.').then(() => {
              this.props.history.push('/setting/myinfo');
            });
          } else {
            // 결제 진행
            this.props.history.push('/payment/room');
          }
        } else {
          // 결제 진행
          this.props.history.push('/payment/room');
        }
      } else {
        this.showMaxLimitAlert();
      }
    }
  };

  /**
   * 상품 삭제
   */
  handleDelItem = mBoid => {
    if (mBoid !== null) {
      api
        .get(`/v1/order/boid/delete?boid=${mBoid}`)
        .then(res => {
          const {data} = res;
          console.log(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.getMyBasket();
          console.log('[DONE] handleDelItem : ' + mBoid);
        });
    } else {
      console.log('[handleDelItem] BOID NULL.');
    }
  };

  /**
   * 전체 상품 삭제
   */
  handleDelAllItem = () => {
    const {myToid} = this.state;
    if (myToid !== null) {
      api
        .get(`/v1/order/boid/deleteAll?toid=${myToid}`)
        .then(res => {
          const {data} = res;
          console.log(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.getMyBasket();
          console.log('[DONE] handleDelAllItem : ' + myToid);
        });
    } else {
      console.log('[handleDelAllItem] TOID NULL.');
    }
  };

  /**
   * 상품 갯수 추가
   */
  handleItemPlus = (item, mCnt, pID) => {
    const {myToid} = this.state;
    const totalCount = this.calcTotalCount();
    if (totalCount + 1 > 8) {
      this.showMaxLimitAlert();
    } else {
      Utils.isCheckVRService(item, true).then(() => {
        Service.updateBOID(myToid, pID, mCnt + 1).then(() => {
          this.getMyBasket();
        });
      });
    }
  };

  /**
   * 상품 갯수 제거
   */
  handleItemMinus = (mCnt, pID) => {
    const {myToid} = this.state;
    if (mCnt - 1 > 0) {
      Service.updateBOID(myToid, pID, mCnt - 1).then(() => {
        this.getMyBasket();
      });
    }
  };

  // -- render --------------------------------------------------------------------------------
  renderBasketList = () => {
    const {basketList, loading, isMenuPriceChange, isVooroomHoliday} = this.state;
    const renderItem = [];

    let chkMenuPriceChange = false;
    basketList.forEach((item, index) => {
      // 가격변동 체크
      if (!isMenuPriceChange && item.boid_price !== item.price) {
        chkMenuPriceChange = true;
        console.log('[가격 변동 발견]');
        console.log(item);
      }

      const finalPrice = item.boid_price ? item.boid_price : item.price;
      renderItem.push(
        <li key={`basketList_${index}`}>
          {!Utils.isTogetherProduct(item.product_id) ? (
            <div className="item-name">
              {item.product_name}
              {!Utils.isTogetherProduct(item.product_id) && (item.is_service === 'N' || isVooroomHoliday) && <span>주문불가</span>}
            </div>
          ) : (
            <div className="item-name item-together">
              {item.product_name}
              <span>공동구매</span>
            </div>
          )}
          <div className="item-store">{item.store_name}</div>
          <div className="item-price">{Utils.wonPriceStr(finalPrice)}원</div>
          <div className="item-count">
            <button type="button" className="btn-count btn-count-minus" onClick={() => this.handleItemMinus(item.count, item.product_id)} />
            <span className="num">{item.count}</span>
            <button type="button" className="btn-count btn-count-plus" onClick={() => this.handleItemPlus(item, item.count, item.product_id)} />
          </div>
          <button
            type="button"
            className="btn-delete js-delete"
            onClick={() => {
              this.handleDelItem(item.boid);
            }}
          />
        </li>,
      );
    });

    chkMenuPriceChange && this.setState({isMenuPriceChange: chkMenuPriceChange});
    return <ol className="list-item">{renderItem}</ol>;
  };

  render() {
    const {isLoading, basketList, totalPrice, aptName, aptDong, aptHo, isBell, roomServiceCharge} = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">룸서비스 주문</div>
          </div>
        </header>

        {isLoading && <Spinner loading={isLoading} />}

        <section className="order">
          {basketList.length > 0 ? (
            <div className="order-group">
              <div className="box-order-menu">
                <button
                  type="button"
                  className="btn-delete-all js-delete-all"
                  onClick={() => {
                    this.handleDelAllItem();
                  }}>
                  전체삭제
                </button>
                {basketList && this.renderBasketList()}
              </div>
              <div className="box-order-info">
                <div className="list-order-info">
                  <li>
                    <div className="tit">총 수량</div>
                    <div className="txt">
                      <strong>{basketList && this.calcTotalCount()}</strong>개
                    </div>
                  </li>

                  <li>
                    <div className="tit">상품금액</div>
                    <div className="txt">
                      <strong>{basketList && Utils.wonPriceStr(this.calcTotalPrice())}</strong>원
                    </div>
                  </li>

                  <li>
                    <div className="tit">룸서비스 요금</div>
                    <div className="txt">
                      <strong>{Utils.wonPriceStr(roomServiceCharge)}</strong>원
                    </div>
                  </li>

                  <li className="v1">
                    <div className="tit">룸정보</div>
                    <div className="txt2">
                      <p>{aptName}</p>
                      <p>
                        <span>{aptDong} 동</span>
                        <span>{aptHo} 호</span>
                      </p>
                    </div>
                  </li>

                  {!isBell && (
                    <li className="v1">
                      <div className="tit">요청사항</div>
                      <div className="txt2">
                        <p style={{marginTop: '-8px'}}>세대 현관문 벨 호출 X</p>
                      </div>
                    </li>
                  )}
                </div>
              </div>
              <div className="box-order-total">
                <div className="order-info">
                  <ol className="list-total-price">
                    <li>
                      <div className="tit">총 주문금액</div>
                      <div className="txt">
                        <strong>{basketList && Utils.wonPriceStr(this.calcTotalPrice() + roomServiceCharge)}</strong>원
                      </div>
                    </li>
                  </ol>

                  <div className="order-info-etc">
                    * 메뉴는 5개, 총 주문수량은 8개 이하 주문 가능.
                    <br />
                    * 엘리베이터 운영 불가시, 6층 이상 배달 불가. (5층 이하 가능)
                    <br />
                    * 여러 스토어의 음식을 묶어 주문한 경우,
                    <br />
                    &nbsp;&nbsp;&nbsp;일부 메뉴 접수 불가시 해당 음식만 부분 환불 처리됨.
                    <br />
                    &nbsp;&nbsp;&nbsp;(나머지 정상 접수 음식은 배달 진행됨)
                  </div>
                </div>
              </div>
              <div className="btn-floating-bottom">
                <button
                  type="button"
                  className="btn-cart"
                  onClick={() => {
                    //this.handlePayment();
                    Utils.showAlertConfirm('룸서비스 정규시즌 종료');
                  }}>
                  <span className="total-count">{basketList && this.calcTotalCount()}개 주문하기</span>
                  <span className="total-price">{basketList && Utils.wonPriceStr(this.calcTotalPrice() + roomServiceCharge)}원</span>
                </button>
              </div>
            </div>
          ) : (
            !isLoading && (
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">목록이 비어있습니다.</div>
                  <div className="txt2">주문할 음식을 담아주세요.</div>
                </div>
              </div>
            )
          )}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Order));
