import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {api} from '../../api';
import {APP_KEY_TOID, APP_KEY_TOID_CONCIERGE, APP_KEY_USER_ROOM, APP_KEY_USER_SETTING_BELL} from '../../shared/Constants';
import {isVooroomCheckOnTime, showAlertConfirmType3} from '../../shared/util';
import Spinner from '../../components/Spinner';
import {getConciergeMyBasket, updateConciergeBOID} from '../../shared/apiService';

class ConciergeOrder extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      basketList: [],
      noServiceList: [],
      myToid: null,
      aptName: '',
      aptDong: '',
      aptHo: '',
      isStoreService: true,
      isVooroomService: true,
      isLoading: true,
      isBell: true,
      isMultiStore: false, // 컨시어지는 특성상 2개 이상 스토어 묶음 주문불가
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({myToid: Utils.getLocalStorage(APP_KEY_TOID_CONCIERGE)});
    this.getMyBasket();

    //  - 룸정보
    Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
      if (rInfo) {
        const parseInfo = rInfo.split('/');
        if (parseInfo.length > 2) {
          this.setState({aptName: parseInfo[0]});
          this.setState({aptDong: parseInfo[1]});
          this.setState({aptHo: parseInfo[2]});
        }
      }
    });

    // - 호출 밸정보
    Utils.getLocalStorageSync(APP_KEY_USER_SETTING_BELL).then(result => {
      if (result && result === 'N') {
        this.setState({isBell: false});
        console.log('isBell false');
      } else {
        console.log('isBell true');
      }
    });
  }

  // -- feature -------------------------------------------------------------------------------
  getMyBasket = () => {
    Service.getConciergeMyBasket().then(async result => {
      console.log(result);

      // - 서비스 상태 업데이트
      let sOutCount = 0;
      let prevStoreId = null;
      let storeCount = 0;
      for await (const item of result) {
        item.is_service = 'Y';
        if (prevStoreId === null || prevStoreId !== item.store_id) {
          prevStoreId = item.store_id;
          storeCount++;
        }
      }
      console.log('[총 스토어 갯수] ' + storeCount);
      storeCount > 1 ? this.setState({isMultiStore: true}) : this.setState({isMultiStore: false});

      sOutCount > 0 ? this.setState({isStoreService: false}) : this.setState({isStoreService: true});
      this.setState({basketList: result}, () => {
        this.setState({isLoading: false});
      });
    });
  };

  calcTotalPrice = () => {
    const {basketList} = this.state;
    let mTotalPrice = 0;
    basketList.forEach((item, index) => {
      mTotalPrice += item.price * item.count;
    });
    console.log('[calcTotalPrice] ' + mTotalPrice);
    return mTotalPrice;
  };

  calcTotalCount = () => {
    const {basketList} = this.state;
    let mTotalCount = 0;
    basketList.forEach((item, index) => {
      mTotalCount += item.count;
    });
    console.log('[calcTotalCount] ' + mTotalCount);

    return mTotalCount;
  };

  showMaxLimitAlert = () => {
    Utils.showAlertConfirm('메뉴는 5개\n총 수량은 8개\n이하 주문 가능합니다.');
  };

  refreshBasket = async () => {
    const {basketList, isStoreService} = this.state;
    let sOutList = [];
    let sOutCount = 0;
    let prevStoreId = null;
    let storeCount = 0;
    for await (const item of basketList) {
      item.is_service = 'Y';
      sOutList.push(item);

      if (prevStoreId === null || prevStoreId !== item.store_id) {
        prevStoreId = item.store_id;
        storeCount++;
      }
    }
    console.log('[총 스토어 갯수] ' + storeCount);
    storeCount > 1 ? this.setState({isMultiStore: true}) : this.setState({isMultiStore: false});

    console.log(sOutList);
    sOutCount > 0 ? this.setState({isStoreService: false}) : this.setState({isStoreService: true});
    this.setState({basketList: sOutList});
  };

  // -- handler -------------------------------------------------------------------------------
  /**
   * 주문하기 버튼
   */
  handlePayment = async () => {
    const {basketList, isStoreService, isMultiStore} = this.state;

    // 상태 업데이트
    await this.refreshBasket();

    if (isMultiStore === true) {
      Utils.showAlertConfirm('컨시어지 서비스는\n예약의 혼선을 막기 위해\n여러 스토어 묶음 주문이 불가합니다.\n\n상품 확인 후\n주문을 진행해 주시기 바랍니다.');
    } else {
      // 모든 메뉴 주문 가능
      const totalItemCount = this.calcTotalCount();
      const totalMenuCount = basketList.length;

      this.props.history.push('/payment/concierge');

      /*if (totalItemCount <= 8 && totalMenuCount <= 5) {
        this.props.history.push('/payment/concierge');
      } else {
        this.showMaxLimitAlert();
      }*/
    }
  };

  /**
   * 상품 삭제
   */
  handleDelItem = mBoid => {
    if (mBoid !== null) {
      api
        .get(`/v1/concierge/boid/delete?boid=${mBoid}`)
        .then(res => {
          const {data} = res;
          console.log(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.getMyBasket();
          console.log('[DONE] handleDelItem : ' + mBoid);
        });
    } else {
      console.log('[handleDelItem] BOID NULL.');
    }
  };

  /**
   * 전체 상품 삭제
   */
  handleDelAllItem = () => {
    const {myToid} = this.state;
    if (myToid !== null) {
      api
        .get(`/v1/concierge/boid/deleteAll?toid=${myToid}`)
        .then(res => {
          const {data} = res;
          console.log(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.getMyBasket();
          console.log('[DONE] handleDelAllItem : ' + myToid);
        });
    } else {
      console.log('[handleDelAllItem] TOID NULL.');
    }
  };

  /**
   * 상품 갯수 추가
   */
  handleItemPlus = (item, mCnt, pID) => {
    const {myToid} = this.state;
    Service.updateConciergeBOID(myToid, pID, mCnt + 1).then(() => {
      this.getMyBasket();
    });
  };

  /**
   * 상품 갯수 제거
   */
  handleItemMinus = (mCnt, pID) => {
    const {myToid} = this.state;
    if (mCnt - 1 > 0) {
      Service.updateConciergeBOID(myToid, pID, mCnt - 1).then(() => {
        this.getMyBasket();
      });
    }
  };

  // -- render --------------------------------------------------------------------------------
  renderBasketList = () => {
    const {basketList, loading} = this.state;
    const renderItem = [];
    basketList.forEach((item, index) => {
      renderItem.push(
        <li key={`basketList_${index}`}>
          <div className="item-name">
            {item.product_name}
            {item.is_service === 'N' && <span>주문불가</span>}
          </div>
          <div className="item-store">{item.store_name}</div>
          <div className="item-price">{Utils.wonPriceStr(item.price)}원</div>

          <div className="item-count">
            <button type="button" className="btn-count btn-count-minus" onClick={() => this.handleItemMinus(item.count, item.product_id)} />
            <span className="num">{item.count}</span>
            <button type="button" className="btn-count btn-count-plus" onClick={() => this.handleItemPlus(item, item.count, item.product_id)} />
          </div>

          <button
            type="button"
            className="btn-delete js-delete"
            onClick={() => {
              this.handleDelItem(item.boid);
            }}
          />
        </li>,
      );
    });

    return <ol className="list-item">{renderItem}</ol>;
  };

  render() {
    const {isLoading, basketList, totalPrice, aptName, aptDong, aptHo, isBell} = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">컨시어지 주문</div>
          </div>
        </header>

        {isLoading && <Spinner loading={isLoading} />}

        <section className="order">
          {basketList.length > 0 ? (
            <div className="order-group">
              <div className="box-order-menu">
                <button
                  type="button"
                  className="btn-delete-all js-delete-all"
                  onClick={() => {
                    this.handleDelAllItem();
                  }}>
                  전체삭제
                </button>
                {basketList && this.renderBasketList()}
              </div>
              <div className="box-order-info">
                <div className="list-order-info">
                  <li>
                    <div className="tit">총 수량</div>
                    <div className="txt">
                      <strong>{basketList && this.calcTotalCount()}</strong>개
                    </div>
                  </li>

                  <li>
                    <div className="tit">상품금액</div>
                    <div className="txt">
                      <strong>{basketList && Utils.wonPriceStr(this.calcTotalPrice())}</strong>원
                    </div>
                  </li>

                  <li>
                    <div className="tit">컨시어지 요금</div>
                    <div className="txt">
                      <strong>0</strong>원
                    </div>
                  </li>

                  <li className="v1">
                    <div className="tit">룸정보</div>
                    <div className="txt2">
                      <p>{aptName}</p>
                      <p>
                        <span>{aptDong} 동</span>
                        <span>{aptHo} 호</span>
                      </p>
                    </div>
                  </li>
                </div>
              </div>
              <div className="box-order-total">
                <div className="order-info">
                  <ol className="list-total-price">
                    <li>
                      <div className="tit">총 주문금액</div>
                      <div className="txt">
                        <strong>{basketList && Utils.wonPriceStr(this.calcTotalPrice() + 0)}</strong>원
                      </div>
                    </li>
                  </ol>

                  {/*<div className="order-info-etc">
                    *메뉴는 5개, 총 주문수량은 8개 이하 주문 가능
                    <br />
                    *엘리베이터 운영 불가시, 6층 이상 배달 불가 (5층 이하 가능)
                  </div>*/}
                </div>
              </div>

              <div className="btn-floating-bottom">
                <button
                  type="button"
                  className="btn-cart"
                  onClick={() => {
                    this.handlePayment();
                  }}>
                  <span className="total-count">{basketList && this.calcTotalCount()}개 주문하기</span>
                  <span className="total-price">{basketList && Utils.wonPriceStr(this.calcTotalPrice() + 0)}원</span>
                </button>
              </div>
            </div>
          ) : (
            !isLoading && (
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">목록이 비어있습니다.</div>
                  <div className="txt2">주문할 상품을 담아주세요.</div>
                </div>
              </div>
            )
          )}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(ConciergeOrder));
