import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {ReactComponent as Check} from '../../assets/img/ico_ck1.svg';
import {
  APP_KEY_FREE_COUPON,
  APP_KEY_POID,
  APP_KEY_POID_CONCIERGE,
  APP_KEY_SNS_TYPE,
  APP_KEY_TOID,
  APP_KEY_TOID_CONCIERGE,
  APP_KEY_USER_EMAIL,
  APP_KEY_USER_UUID,
  CONCIERGE_STATE_10_NORMAL_RECEIPT,
} from '../../shared/Constants';
import {setMenuScrollTop} from '../../redux/app/appActions';
import {getLocalStorage} from '../../shared/util';

class PaymentComplete extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      payMode: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const {dispatch} = this.props;

    // - 웹뷰 히스토리 삭제
    Utils.sendToRnMessage('RN_WEBVIEW_HISTORY_CLEAR');

    // - 결제모드
    let payMode = this.props.match.params.mode;
    this.setState({payMode: payMode});

    // - 네이티브 헤더 숨기기
    Utils.sendToRnMessage('RN_PAY_HEADER_CLOSE');

    // - 사용자UUID
    const userId = getLocalStorage(APP_KEY_USER_UUID);
    const isUseCoupon = getLocalStorage(APP_KEY_FREE_COUPON);
    const email = getLocalStorage(APP_KEY_USER_EMAIL);
    const snsType = getLocalStorage(APP_KEY_SNS_TYPE);

    // - 결제 성공시 TOID (장바구니) 삭제함
    if (payMode === 'concierge') {
      // - 컨시어지
      Utils.getLocalStorageSync(APP_KEY_TOID_CONCIERGE).then(toid => {
        if (toid !== undefined) {
          Utils.setLocalStorage(APP_KEY_POID_CONCIERGE, toid);
          localStorage.removeItem(APP_KEY_TOID_CONCIERGE);

          // 컨시어지 결제 성공시 자동응답 카톡 발송 -> POID, BOID 상태값 업데이트
          Service.getUserInfo(email, snsType).then(result => {
            console.log(result);
            if (result) {
              const myPOID = toid;
              const templateCode = 'Con-Oper_order';
              const recipientNo = result.phone ? Utils.getDecryptPhoneNumber(result.phone) : '';
              Service.getConciergePoidInfo(myPOID).then(result => {
                console.log(result);

                // - 01 카톡 발송
                let productName = '';
                let dateTimeStr = '';
                let menuCount = 0;
                let storeName = '';
                result.forEach((item, idx) => {
                  if (dateTimeStr === '') dateTimeStr = Utils.getOrderDateTimeString(item.order_date);
                  if (productName === '') productName = item.product_name;
                  if (item.count) menuCount += item.count;
                  if (item.store_name) storeName = item.store_name;
                });
                if (result.length > 1) {
                  productName += ' 외 ' + (result.length - 1) + '개';
                }
                const mTemplateParameter = {
                  dateTime: dateTimeStr,
                  poid: myPOID,
                  storeName: storeName,
                  menuName: productName,
                  menuCount: menuCount,
                };
                Service.sendKakaoAlimTalk(templateCode, recipientNo, mTemplateParameter).finally(() => {
                  console.log('[컨시어지] 결제 성공 & 알림톡 전송 완료.');
                  Service.updateConciergePoidState(CONCIERGE_STATE_10_NORMAL_RECEIPT, myPOID).then();
                  Service.updateConciergeBoidState(CONCIERGE_STATE_10_NORMAL_RECEIPT, myPOID).then();
                });
              });
            }
          });
        } else {
          console.log('CONCIERGE TOID NULL.');
        }
      });
    } else {
      // - 룸서비스
      Utils.getLocalStorageSync(APP_KEY_TOID).then(toid => {
        if (toid !== undefined) {
          Utils.setLocalStorage(APP_KEY_POID, toid);
          localStorage.removeItem(APP_KEY_TOID);

          // - 공동구매 결제시 상태값 업데이트 및 알림톡 발송 (백오피스 API 사용)
          // - 100 : 공구 접수대기 (카톡 발송 완료)
          // - 110 : 공구 배송시작
          // - 120 : 공구 배송종료
          // - -2 : 공구 환불
          Service.getUserInfo(email, snsType).then(result => {
            console.log(result);
            if (result) {
              const myPOID = toid;
              const templateCode = 'c-p-order_done';
              const recipientNo = result.phone ? Utils.getDecryptPhoneNumber(result.phone) : '';
              Service.getPoidInfo(myPOID).then(result => {
                console.log(result);
                let isTogetherProduct = false;
                let productName = '';
                let dateTimeStr = '';
                result.forEach((item, idx) => {
                  isTogetherProduct = Utils.isTogetherProduct(item.product_id);
                  if (dateTimeStr === '') dateTimeStr = Utils.getOrderDateTimeString(item.create_date);
                  if (productName === '') productName = item.product_name;
                });
                if (result.length > 1) {
                  productName += ' 외 ' + (result.length - 1) + '개';
                }

                if (isTogetherProduct) {
                  console.log('공동구매 상품');
                  // - 01 카톡 발송
                  const mTemplateParameter = {
                    dateTime: dateTimeStr,
                    poid: myPOID,
                    menuName: productName,
                  };
                  Service.sendKakaoAlimTalk(templateCode, recipientNo, mTemplateParameter).finally(() => {
                    console.log('[공동구매] 결제 성공 & 알림톡 전송 완료.');

                    // - 02 POID 상태 업데이트
                    Service.updatePoidState(100, myPOID).then();

                    // - 03 SOID 상태 업데이트
                    Service.updateSoidState(100, myPOID).then();

                    // - 04 BOID 상태 업데이트룸
                    Service.updateBoidState(100, myPOID).then();
                  });
                } else {
                  console.log('룸서비스 상품');
                }
              });
            } else {
              console.log(`>> email : ${email} / snsType : ${snsType}`);
              console.log('>> UserInfo Data is Null.');
            }
          });
        } else {
          console.log('TOID NULL.');
        }
      });

      // - 고객감사 쿠폰 사용시 제거 /////////////
      if (userId && isUseCoupon === 'true') {
        Service.updateFreeCouponMinus(userId).then(result => {
          console.log('[updateFreeCouponMinus] 고객 감사 쿠폰 차감.');
          console.log(result);
          localStorage.removeItem(APP_KEY_FREE_COUPON);
        });
      }
    }

    // 스크롤값 리셋.
    dispatch(setMenuScrollTop(0));
  }

  // -- feature -------------------------------------------------------------------------------
  isConciergePay = () => {
    const {payMode} = this.state;
    return payMode === 'concierge';
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <div className="hd-tit">결제완료</div>
          </div>
        </header>

        <section className="payment-complete">
          <div className="complete-message">
            <Check />
            <div className="txt">
              결제가 정상적으로 완료되었습니다. <br />
              주문해 주셔서 감사합니다.
            </div>
          </div>

          <div className="btn-floating-bottom">
            {this.isConciergePay() ? (
              <div className="btn-group">
                <Link to="/concierge/menu" replace={true} className="btn btn-custom btn-custom-v1">
                  메뉴 보기
                </Link>
                <Link to="/concierge/order/view" replace={true} className="btn btn-custom">
                  주문 내역 보기
                </Link>
              </div>
            ) : (
              <div className="btn-group">
                <Link to="/menu" replace={true} className="btn btn-custom btn-custom-v1">
                  메뉴 보기
                </Link>
                <Link to="/order/view" replace={true} className="btn btn-custom">
                  주문 내역 보기
                </Link>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(PaymentComplete));
