import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';
import {ReactComponent as ArrowRight} from '../../assets/img/ico_arrow_right1.svg';
import {api} from '../../api';
import {nl2brHtml} from '../../shared/util';

class NoticeView extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      noticeItem: {},
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    let uuid = this.props.match.params.uuid;
    console.log('uuid : ' + uuid);
    if (uuid > 0) {
      this.updateNoticeCount(uuid);
      this.getNoticeById(uuid);
    }
  }

  // -- feature -------------------------------------------------------------------------------
  updateNoticeCount = uuid => {
    api
      .get(`/v1/notice/count/plus/${uuid}`)
      .then(res => {})
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] updateNoticeCount ' + uuid);
      });
  };

  getNoticeById = uuid => {
    api
      .get(`/v1/notice/${uuid}`)
      .then(res => {
        const {data} = res;
        console.log(data[0]);
        this.setState({noticeItem: data[0]});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getNoticeById');
      });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------

  render() {
    const {noticeItem} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">공지사항</div>
          </div>
        </header>

        <section className="mycoupon">
          {noticeItem && (
            <div className="wrap">
              <ol className="list-coupon-history">
                <li>
                  <div className="tit notice-title">{noticeItem.title}</div>
                  <div className="notice-date">{noticeItem.date}</div>
                </li>
              </ol>
              <div className="notice-view">{Utils.nl2brHtml(noticeItem.content)}</div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(NoticeView));
