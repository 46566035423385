// EXAMPLE ACTION
export const SET_PROJECT_VERSION = 'SET_PROJECT_VERSION';
export const CHANGE_STORE_VALUE = 'CHANGE_STORE_VALUE';
export const COMMON_DIALOG_STATE_CHANGED = 'COMMON_DIALOG_STATE_CHANGED';
export const SET_MEMBER_LOGIN = 'SET_MEMBER_LOGIN';
export const SET_MENU_SCROLL_TOP = 'SET_MENU_SCROLL_TOP';
export const SET_MENU_POPUP_SHOW_1 = 'SET_MENU_POPUP_SHOW_1';
export const SET_MENU_POPUP_SHOW_2 = 'SET_MENU_POPUP_SHOW_2';
export const SET_MENU_POPUP_SHOW_3 = 'SET_MENU_POPUP_SHOW_3';
export const SET_MENU_POPUP_SHOW_EMERGENCY = 'SET_MENU_POPUP_SHOW_EMERGENCY';
export const SET_MENU_POPUP_SHOW_HOLIDAY = 'SET_MENU_POPUP_SHOW_HOLIDAY';
