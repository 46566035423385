import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {api} from '../../api';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {APP_KEY_USER_UUID} from '../../shared/Constants';

class MyInfo extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      inputName: '',
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    this.setState({userId: uuid});

    // - 사용자 정보 가져오기
    if (uuid) {
      Service.getUserInfoByUuid(uuid).then(result => {
        if (result.real_name) {
          this.setState({inputName: result.real_name});
        }
      });
    } else {
      console.log('uuid is NULL');
    }
  }

  // -- feature -------------------------------------------------------------------------------
  getTotalAptList = () => {
    api
      .get(`/v1/zone/apt/list`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({aptList: data});
        this.setState({showAptList: data});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getTotalAptList');
      });
  };

  // -- handler -------------------------------------------------------------------------------
  handleName = e => {
    this.setState({inputName: e.target.value});
  };

  handleRegName = () => {
    const {inputName, userId} = this.state;

    if (inputName.length > 1 && userId) {
      Service.updateUserRealName(userId, inputName).then(() => {
        Utils.showAlertConfirm('수정 완료하였습니다.').then(() => {
          this.props.history.goBack();
        });
      });
    } else {
      Utils.showAlertConfirm('올바른 정보를 입력해 주세요.');
    }
  };
  // -- render --------------------------------------------------------------------------------

  render() {
    const {inputName} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">내 정보 설정</div>
          </div>
        </header>

        <section className="address">
          <div className="address-fixed">
            <div className="box-search">
              <div className="order-info">
                <ol className="list-total-price">
                  <li>
                    <div className="tit" style={{fontWeight: '600'}}>
                      수령인 정보
                    </div>
                  </li>
                </ol>

                <div className="order-info-etc" style={{lineHeight: '150%', marginBottom: '15px'}}>
                  상품을 수령하시는 분의 성함을 입력해 주세요.
                </div>
              </div>

              <div className="inp-group-search">
                <input type="text" className="inp-search" defaultValue={inputName} onChange={this.handleName} />
              </div>
            </div>

            <div className="btn-floating-bottom">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-custom btn-custom-v1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  취소
                </button>
                <button type="button" className="btn btn-custom" onClick={this.handleRegName}>
                  등록
                </button>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(MyInfo));
