import {CHANGE_STORE_VALUE} from "../actionTypes";
import produce from "immer";

const defaultState = {};
const initialState = JSON.parse(JSON.stringify(defaultState)); // deep copy

export const rootReducer = (state = initialState, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    if (action.type === CHANGE_STORE_VALUE) {
        return produce(state, draft => {
            const container = draft[action.containerName];
            const paths = action.targetPath.split('/');

            let objLastContainer = container;
            let lastPathName = paths[paths.length - 1];
            if (paths.length > 1) {
                for (let i = 0; i < paths.length - 1; i++) {
                    const containerName = paths[i];
                    objLastContainer = objLastContainer[containerName];
                }
            }
            objLastContainer[lastPathName] = action.value;
        });
    }

    return state;
};
