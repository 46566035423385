import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import {APP_KEY_USER_UUID} from '../../shared/Constants';
import {getLocalStorage, getLocalStorageSync} from '../../shared/util';
import {api} from '../../api';

class Withdraw extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      mUuid: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const userId = getLocalStorage(APP_KEY_USER_UUID);

    this.setState({mUuid: userId}, () => {
      console.log(userId);
    });
  }

  // -- feature -------------------------------------------------------------------------------
  delCustomerWithdraw = uuid => {
    api
      .get(`/v1/oauth/withdraw?uuid=${uuid}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        Utils.processLogOut();
        Utils.showAlertConfirm('회원탈퇴 처리 되었습니다.').then(result => {
          this.props.history.replace('/');
        });
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] delCustomerWithdraw');
      });
  };
  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {mUuid} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <div className="hd-tit">회원탈퇴</div>
          </div>
        </header>

        <section className="payment-complete">
          <div className="complete-message">
            <div className="txt2">
              회원 탈퇴를 진행하시겠습니까?
              <div className="txt3">
                모든 데이터는 삭제 처리되며
                <br />
                복원할 수 없습니다.
              </div>
            </div>
          </div>

          <div className="btn-floating-bottom">
            <div className="btn-group">
              <Link to="/setting" replace={true} className="btn btn-custom btn-custom-v1">
                취소
              </Link>
              <a
                className="btn btn-custom"
                onClick={() => {
                  this.delCustomerWithdraw(mUuid);
                }}>
                확인
              </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(Withdraw));
