import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import {ReactComponent as Fail} from '../../assets/img/ico_fail_1.svg';
import {setMenuScrollTop} from '../../redux/app/appActions';

class PaymentFail extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      payMode: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const {dispatch} = this.props;

    // - 웹뷰 히스토리 삭제
    Utils.sendToRnMessage('RN_WEBVIEW_HISTORY_CLEAR');

    // - 결제모드
    let payMode = this.props.match.params.mode;
    this.setState({payMode: payMode});

    // - 네이티브 헤더 숨기기
    Utils.sendToRnMessage('RN_PAY_HEADER_CLOSE');

    // 스크롤값 리셋.
    dispatch(setMenuScrollTop(0));
  }

  // -- feature -------------------------------------------------------------------------------
  isConciergePay = () => {
    const {payMode} = this.state;
    return payMode === 'concierge';
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <div className="hd-tit">결제실패</div>
          </div>
        </header>

        <section className="payment-complete">
          <div className="complete-message">
            <Fail />
            <div className="txt">
              결제를 실패했습니다.
              <br />
              장바구니에서 다시 주문해주세요.
            </div>
          </div>

          <div className="btn-floating-bottom">
            {this.isConciergePay() ? (
              <div className="btn-group">
                <Link to="/concierge/menu" replace={true} className="btn btn-custom btn-custom-v1">
                  메뉴 보기
                </Link>
                <Link to="/concierge/order/view" replace={true} className="btn btn-custom">
                  주문 내역 보기
                </Link>
              </div>
            ) : (
              <div className="btn-group">
                <Link to="/menu" replace={true} className="btn btn-custom btn-custom-v1">
                  메뉴 보기
                </Link>
                <Link to="/order/view" replace={true} className="btn btn-custom">
                  주문 내역 보기
                </Link>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(PaymentFail));
