import reduceReducers from 'reduce-reducers';
import {combineReducers} from 'redux';
import {rootReducer} from './root/rootReducer';
import {appReducer} from './app/appReducer';

const combinedReducers = combineReducers({
  app: appReducer,
});

export const reducers = reduceReducers(combinedReducers, rootReducer);
