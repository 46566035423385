import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import {api} from '../../api';
import * as Utils from '../../shared/util';

class CommunityListView extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      menuId: '',
      communityItemList: [],
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const mID = this.props.location.state.menuId;
    console.log(mID);
    this.getCommunityListDataByMenuId(mID);
    this.setState({menuId: mID});
  }

  // -- feature -------------------------------------------------------------------------------
  getCommunityListDataByMenuId = menuId => {
    api
      .get(`/v1/community/${menuId}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({communityItemList: data});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getCommunityListDataByMenuId');
      });
  };

  plusCommunityDataPageView = uuid => {
    api
      .get(`/v1/community/count/plus/${uuid}`)
      .then(res => {
        const {data} = res;
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] plusCommunityDataPageView');
      });
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  renderItemList = () => {
    const {communityItemList, menuId} = this.state;
    const renderItem = [];

    for (let i = 0; i < communityItemList.length; i += 2) {
      {
        menuId === communityItemList[i].menu_id &&
          renderItem.push(
            <div key={communityItemList[i].uuid} className="community-list-row">
              <div
                className="community-list-row-item"
                onClick={() => {
                  this.plusCommunityDataPageView(communityItemList[i].uuid);
                  Utils.sendToRnMessage('RN_STORE_INFO_LINK', communityItemList[i].hyper_link);
                }}>
                <img src={communityItemList[i].thumbnail} loading="lazy" className="community-list-row-img" />
                <div className="community-list-row-item-text1">{communityItemList[i].title}</div>
                <div className="community-list-row-item-text1-1">{communityItemList[i].sub_title}</div>
                <div className="community-list-row-item-text2">{communityItemList[i].body}</div>
              </div>
              {communityItemList.length > i + 1 ? (
                <div
                  className="community-list-row-item"
                  onClick={() => {
                    this.plusCommunityDataPageView(communityItemList[i + 1].uuid);
                    Utils.sendToRnMessage('RN_STORE_INFO_LINK', communityItemList[i + 1].hyper_link);
                  }}>
                  <img src={communityItemList[i + 1].thumbnail} loading="lazy" className="community-list-row-img" />
                  <div className="community-list-row-item-text1">{communityItemList[i + 1].title}</div>
                  <div className="community-list-row-item-text1-1">{communityItemList[i + 1].sub_title}</div>
                  <div className="community-list-row-item-text2">{communityItemList[i + 1].body}</div>
                </div>
              ) : (
                <div className="community-list-row-item">
                  <div className="community-list-row-item-text1"></div>
                  <div className="community-list-row-item-text1-1"></div>
                  <div className="community-list-row-item-text2"></div>
                </div>
              )}
            </div>,
          );
      }
    }

    return <div className="community-list-view">{renderItem}</div>;
  };

  render() {
    const {communityItemList} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">{this.props.location.state.title}</div>
          </div>
        </header>

        <section className="community-list">
          {communityItemList.length > 0 ? (
            this.renderItemList()
          ) : (
            <>
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">아직 등록된 글이 없습니다</div>
                  <div className="txt2">등록 요청 바랍니다 :)</div>
                </div>
              </div>
            </>
          )}
          <div className="btn-floating-bottom">
            <button
              type="button"
              className="btn-cart"
              onClick={() => {
                Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://forms.gle/efxCaRHGNb7N4FzRA');
              }}>
              <span className="community-bottom-text">게시글 등록 요청</span>
            </button>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(CommunityListView));
