import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ReactComponent as Logo} from '../../assets/img/img_logo2.svg';
import {ReactComponent as IcoKakao} from '../../assets/img/ico_kakao1.svg';
import {ReactComponent as IcoNaver} from '../../assets/img/ico_naver1.svg';
import {ReactComponent as IcoTest} from '../../assets/img/ico_test1.svg';
import {ReactComponent as IcoClose} from '../../assets/img/ico_close.svg';
import {API_SERVER, APP_KEY_SNS_TYPE, APP_KEY_USER_STATUS, APP_KEY_TOID, APP_KEY_POID, APP_KEY_USER_ROOM} from '../../shared/Constants';
import * as Utils from '../../shared/util';
import {api} from '../../api';

class OnBoardingLogin extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      currentOS: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // OS정보
    this.setState({currentOS: Utils.getOS()});

    // Apple 로그인 초기화
    window.AppleID.auth.init({
      clientId: 'kr.vooroom.user.app',
      scope: 'email',
      redirectURI: 'https://api.vooroom.kr/v1/oauth/apple',
      state: 'vooroom_apple',
      usePopup: false,
    });
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleAppleLogin = () => {
    if (window.AppleID !== null) {
      Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'apple');
      window.AppleID.auth.signIn();
    } else {
      console.log('[handleAppleLogin] AppleID is Null');
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {currentOS} = this.state;

    return (
      <React.Fragment>
        <div
          className="login-onboarding-close"
          onClick={() => {
            this.props.history.replace('/');
          }}>
          <IcoClose />
        </div>
        <section className="login">
          <div className="login-onboarding-text">
            간편한 부룸서비스
            <br />
            지금 바로 시작해볼까요?
          </div>
          <div className="wrap" style={{paddingBottom: '0px'}}>
            <Logo className="img-logo" />

            <ol className="list-sns-login">
              <li>
                <a
                  href={API_SERVER + '/v1/oauth/login?type=kakao'}
                  className="btn-sns-login btn-sns-login-kakao"
                  onClick={() => {
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'kakao');
                  }}>
                  <IcoKakao style={{marginRight: '6px'}} />
                  카카오톡으로 계속하기
                </a>
              </li>
              <li>
                <a
                  href={API_SERVER + '/v1/oauth/login?type=naver'}
                  className="btn-sns-login btn-sns-login-naver"
                  onClick={() => {
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'naver');
                  }}>
                  <IcoNaver style={{marginRight: '0px', marginTop: '3px'}} />
                  네이버로 계속하기
                </a>
              </li>
              {currentOS === 'ios' && (
                <li className="btn-sns-login-apple">
                  <a
                    onClick={() => {
                      this.handleAppleLogin();
                    }}>
                    <img src={require('../../assets/img/ico_login_apple.png').default} className="btn-sns-login" />
                  </a>
                </li>
              )}
              {/*<li>
                <a
                  className="btn-sns-login btn-sns-login-test"
                  onClick={() => {
                    localStorage.removeItem(APP_KEY_TOID);
                    localStorage.removeItem(APP_KEY_POID);
                    Utils.setLocalStorage(APP_KEY_SNS_TYPE, 'kakao');
                    Utils.processLogIn('test@test.com', '테스트아이디', 1, 17);
                    this.props.history.replace('/');
                  }}>
                  <IcoTest style={{marginRight: '6px'}} />
                  테스트ID로 계속하기
                </a>
              </li>*/}
            </ol>
          </div>
          <div
            className="login-onboarding-bottom"
            onClick={() => {
              this.props.history.replace('/');
            }}>
            조금 더 둘러볼게요
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OnBoardingLogin));
