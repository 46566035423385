import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Utils from '../../shared/util';
import {api} from '../../api';
import * as Constants from '../../shared/Constants';
import Spinner from '../../components/Spinner';
import {ReactComponent as Info1} from '../../assets/img/order_info_1.svg';
import {ReactComponent as Info2} from '../../assets/img/order_info_2.svg';
import {delLocalStorage} from '../../shared/util';

class OrderView extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      productList: [],
      orderPoid: null,
      orderDate: null,
      orderPayPrice: 0,
      orderState: 1,
      isLoading: true,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    window.scrollTo(0, 0);
    const myPoid = Utils.getLocalStorage(Constants.APP_KEY_POID);
    if (myPoid !== null) {
      this.setState({orderPoid: myPoid});
      this.getMyPoidOrderInfo(myPoid).then();
    } else {
      console.log('POID Null.');
      this.setState({isLoading: false});
    }
  }

  // -- feature -------------------------------------------------------------------------------
  getMyPoidOrderInfo = async mPoid => {
    await api
      .get(`/v1/order/poid?poid=${mPoid}`)
      .then(res => {
        const {data} = res;
        console.log(data);
        this.setState({orderDate: data[0].create_date});
        this.setState({orderPayPrice: data[0].pay_price});
        this.setState({orderState: data[0].poid_state});
        this.setState({productList: data});
        this.setState({isLoading: false});
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] getMyPoidOrderInfo : ' + mPoid);
      });

    this.checkOrderView();
  };

  checkOrderView = () => {
    // 주문내역 날짜를 비교하여 오늘 주문건에 대해서만 나타냄.
    const {orderPoid, orderDate, orderState} = this.state;

    //console.log(orderPoid);
    //console.log(orderDate);

    // 주문취소건에 대해서 POID 삭제
    if (orderPoid !== null && orderState < 0) {
      Utils.delLocalStorage(Constants.APP_KEY_POID);
      console.log('취소건 POID 삭제 : ' + orderPoid);
      this.setState({orderPoid: null});
    }

    // 주문정보 반환
    if (orderPoid === null || orderDate === null || orderState < 0) {
      return false;
    } else {
      const rawDate = new Date(orderDate);
      const today = new Date();
      const tDate = new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate());
      const diffDate = today.getTime() - tDate.getTime();
      const btDay = Math.abs(diffDate / (1000 * 60 * 60 * 24));

      if (btDay >= 1) return false;
      else return true;
    }
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  renderProductList = () => {
    const {productList} = this.state;
    const renderItem = [];
    productList.forEach((item, index) => {
      renderItem.push(
        <li key={`productList_li_${index}`}>
          <div className="tit">{item.product_name}</div>
          <div className="count">
            <span>{item.count}</span>개
          </div>
          <div className="price">
            <span>{Utils.wonPriceStr(item.price * item.count)}</span>원
          </div>
        </li>,
      );
    });

    return <ol className="list-order-menu">{renderItem}</ol>;
  };

  render() {
    const {productList, orderPoid, orderDate, orderPayPrice, orderState, isLoading} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.replace('/main/order');
              }}></button>
            <div className="hd-tit">룸서비스 주문내역</div>
          </div>
        </header>

        {isLoading && <Spinner loading={isLoading} />}

        <section className="order-view">
          {this.checkOrderView() ? (
            <div className="wrap" style={{paddingBottom: '68px'}}>
              {orderState < Constants.ROOM_SERVICE_STATE_5_COMPLETE_PICKUP && (
                <div className="box">
                  <div className="state-message">
                    <div className="tit">‘음식 준비중’</div>
                    <div className="txt">조금만 기다려주세요.</div>
                  </div>

                  <div className="progress-state">
                    <div className="circle-group">
                      <div className="ico active"></div>
                      <div className="ico"></div>
                      <div className="ico inactive"></div>
                    </div>

                    <div className="txt-group">
                      <div className="txt">음식 준비중</div>
                      <div className="txt">픽업완료</div>
                      <div className="txt">배달완료</div>
                    </div>

                    <div className="info-group">
                      <Info1 />
                    </div>
                    <div className="info-group">
                      <Info2 />
                    </div>
                  </div>
                </div>
              )}

              {orderState === Constants.ROOM_SERVICE_STATE_5_COMPLETE_PICKUP && (
                <div className="box">
                  <div className="state-message">
                    <div className="tit">‘픽업완료’</div>
                    <div className="txt">10분 내외로 배달해드릴게요.</div>
                  </div>

                  <div className="progress-state">
                    <div className="circle-group">
                      <div className="ico active"></div>
                      <div className="ico active"></div>
                      <div className="ico"></div>
                    </div>

                    <div className="txt-group">
                      <div className="txt">음식 준비중</div>
                      <div className="txt">픽업완료</div>
                      <div className="txt">배달완료</div>
                    </div>
                  </div>
                </div>
              )}

              {orderState === Constants.ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY && (
                <div className="box">
                  <div className="state-message">
                    <div className="tit">‘배달완료’</div>
                    <div className="txt">맛있게 드세요 :)</div>
                  </div>

                  <div className="progress-state">
                    <div className="circle-group">
                      <div className="ico active"></div>
                      <div className="ico active"></div>
                      <div className="ico active"></div>
                    </div>

                    <div className="txt-group">
                      <div className="txt">음식 준비중</div>
                      <div className="txt">픽업완료</div>
                      <div className="txt">배달완료</div>
                    </div>
                  </div>
                </div>
              )}

              <div className="box">
                <ol className="list-order-data">
                  <li>
                    <div className="tit">주문일시</div>
                    <div className="txt">
                      <span>{Utils.getOrderDateTimeStr(orderDate)}</span>
                    </div>
                  </li>

                  <li>
                    <div className="tit">주문번호</div>
                    <div className="txt">
                      <strong>{orderPoid}</strong>
                    </div>
                  </li>
                </ol>
              </div>

              <div className="box">{productList && this.renderProductList()}</div>

              <div className="box">
                <ol className="list-total-price">
                  <li>
                    <div className="tit">총 결제금액</div>
                    <div className="txt">
                      <strong>{Utils.wonPriceStr(orderPayPrice)}</strong>원
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          ) : (
            !isLoading && (
              <div className="no-data">
                <div className="txt-group">
                  <div className="txt">목록이 비어있습니다.</div>
                  <div className="txt2">주문 내역이 없습니다.</div>
                </div>

                <div className="btn-floating-bottom">
                  <Link to="/" className="btn-custom">
                    메뉴 담으러 가기
                  </Link>
                </div>
              </div>
            )
          )}

          <div className="btn-floating-bottom">
            <Link to="/order/history" className="btn-history">
              이전 주문 내역 보기
            </Link>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(OrderView));
