import React from 'react';
import randomInteger from 'random-int';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  APP_KEY_IS_LOGIN,
  APP_KEY_POID,
  APP_KEY_POID_CONCIERGE,
  APP_KEY_SNS_TYPE,
  APP_KEY_TOID,
  APP_KEY_TOID_CONCIERGE,
  APP_KEY_USER_ALIAS,
  APP_KEY_USER_EMAIL,
  APP_KEY_USER_ROOM,
  APP_KEY_USER_STATUS,
  APP_KEY_USER_UUID,
  APP_KEY_USER_ZONE_CODE,
  CONCIERGE_STATE_10_NORMAL_RECEIPT,
  CONCIERGE_STATE_1_DONE_PAY,
  CONCIERGE_STATE_20_SCHEDULE_ING,
  CONCIERGE_STATE_30_SCHEDULE_DONE,
  CONCIERGE_STATE_40_SERVICE_DONE,
  ROOM_SERVICE_STATE_0_BASKET,
  ROOM_SERVICE_STATE_1_DONE_PAY,
  ROOM_SERVICE_STATE_2_WAIT_ORDER,
  ROOM_SERVICE_STATE_3_1_NORMAL_RECEIPT,
  ROOM_SERVICE_STATE_3_2_DELAY_RECEIPT,
  ROOM_SERVICE_STATE_4_DELAY_DELIVERY,
  ROOM_SERVICE_STATE_5_COMPLETE_PICKUP,
  ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY,
  ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND,
  VOOROOM_OPERATION_BREAK_TIME_END,
  VOOROOM_OPERATION_BREAK_TIME_START,
  VOOROOM_OPERATION_BREAK_TIME_WEEKEND_START,
  VOOROOM_OPERATION_TIME_CLOSE,
  VOOROOM_OPERATION_TIME_OPEN,
  VOOROOM_OPERATION_TIME_WEEKEND_OPEN,
} from './Constants';
import * as Service from './apiService';
import {ReactComponent as IcoEmergency} from '../assets/img/emergency_icon.svg';
import {ReactComponent as IcoEmergencyByWeather} from '../assets/img/emergency_icon_type_weather.svg';
import CryptoJS from 'crypto-js';

// - RN으로 메시지 전송
export const sendToRnMessage = (type, data) => {
  const sendData = {
    type,
    data,
  };
  if (window.ReactNativeWebView) {
    console.log(`(WEBVIEW to RN) sendToRnMessage : ${type} / ${data}`);
    window.ReactNativeWebView.postMessage(JSON.stringify(sendData));
  } else {
    console.log('sendToRnMessage', 'ReactNativeWebView is Null.');
  }
};

/**
 * 주어진 객체를 복사해서 새로운 객체를 만든다.
 * @param src
 */
export const deepCopy = src => {
  return JSON.parse(JSON.stringify(src));
};

/**
 * 현재 브라우저가 모바일 타입인지를 반환
 */
export const isMobile = () => {
  return /Mobi/i.test(window.navigator.userAgent);
};

/**
 * 주어진 문자열이 빈 값인지를 검사한다.
 * @param value
 * @returns {boolean}
 */
export const isBlank = value => {
  if (!value) {
    return true;
  }

  if (typeof value === 'string') {
    const trimmed = value.trim();
    if (trimmed.length === 0) {
      return true;
    }
  } else {
    throw Error(`value ${value}는 string 형식이어야 합니다.`);
  }

  return false;
};

/**
 * value가 빈 값인 경우, defaultValue를 반환한다.
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const defaultString = (value, defaultValue) => {
  if (isBlank(value)) {
    return defaultValue;
  }
  return value;
};

/**
 * 주어진 문자열에서 숫자만 남긴다.
 */
export const getNumbersOnly = src => {
  if (isBlank(src)) {
    return '';
  }
  const numbersOnly = src.replace(/[^\d]/g, '');
  return numbersOnly;
};

/**
 * 주어진 문자열이 빈 값이 아닌지를 검사한다. TODO: lodash 의 것을 사용한다.
 * @param value
 * @returns {boolean}
 */
export const isNotBlank = value => {
  return !isBlank(value);
};

/**
 * 주어진 객체(array, map, js object)가 빈 값인지를 검사한다.
 * @param obj
 */
export const isEmpty = obj => {
  if (!obj || obj === undefined) {
    return true;
  }

  if (Object.entries) {
    return Object.entries(obj).length === 0 && (obj.constructor === Object || obj.constructor === Array);
  } else if (Object.keys) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } else {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
};

export const isNotEmpty = obj => {
  return !isEmpty(obj);
};

/**
 * 개행문자를 <br/> 태그로 치환.
 */
export const nl2br = str => {
  if (isBlank(str)) {
    return <React.Fragment />;
  }
  const splitTags = str.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });
  return <React.Fragment>{splitTags}</React.Fragment>;
};

/**
 * 개행문자를 <br/> 태그로 치환.
 */
export const nl2brHtml = str => {
  if (isBlank(str)) {
    return <React.Fragment />;
  }
  const splitTags = str.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        <div dangerouslySetInnerHTML={{__html: item}} />
      </React.Fragment>
    );
  });
  return <React.Fragment>{splitTags}</React.Fragment>;
};

/**
 * 두 배열의 내용이 같은지를 체크
 * @param array1
 * @param array2
 * @returns {boolean|boolean|*}
 */
export const isSameArray = (array1, array2) => {
  if (!array1 && !array2) {
    return true;
  }
  if ((array1 && !array2) || (!array1 && array2)) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }

  // 정렬 상태까지 비교가 필요하면 sort를 하지 말고,
  // 그냥 처음부터 하나씩 비교하다가 다른 값이 있을 경우 return 하는게 가장 빠르다.
  const s1 = [...array1].sort();
  const s2 = [...array2].sort();
  return s1.toString() === s2.toString();

  // return s1.every(function (element, index) {
  //     return element === s2[index];
  // });
};

/**
 * boolean 형식 state 값을 toggle 한다.
 * @param that
 * @param name
 * @returns {(function(): void)|*}
 */
export const toggleState = (that, name) => () => {
  const prevValue = that.state[name];
  that.setState({
    [name]: !prevValue,
  });
};

/**
 * state 값을 변경한다.
 * @param that
 * @param name
 * @param value
 * @returns {(function(*))|*}
 */
export const updateState = (that, name, value) => e => {
  if (typeof value === 'undefined') {
    value = e.target.value;
  }

  that.setState({
    [name]: value,
  });
};

// 한국 전화번호 정규식
const phoneNumberRegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
/**
 * 전화번호 유효성을 검사한다.
 * @param phoneNumber
 * @returns {boolean}
 */
export const isValidPhoneNumber = phoneNumber => {
  return phoneNumberRegExp.test(phoneNumber);
};

/**
 * 전화번호 하이픈 넣기
 */
export const phoneRex = phoneNum => {
  if (phoneNum) {
    return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }

  return phoneNum;
};

/**
 * 주소설정 (영어, 숫자)
 * @param phoneNumber
 * @returns {boolean}
 */
export const isValidAddress = value => {
  const engNum = /^[a-zA-Z0-9]*$/;
  return engNum.test(value);
};

/**
 * 개발모드 판정
 * @param phoneNumber
 * @returns {boolean}
 */
export const isDebuggableEnv = () => {
  const host = window.location.host;
  const isDebuggableEnv =
    host.startsWith('beta-dev.') ||
    host.startsWith('local.') ||
    host.startsWith('localhost') ||
    host.startsWith('127.0.0.1') ||
    host.indexOf('.local') > 0 ||
    host.startsWith('local-');
  return isDebuggableEnv;
};

/**
 * 카톡 인앱 브라우저에서 창을 닫는다. 동의서/알림장 등에서 사용
 * @returns {boolean}
 */
export const closeKakaoInAppBrowser = () => {
  try {
    window.close();
    window.location.href = 'kakaotalk://inappbrowser/close';
    return false;
  } catch (ignorable) {
    console.log(ignorable);
  }
};

/**
 * 원화 금액 3자리 콤마 표시함
 * @param price
 * @returns {String}
 */
export const wonPriceStr = price => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * 문자 + 숫자 정렬 기능 (오름차순)
 * @param price
 * @returns {String}
 */
export const sortByMenuCodeAsc = arr => {
  return arr.sort(function (a, b) {
    return a.menu_code.localeCompare(b.menu_code, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};

/**
 * 날짜 yyyymmddhhmmss
 */
export const getDateTimeString = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  return date.getFullYear() + month + day + hour + minute + second;
};

/**
 * 날짜 yyyymmdd
 */
export const getTodayDateString = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  return date.getFullYear() + '-' + month + '-' + day;
};

/**
 * 날짜 yy.mm.dd
 */
export const getDateString = dValue => {
  const date = new Date(dValue);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear().toString().substring(2, 4);
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  return year + '.' + month + '.' + day;
};

/**
 * 주문 날짜 일시 Format
 */
export const getOrderDateTimeStr = (mDateTime, type) => {
  const date = new Date(mDateTime);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  let result = date.getFullYear().toString().substring(2, 4) + '.' + month + '.' + day + ' ' + hour + ':' + minute;

  if (type === 1) {
    result = date.getFullYear().toString().substring(2, 4) + '.' + month + '.' + day;
  }

  return result;
};

/**
 * 날짜 mm/dd hh:mm
 */
export const getOrderDateTimeString = tDate => {
  const date = new Date(tDate);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;

  return month + '/' + day + ' ' + hour + ':' + minute;
};

/**
 * set localStorage
 */
export const setLocalStorage = (key, value) => {
  console.log(`[setLocalStorage] : ${key} / ${value}`);
  localStorage.setItem(key, value);
};

/**
 * del localStorage
 */
export const delLocalStorage = key => {
  console.log(`[delLocalStorage] : ${key}`);
  localStorage.removeItem(key);
};

/**
 * get localStorage (Async)
 */
export const getLocalStorage = key => {
  const value = localStorage.getItem(key);
  //console.log(`[getLocalStorage] : ${key} / ${value}`);
  return value;
};

/**
 * get localStorage (Sync)
 */
export const getLocalStorageSync = key => {
  return new Promise((resolve, reject) => {
    const value = localStorage.getItem(key);
    console.log(`[getLocalStorageSync] : ${key} / ${value}`);
    resolve(value);
  });
};

/**
 * 로그인 유무
 */
export const isLogin = () => {
  if (localStorage.getItem(APP_KEY_IS_LOGIN) === 'true' && localStorage.getItem(APP_KEY_USER_STATUS) > 0) {
    return true;
  } else {
    return false;
  }
};

/**
 * 로그인 처리
 */
export const processLogIn = (userEmail, userAlias, userStatus, uuid, zone_code) => {
  localStorage.setItem(APP_KEY_USER_EMAIL, userEmail);
  localStorage.setItem(APP_KEY_USER_ALIAS, userAlias);
  localStorage.setItem(APP_KEY_USER_STATUS, userStatus);
  localStorage.setItem(APP_KEY_USER_UUID, uuid);
  localStorage.setItem(APP_KEY_IS_LOGIN, true);
  localStorage.setItem(APP_KEY_USER_ZONE_CODE, zone_code);
};

/**
 * 로그아웃 처리
 */
export const processLogOut = () => {
  localStorage.removeItem(APP_KEY_USER_EMAIL);
  localStorage.removeItem(APP_KEY_USER_ALIAS);
  localStorage.removeItem(APP_KEY_USER_STATUS);
  localStorage.removeItem(APP_KEY_USER_ROOM);
  localStorage.removeItem(APP_KEY_USER_ZONE_CODE);
  localStorage.removeItem(APP_KEY_SNS_TYPE);
  localStorage.removeItem(APP_KEY_USER_UUID);
  localStorage.removeItem(APP_KEY_POID);
  localStorage.removeItem(APP_KEY_POID_CONCIERGE);
  localStorage.setItem(APP_KEY_IS_LOGIN, false);
};

/**
 * 랜덤 숫자 6자리
 */
export const randomInt6 = () => {
  return randomInteger(100000, 999999);
};

export const showAlertConfirm = msg => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-msg">{nl2br(msg)}</div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

export const showAlertConfirmType2 = msg => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-msg">{nl2br(msg)}</div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name-type2',
    };
    confirmAlert(options);
  });
};

export const showAlertConfirmType3 = msg => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-msg" style={{fontFamily: 'Noto Sans KR'}}>
              {nl2br(msg)}
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 비상선언
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showEmergencyAlertPopup = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergency />
              </div>
              <div className="react-confirm-custom-emergency-title">
                지금은 서비스 점검을 위해
                <br />
                일시적으로 주문이 불가합니다.
              </div>
              <br />
              <div>
                서비스 이용에 불편을 드려 죄송합니다.
                <br />
                빠른 시간 내 안정적인 서비스를
                <br />
                제공할 수 있도록 노력하겠습니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 주문폭주
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showOrderHeavyAlertPopup = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergency />
              </div>
              <div className="react-confirm-custom-emergency-title">
                현재 주문이 일시적으로 많아
                <br />
                주문이 불가합니다.
              </div>
              <br />
              <div>
                잠시 후 다시 시도 부탁드립니다.
                <br />
                빠른 시간 내 안정적인 서비스를
                <br />
                제공할 수 있도록 노력하겠습니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 기상지연 (오픈)
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showWeatherDelayAlertPopup = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergency />
              </div>
              <div className="react-confirm-custom-emergency-title">
                지금은 기상변화로
                <br />
                일시적으로 주문이 불가합니다.
              </div>
              <br />
              <div>
                서비스 이용에 불편을 드려 죄송합니다.
                <br />
                기상상황에 따라 다시 운영되오니
                <br />
                조금만 기다려주세요.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 재정비 기간
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showRechargeAlertPopup = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergency />
              </div>
              <div className="react-confirm-custom-emergency-title">
                [ 서비스 정비 기간 안내 ]
                <br />
                23년 1월 정식 출시 예정입니다.
              </div>
              <br />
              <div>
                서비스 재정비 후 정식 출시합니다.
                <br />
                조금만 기다려 주세요.
                <br />
                감사합니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 임시휴무 - 매니저 이슈
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showEmergencyAlertPopupByManager = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergency />
              </div>
              <div className="react-confirm-custom-emergency-title">
                <b>해당 구역 매니저 휴무로</b>
                <br />
                {isTimeA() ? <>(오전) 파트 주문이 불가합니다.</> : <>(오후) 파트 주문이 불가합니다.</>}
                <br />
                이용에 불편을 드려 죄송합니다.
                <br />
              </div>
              <br />
              <div>
                안정적인 서비스 운영을 위해
                <br />
                더욱 노력하겠습니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 임시휴무 - 기상악화
 * @param msg
 * @returns {Promise<unknown>}
 */
export const showEmergencyAlertPopupByWeather = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div>
                <IcoEmergencyByWeather />
              </div>
              <div className="react-confirm-custom-emergency-title">
                기상변화로 인한
                <br />
                부룸 서비스 운영 중지 안내
              </div>
              <br />
              <div>
                부룸 매니저의 안전을 고려하여
                <br />
                부룸 서비스 운영 중지 안내 드립니다.
                <br />
                너른 양해 부탁 드립니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * 부룸 정기 휴무 팝업
 * @returns {Promise<unknown>}
 */
export const showHolidayAlertPopup = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom">
          <div className="react-confirm-custom-wrap">
            <div className="react-confirm-custom-emergency">
              <div className="react-confirm-custom-holiday-title">
                <b>매주 월요일</b> 정기 휴무 안내
              </div>
              <br />
              <div>
                부룸을 이용하시는 고객님께
                <br />
                감사의 말씀드리며
                <br />
                더 나은 서비스 품질 향상을 위해
                <br />
                <b>매주 월요일</b>이 정기 휴무일로
                <br />
                지정되었음을 안내드립니다.
              </div>
            </div>
            <div className="react-confirm-custom-btn">
              <button
                onClick={() => {
                  onClose();
                  resolve(true);
                }}>
                확인
              </button>
            </div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

export const showAlertPutMenu = () => {
  return new Promise(function (resolve, reject) {
    const options = {
      childrenElement: () => <div />,
      customUI: ({onClose}) => (
        <div className="react-confirm-custom-v2">
          {setTimeout(() => {
            resolve(true);
            onClose();
          }, 1000)}
          <div className="react-confirm-custom-wrap-v2">
            <div className="react-confirm-custom-msg-v2">메뉴 담기 완료</div>
          </div>
        </div>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  });
};

/**
 * TOID 체크 및 발급
 */
export const checkToid = () => {
  return new Promise((resolve, reject) => {
    if (isLogin()) {
      const userId = getLocalStorage(APP_KEY_USER_UUID);
      Service.getToidByUser(userId).then(result => {
        if (result.length > 0) {
          console.log('[checkToid] 기발급된 TOID : ' + result[0].toid);
          setLocalStorage(APP_KEY_TOID, result[0].toid);
          resolve(true);
        } else {
          Service.createTOID(userId).then(() => {
            Service.getToidByUser(userId).then(result => {
              console.log('[checkToid] 신규발급 TOID : ' + result[0].toid);
              setLocalStorage(APP_KEY_TOID, result[0].toid);
              resolve(true);
            });
          });
        }
      });
    } else {
      console.log('[checkToid] 미로그인 상태');
      resolve(false);
    }
  });
};

/**
 * 컨시어지 TOID 체크 및 발급
 */
export const checkConciergeToid = () => {
  return new Promise((resolve, reject) => {
    if (isLogin()) {
      const userId = getLocalStorage(APP_KEY_USER_UUID);
      Service.getConciergeToidByUser(userId).then(result => {
        if (result.length > 0) {
          console.log('[checkConciergeToid] 기발급된 TOID : ' + result[0].toid);
          setLocalStorage(APP_KEY_TOID_CONCIERGE, result[0].toid);
          resolve(true);
        } else {
          Service.createConciergeTOID(userId).then(() => {
            Service.getConciergeToidByUser(userId).then(result => {
              console.log('[checkConciergeToid] 신규발급 TOID : ' + result[0].toid);
              setLocalStorage(APP_KEY_TOID_CONCIERGE, result[0].toid);
              resolve(true);
            });
          });
        }
      });
    } else {
      console.log('[checkConciergeToid] 미로그인 상태');
      resolve(false);
    }
  });
};

/**
 * State to String
 */
export const cycleStateToStr = state => {
  let result = '';
  switch (state) {
    case ROOM_SERVICE_STATE_0_BASKET:
      result = '장바구니';
      break;
    case ROOM_SERVICE_STATE_1_DONE_PAY:
      result = '결제완료';
      break;
    case ROOM_SERVICE_STATE_2_WAIT_ORDER:
      result = '접수대기';
      break;
    case ROOM_SERVICE_STATE_3_1_NORMAL_RECEIPT:
      result = '접수완료';
      break;
    case ROOM_SERVICE_STATE_3_2_DELAY_RECEIPT:
      result = '접수완료';
      break;
    case ROOM_SERVICE_STATE_4_DELAY_DELIVERY:
      result = '배달지연';
      break;
    case ROOM_SERVICE_STATE_5_COMPLETE_PICKUP:
      result = '픽업완료';
      break;
    case ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY:
      result = '배달완료';
      break;
    case ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND:
      result = '환불완료';
      break;
    case 100:
      result = '접수완료';
      break;
    case 101:
      result = '배송시작';
      break;
    case 101:
      result = '배송완료';
      break;
    case -2:
      result = '환불완료';
      break;
    default:
      break;
  }
  return result;
};

/**
 * State to String
 */
export const cycleConciergeStateToStr = state => {
  let result = '';
  switch (state) {
    case 0:
      result = '장바구니';
      break;
    case CONCIERGE_STATE_1_DONE_PAY:
      result = '결제완료';
      break;
    case CONCIERGE_STATE_10_NORMAL_RECEIPT:
      result = '접수완료';
      break;
    case CONCIERGE_STATE_20_SCHEDULE_ING:
      result = '일정조율';
      break;
    case CONCIERGE_STATE_30_SCHEDULE_DONE:
      result = '일정확인';
      break;
    case CONCIERGE_STATE_40_SERVICE_DONE:
      result = '시행완료';
      break;
    case -1:
      result = '환불취소';
      break;
    default:
      break;
  }
  return result;
};

/**
 * 브라우저 OS 정보
 */
export const getOS = () => {
  let currentOS;
  let mobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase());

  if (mobile) {
    // 유저에이전트를 불러와서 OS를 구분합니다.
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search('android') > -1) currentOS = 'android';
    else if (userAgent.search('iphone') > -1 || userAgent.search('ipod') > -1 || userAgent.search('ipad') > -1) currentOS = 'ios';
    else currentOS = 'else';
  } else {
    // 모바일이 아닐 때
    currentOS = 'nomobile';
  }
  return currentOS;
};

/**
 * 두 Time 일수 차이
 */
export const getDateDiff = targetDay => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  const date1 = new Date(year + '-' + month + '-' + day);
  const date2 = new Date(targetDay);

  const diffDate = date1.getTime() - date2.getTime();

  return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
};

/**
 * HH:mm
 */
export const parseDateHHmm = timeStr => {
  const temp = timeStr.split(':');
  return temp[0] + ':' + temp[1];
};

/**
 * Converter Date Type
 */
export const convDateType = (tYear, tMonth, tDay, tHour, tMin) => {
  return new Date(tYear, tMonth, tDay, tHour, tMin);
};

/**
 * 부룸 운영 시간 여부 반환
 */
export const isVooroomCheckOnTime = () => {
  let result = false;
  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth();
  const nowDay = nowDate.getDate();
  const nowTime = nowDate.getTime();

  const tOpenTime = isWeekend() ? VOOROOM_OPERATION_TIME_WEEKEND_OPEN.split(':') : VOOROOM_OPERATION_TIME_OPEN.split(':');
  const tCloseTime = VOOROOM_OPERATION_TIME_CLOSE.split(':');
  const tBreakStartTime = isWeekend() ? VOOROOM_OPERATION_BREAK_TIME_WEEKEND_START.split(':') : VOOROOM_OPERATION_BREAK_TIME_START.split(':');
  const tBreakEndTime = VOOROOM_OPERATION_BREAK_TIME_END.split(':');

  const vOpenTime = convDateType(nowYear, nowMonth, nowDay, tOpenTime[0], tOpenTime[1]);
  const vCloseTime = convDateType(nowYear, nowMonth, nowDay, tCloseTime[0], tCloseTime[1]);
  const vBreakStTime = convDateType(nowYear, nowMonth, nowDay, tBreakStartTime[0], tBreakStartTime[1]);
  const vBreakEdTime = convDateType(nowYear, nowMonth, nowDay, tBreakEndTime[0], tBreakEndTime[1]);

  /*console.log('[부룸 오픈시간] : ' + vOpenTime);
  console.log('[부룸 마감시간] : ' + vCloseTime);
  console.log('[부룸 브레이크 시작] : ' + vBreakStTime);
  console.log('[부룸 브레이크 종료] : ' + vBreakEdTime);*/

  // 01 - 운영 시간 체크
  if (nowTime >= vOpenTime.getTime() && nowTime < vCloseTime) {
    result = true;
  }

  // 02 - 브레이크 시간 체크
  if (nowTime >= vBreakStTime.getTime() && nowTime < vBreakEdTime) {
    result = false;
  }

  // - 화이트리스트
  const email = getLocalStorage(APP_KEY_USER_EMAIL);
  if (isWhiteList(email)) {
    //console.log('[화이트리스트 - ON] ' + email);
    return true;
  }

  return result;
};

/**
 * 부룸 브레이크 타입 체크
 */
export const isVooroomCheckBreakTime = () => {
  let result = false;
  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth();
  const nowDay = nowDate.getDate();
  const nowTime = nowDate.getTime();

  const tBreakStartTime = isWeekend() ? VOOROOM_OPERATION_BREAK_TIME_WEEKEND_START.split(':') : VOOROOM_OPERATION_BREAK_TIME_START.split(':');
  const tBreakEndTime = VOOROOM_OPERATION_BREAK_TIME_END.split(':');
  const vBreakStTime = convDateType(nowYear, nowMonth, nowDay, tBreakStartTime[0], tBreakStartTime[1]);
  const vBreakEdTime = convDateType(nowYear, nowMonth, nowDay, tBreakEndTime[0], tBreakEndTime[1]);

  /*console.log('[부룸 브레이크 시작] : ' + vBreakStTime);
  console.log('[부룸 브레이크 종료] : ' + vBreakEdTime);*/

  if (nowTime >= vBreakStTime.getTime() && nowTime < vBreakEdTime) {
    result = true;
  }

  return result;
};

/**
 * 스토어 운영 시간 여부 반환
 */
export const isStoreCheckOnTime = (TIME_OPEN, TIME_CLOSE, BREAK_TIME_START, BREAK_TIME_END) => {
  let result = false;
  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth();
  const nowDay = nowDate.getDate();
  const nowTime = nowDate.getTime();

  const tOpenTime = TIME_OPEN.split(':');
  const tCloseTime = TIME_CLOSE.split(':');

  const sOpenTime = convDateType(nowYear, nowMonth, nowDay, tOpenTime[0], tOpenTime[1]);
  const sCloseTime = convDateType(nowYear, nowMonth, nowDay, tCloseTime[0], tCloseTime[1]);

  console.log('[스토어 오픈시간] : ' + sOpenTime);
  console.log('[스토어 마감시간] : ' + sCloseTime);

  // 01 - 운영 시간 체크
  if (nowTime >= sOpenTime.getTime() && nowTime < sCloseTime) {
    result = true;
  }

  // 02 - 브레이크 시간 체크
  if (BREAK_TIME_START !== null && BREAK_TIME_END !== null) {
    const tBreakStartTime = BREAK_TIME_START.split(':');
    const tBreakEndTime = BREAK_TIME_END.split(':');
    const sBreakStTime = convDateType(nowYear, nowMonth, nowDay, tBreakStartTime[0], tBreakStartTime[1]);
    const sBreakEdTime = convDateType(nowYear, nowMonth, nowDay, tBreakEndTime[0], tBreakEndTime[1]);
    console.log('[스토어 브레이크 시작] : ' + sBreakStTime);
    console.log('[스토어 브레이크 종료] : ' + sBreakEdTime);
    if (nowTime >= sBreakStTime.getTime() && nowTime < sBreakEdTime) {
      result = false;
    }
  }

  return result;
};

/**
 * 메뉴별 운영 시간 체크 (평일 / 주말 / 런치 / 디너)
 * true : 주문가능 / false 주문불가
 */
export const isCheckMenuTimeService = menuTime => {
  //--------------------------
  //    P : 평일
  //    W : 주말
  //    F : 모두
  //---------------------------
  //    A : 런치
  //    B : 디너
  //    F : 모두
  console.log('[isCheckMenuTimeService]', menuTime);

  const parseTime = menuTime.split(',');

  // ----------- 평일
  if (parseTime[0] === 'P') {
    // 평일인가?
    if (!isWeekend()) {
      // 전체 메뉴 인가?
      if (parseTime[1] === 'F') {
        return true;
      } else {
        // 런치 메뉴 인가?
        if (parseTime[1] === 'A' && isTimeA()) {
          return true;
        }
        // 디너 메뉴 인가?
        if (parseTime[1] === 'B' && !isTimeA()) {
          return true;
        }
      }
    }
  }

  // ----------- 주말
  if (parseTime[0] === 'W') {
    // 주말인가?
    if (isWeekend()) {
      // 전체 메뉴 인가?
      if (parseTime[1] === 'F') {
        return true;
      } else {
        // 런치 메뉴 인가?
        if (parseTime[1] === 'A' && isTimeA()) {
          return true;
        }
        // 디너 메뉴 인가?
        if (parseTime[1] === 'B' && !isTimeA()) {
          return true;
        }
      }
    }
  }

  // ----------- 모두 (전체)
  if (parseTime[0] === 'F') {
    // 전체 메뉴 인가?
    if (parseTime[1] === 'F') {
      return true;
    } else {
      // 런치 메뉴 인가?
      if (parseTime[1] === 'A' && isTimeA()) {
        return true;
      }
      // 디너 메뉴 인가?
      if (parseTime[1] === 'B' && !isTimeA()) {
        return true;
      }
    }
  }

  console.log('[isCheckMenuTimeService]', false);
  return false;
};

/**
 * 부룸 운영 시간 여부 반환
 */
export const isCheckVRService = (item, isAlert) => {
  return new Promise((resolve, reject) => {
    if (item === null) resolve(false);

    // - 화이트리스트
    const email = getLocalStorage(APP_KEY_USER_EMAIL);
    if (isWhiteList(email)) {
      console.log('[화이트리스트 - ON] ' + email);
      resolve(true);
      return;
    }

    // 공동구매 상품
    if (isTogetherProduct(item.product_id) && item.is_open === 'Y') {
      resolve(true);
      return;
    }

    if (isVooroomCheckOnTime()) {
      if (item.is_open === 'Y' && item.is_order === 'Y') {
        let isStoreAvail = true;
        if (item.open_time !== null && item.close_time !== null && isStoreCheckOnTime(item.open_time, item.close_time, item.break_start_time, item.break_end_time) === false) {
          isStoreAvail = false; // 매장 운영 시간 X
        }

        if (isStoreAvail) {
          resolve(true);
        } else {
          if (item.break_start_tim !== null && item.break_end_time !== null) {
            isAlert === true &&
              showAlertConfirmType3(`스토어에서 메뉴를 준비중입니다.\n\n[스토어 운영시간 안내]
            ☀️️ ${parseDateHHmm(item.open_time)}~${parseDateHHmm(item.break_start_time)}
            🌙 ${parseDateHHmm(item.break_end_time)}~${parseDateHHmm(item.close_time)}`);

            resolve(false);
          } else {
            isAlert === true &&
              showAlertConfirmType3(`스토어에서 메뉴를 준비중입니다.\n\n[스토어 운영시간 안내]
            🕒 ${parseDateHHmm(item.open_time)}~${parseDateHHmm(item.close_time)}`);
            resolve(false);
          }
        }
      } else {
        isAlert === true && showAlertConfirm('현재 스토어 사정으로\n주문이 불가합니다.');
        resolve(false);
      }
      resolve(false);
    } else {
      if (isAlert === true) {
        isWeekend()
          ? showAlertConfirmType3('지금은 부룸 서비스 운영 준비로\n주문이 어렵습니다.\n\n[부룸 운영시간 안내]\n☀️️ 11:00~14:00\n🌙 17:00~20:00')
          : showAlertConfirmType3('지금은 부룸 서비스 운영 준비로\n주문이 어렵습니다.\n\n[부룸 운영시간 안내]\n☀️️ 11:00~14:00\n🌙 17:00~20:00');
      }
      resolve(false);
    }
  });
};

/**
 * 부룸 휴무일 인가? (매주 월요일)
 */
export const isVooroomHoliday = () => {
  // const week = new Array('일', '월', '화', '수', '목', '금', '토');
  const todayNum = new Date().getDay();

  // - 화이트 리스트
  const email = getLocalStorage(APP_KEY_USER_EMAIL);
  if (isWhiteList(email)) {
    return false;
  }

  return todayNum === 1 ? true : false;
};

/**
 * 롯데 아파트+오피스텔 동호수 체크 로직 추가
 */
export const isCheckLotteAddr = (dong, ho) => {
  const isDongOp = isNaN(dong); // T : 오피 , F : 아파트
  const endHo = ho.slice(-2); // 끝에서 2자리

  console.log('[isDongOp] ' + isDongOp);
  console.log('[endHo] ' + endHo);

  // 아파트 동을 입력하고 06~18 라인을 입력한 경우 (오피 라인임)
  if (isDongOp === false) {
    // 아파트는 1~5호 라인은 체크 안함.
    if (endHo === '01' || endHo === '02' || endHo === '03' || endHo === '04' || endHo === '05') {
      return false;
    }
  } else {
    // 오피스텔 체크안함.
    return false;
  }

  // 경고 팝업창 출력
  return true;
};

/**
 * 내부 화이트 허용 테스트
 */
export const isWhiteList = email => {
  let whiteList = ['ohuny@nate.com', 'neo@vooroom.kr', 'tgdkjs3907@naver.com'];
  let result = false;

  whiteList.forEach((item, idx) => {
    if (item === email) result = true;
  });
  return result;
};

/**
 * 주말 인가?
 */
export const isWeekend = () => {
  //const week = new Array('일', '월', '화', '수', '목', '금', '토');
  const todayNum = new Date().getDay();
  return todayNum === 0 || todayNum === 6 ? true : false;
};

/**
 * A타임 인가?
 */
export const isTimeA = () => {
  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth();
  const nowDay = nowDate.getDate();
  const nowTime = nowDate.getTime();

  const vStartA = convDateType(nowYear, nowMonth, nowDay, 1, 0);
  const vEndA = convDateType(nowYear, nowMonth, nowDay, 14, 0);
  const vStartB = convDateType(nowYear, nowMonth, nowDay, 14, 0);
  const vEndB = convDateType(nowYear, nowMonth, nowDay, 1, 0);

  let result = false;

  if (nowTime >= vStartA.getTime() && nowTime < vEndA) {
    result = true; // A타임
  }

  if (nowTime >= vStartB.getTime() && nowTime < vEndB) {
    result = false; // B타임
  }

  return result;
};

/**
 * 공동구매 상품 인가?
 */
export const isTogetherProduct = pid => {
  const pidList = ['P_00003623', 'P_00003624', 'P_00003625', 'P_00003626', 'P_00003627'];
  console.log('[isTogetherProduct] product_id : ', pid);
  for (const item of pidList) {
    if (item === pid) {
      console.log('[isTogetherProduct] same list id : ', item);
      return true;
    }
  }

  return false;
};

/**
 * 추석 이벤트 상품 인가?
 */
export const isEventProduct = pid => {
  const pidList = ['P_00003775', 'P_00003776', 'P_00003777', 'P_00003778', 'P_00003779', 'P_00003780'];
  console.log('[isEventProduct] product_id : ', pid);
  for (const item of pidList) {
    if (item === pid) {
      console.log('[isEventProduct] same list id : ', item);
      return true;
    }
  }

  return false;
};

/**
 * 전화번호 복호화
 */
export const getDecryptPhoneNumber = phone => {
  const rePhone = phone.replaceAll(' ', '+');
  const bytes = CryptoJS.AES.decrypt(rePhone, 'vooroomKey');
  const decryptPhone = bytes.toString(CryptoJS.enc.Utf8);
  return decryptPhone;
};

/**
 * 컨시어지 타입 반환 (R/T)
 */
export const getConciergeTypeIsR = mCode => {
  let result = false;
  if (mCode === '4_DT2_G1_S35') {
    result = true;
  }
  return result;
};

/**
 * 룸서비스 운영 여부 (ON/OFF)
 */
export const isRunRoomService = (isVooroomHoliday, isEmergency, isServiceTime) => {
  let result = false;
  if (isVooroomHoliday === false && isEmergency === 'N' && isServiceTime) result = true;
  return result;
};
