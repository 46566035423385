import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import ImgTransparent1 from '../../assets/img/img_transparent1.png';
import {ReactComponent as IcoCoupon} from '../../assets/img/ico_coupon1.svg';
import {ReactComponent as IcoPoint} from '../../assets/img/ico_point1.svg';
import {ReactComponent as IcoOrder} from '../../assets/img/ico_order1.svg';
import {ReactComponent as DefaultProfile} from '../../assets/img/ico_profile_default.svg';
import {ReactComponent as DefaultApt} from '../../assets/img/ico_apt_default.svg';
import * as Utils from '../../shared/util';
import {APP_KEY_USER_ALIAS, APP_KEY_USER_EMAIL, APP_KEY_USER_ROOM, APP_KEY_USER_UUID} from '../../shared/Constants';
import * as Service from '../../shared/apiService';

class MySetting extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      roomInfo: null,
      aptName: '',
      aptDong: '',
      aptHo: '',
      isSangga: false,
      couponCount: 0,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    window.scrollTo(0, 0);

    // - 사용자 정보 가져오기
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    if (uuid) {
      Service.getUserInfoByUuid(uuid).then(result => {
        console.log(result);

        // 배달비 무료 쿠폰
        const mCount = result ? result.free_coupon : null;
        if (mCount) {
          this.setState({couponCount: mCount});
        }
      });
    } else {
      console.log('uuid is null.');
    }

    Utils.getLocalStorageSync(APP_KEY_USER_ROOM).then(rInfo => {
      if (rInfo) {
        const parseInfo = rInfo.split('/');
        if (parseInfo.length > 2) {
          this.setState({aptName: parseInfo[0]}, () => {
            if (parseInfo[0] === '동탄역앨리스빌') {
              this.setState({isSangga: true});
            }
          });
          this.setState({aptDong: parseInfo[1]});
          this.setState({aptHo: parseInfo[2]});
          this.setState({roomInfo: rInfo});
        }
      }
    });
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const {roomInfo, aptName, aptDong, aptHo, isSangga, couponCount} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd hd-v2">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                //this.props.history.replace('/');
                this.props.history.goBack();
              }}
            />
            <div className="hd-tit">내설정</div>
          </div>
        </header>

        <section className="setting">
          <div className="top">
            <div className="user-profile">
              <div className="img-group">
                <DefaultProfile className="thumb thumb-backimg1" />
              </div>

              <Link to="/setting/myinfo" className="user-info">
                <div className="user-name">{Utils.getLocalStorage(APP_KEY_USER_ALIAS)}</div>
                <div className="user-email">{Utils.getLocalStorage(APP_KEY_USER_EMAIL)}</div>
              </Link>
            </div>

            {roomInfo ? (
              <Link to="/setting/address" className="user-address">
                <div className="img-group">
                  <DefaultApt className="thumb" />
                </div>

                <div className="address-info">
                  <div className="apt-name">{aptName}</div>
                  <div className="apt-detail">
                    {isSangga ? (
                      <span>{aptDong}</span>
                    ) : (
                      <>
                        <span>{aptDong} 동</span>
                        <span>{aptHo} 호</span>
                      </>
                    )}
                  </div>
                </div>
              </Link>
            ) : (
              <Link to="/setting/address">
                <div className="user-address-empty">
                  <div className="user-address-empty-box">거주중인 아파트를 등록해 주세요</div>
                </div>
              </Link>
            )}

            <ol className="user-state">
              <li>
                <Link
                  to={{
                    pathname: '/setting/coupon',
                    state: {
                      couponCount: couponCount,
                    },
                  }}>
                  <div className="ico">
                    <IcoCoupon />
                  </div>
                  <div className="txt">
                    <>
                      <span>{couponCount}</span> 장
                    </>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="/setting/request">
                  <div className="ico">
                    <IcoOrder />
                  </div>
                  <div className="txt">요청사항</div>
                </Link>
              </li>

              <li>
                <Link to="/setting/point">
                  <div className="ico">
                    <IcoPoint />
                  </div>
                  <div className="txt">
                    <span>0</span> 포인트
                  </div>
                </Link>
              </li>
            </ol>
          </div>

          <ol className="list-setting-menu">
            <li>
              <Link to="/setting/cs">고객센터</Link>
            </li>
            <li>
              <Link to="/event">이벤트</Link>
            </li>
            {/*<li>
              <a href="#">환경설정</a>
            </li>*/}
            <li>
              <Link to="/notice">공지사항</Link>
            </li>
            <li style={{borderBottom: '8px solid #f6f6f6'}}>
              {/*<a
                onClick={() => {
                  Utils.processLogOut();
                  Utils.showAlertConfirm("로그아웃 되었습니다.").then(
                    (result) => {
                      this.props.history.replace("/");
                    }
                  );
                }}
              >
                로그아웃
              </a>*/}
              <Link to="/logout">로그아웃</Link>
            </li>

            <li
              onClick={() => {
                Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://forms.gle/x9aYwt5YwJ4hi6Cc7');
              }}>
              <a>부룸 스토어 입점 문의하기</a>
            </li>

            <li>
              <a href="https://vooroom-concierge.kr/applink">부룸 매니저 지원하기</a>
            </li>

            <li>
              <Link to="/setting/bs">브랜드 철학</Link>
            </li>

            <li>
              <Link to="/setting/company">사업자정보</Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/setting/terms',
                  state: {
                    uuid: 1,
                    title: '서비스 이용약관',
                  },
                }}>
                서비스 이용약관
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/setting/terms',
                  state: {
                    uuid: 2,
                    title: '전자금융거래 이용약관',
                  },
                }}>
                전자금융거래 이용약관
              </Link>
            </li>
            <li>
              <a
                onClick={() => {
                  Utils.sendToRnMessage('RN_STORE_INFO_LINK', 'https://vooroom.kr/terms');
                }}>
                개인정보 처리방침
              </a>
            </li>

            <li>
              <Link to="/withdraw">회원탈퇴</Link>
            </li>
          </ol>
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(MySetting));
