import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as Event1} from '../../assets/img/event_week.svg';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';
import * as Service from '../../shared/apiService';
import {APP_KEY_SNS_TYPE, APP_KEY_USER_EMAIL, APP_KEY_USER_ROOM, APP_KEY_USER_SETTING_BELL, APP_KEY_USER_UUID} from '../../shared/Constants';
import {api} from '../../api';

class RequestList extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      reqBell: '',
      uuid: null,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    this.getUserInfo();
  }

  // -- feature -------------------------------------------------------------------------------
  getUserInfo = () => {
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    console.log(uuid);
    this.setState({uuid: uuid});
    Service.getUserInfoByUuid(uuid).then(result => {
      this.setState({reqBell: result.request_bell});
    });
  };

  updateUserBellInfo = () => {
    const {reqBell, uuid} = this.state;
    if (uuid) {
      api
        .get(`/v1/user/update/bell?uuid=${uuid}&bell=${reqBell}`)
        .then(res => {
          Utils.setLocalStorage(APP_KEY_USER_SETTING_BELL, reqBell);
          this.props.history.replace('/setting');
          Utils.showAlertConfirm('정보가 수정 되었습니다.');
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          console.log('[DONE] updateUserBellInfo');
        });
    } else {
      console.log('[updateUserBellInfo] uuid is Null.');
    }
  };

  // -- handler -------------------------------------------------------------------------------
  handleBellCheck = value => {
    this.setState({reqBell: value});
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {reqBell} = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">요청사항</div>
          </div>
        </header>

        {reqBell && (
          <section className="setting-tv">
            <div className="box-order-info">
              <div className="order-info">
                <ol className="list-total-price">
                  <li>
                    <div className="tit" style={{fontWeight: '600'}}>
                      세대 현관문 벨 호출
                    </div>
                  </li>
                </ol>

                <div className="order-info-etc" style={{lineHeight: '150%'}}>
                  * 공동 현관문 벨 호출 : 보안 이슈로 필수 진행되오니 양해 부탁드립니다.
                  <br />* '아이가 자고 있어요'&nbsp;&nbsp;'개가 짖어요' 문구 메모를 호출기 상단에 부착 요청드립니다.
                </div>
              </div>
              <ol className="list-address" style={{marginTop: '20px'}}>
                <li style={{borderBottom: 'none'}}>
                  <input
                    id="address_ck1"
                    type="radio"
                    name="address_ck"
                    className="ck-custom"
                    checked={reqBell === 'Y' ? true : false}
                    onChange={() => {}}
                    onClick={() => {
                      this.handleBellCheck('Y');
                    }}
                  />
                  <label htmlFor="address_ck1" style={{padding: '16px 16px'}}>
                    벨 호출 해주세요
                  </label>
                </li>

                <li style={{borderBottom: 'none'}}>
                  <input
                    id="address_ck2"
                    type="radio"
                    name="address_ck"
                    className="ck-custom"
                    checked={reqBell === 'N' ? true : false}
                    onChange={() => {}}
                    onClick={() => {
                      this.handleBellCheck('N');
                    }}
                  />
                  <label htmlFor="address_ck2" style={{padding: '16px 16px'}}>
                    벨 호출 하지 말아 주세요
                  </label>
                </li>
              </ol>
            </div>

            <div className="btn-floating-bottom">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-custom btn-custom-v1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-custom"
                  onClick={() => {
                    this.updateUserBellInfo();
                  }}>
                  등록
                </button>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(RequestList));
