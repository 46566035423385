import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/common.css';
import * as Utils from '../../shared/util';
import {api} from '../../api';
import {APP_KEY_USER_STATUS, APP_KEY_USER_UUID} from '../../shared/Constants';
import CryptoJS from 'crypto-js';
import * as Service from '../../shared/apiService';

const TimerAuthTime = 180;
class ReAuthPhone extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      genAuthNum: null,
      inputAuthNum: '',
      inputPhoneNum: '',
      timerCount: TimerAuthTime,
      timer: null,
      phoneNumLock: false,
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // - 사용자 정보 가져오기
    const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
    this.setState({userId: uuid});

    console.log('[ReAuthPhone] ' + uuid);
  }

  // -- feature -------------------------------------------------------------------------------
  updateUserStatusAndPhone = (uuid, phone) => {
    api
      .get(`/v1/user/update/status?uuid=${uuid}&status=1&phone=${phone}`)
      .then(res => {
        console.log('[OK] updateUserStatusAndPhone');
        Utils.setLocalStorage(APP_KEY_USER_STATUS, 1);

        // TODO: 마지막에 보던 페이지로 이동시키는 기능 추가
        this.props.history.replace('/');
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        console.log('[DONE] updateUserStatusAndPhone');
      });
  };

  reqSmsAuth = (recipientNo, authNum) => {
    const params = {
      recipientNo: recipientNo,
      authNum: authNum,
    };
    api
      .post(`/v1/oauth/sms`, params)
      .then(res => {
        console.log('reqSmsAuth OK!!');
        const {data} = res;
        console.log(data);
        // 인증번호 전송 성공
        if (data === 'OK') {
          Utils.showAlertConfirm(recipientNo + ' 로\n6자리 인증번호를 전송하였습니다.').then(result => {
            if (result) {
              // 타이머 시작
              const mTimer = setInterval(() => {
                if (this.state.timerCount > 0) {
                  this.setState({timerCount: --this.state.timerCount});
                }
              }, 1000);
              this.setState({timer: mTimer});

              // 인증번호 셋팅 및 시간 초기화
              this.setState({genAuthNum: authNum});
              this.setState({timerCount: TimerAuthTime});
            }
          });
        }
      })
      .finally(() => {});
  };

  // -- handler -------------------------------------------------------------------------------

  /* 인증요청 */
  handleAuthRequest = mode => e => {
    const {inputPhoneNum, timer} = this.state;

    // 앱스토어 테스트 용도
    if (inputPhoneNum === '000000') {
      this.setState({authNum: 123456});
      this.setState({genAuthNum: 123456});
      return;
    }

    // - 재요청시
    if (mode === 'retry') {
      this.setState({genAuthNum: null});
      this.setState({inputAuthNum: ''});
      clearInterval(timer); // 타이머 초기화
    }

    if (Utils.isValidPhoneNumber(inputPhoneNum)) {
      const authNum = Utils.randomInt6();
      console.log('[genAuthNum] ' + authNum);
      this.reqSmsAuth(inputPhoneNum, authNum);
      this.setState({phoneNumLock: true});
    } else {
      // 전화번호 입력 오류
      Utils.showAlertConfirm('전화번호를 정확하게 입력해 주세요.');
    }
  };

  /* 인증완료 */
  handleAuthComplete = () => e => {
    const {genAuthNum, inputAuthNum, timerCount, inputPhoneNum} = this.state;

    console.log('genAuthNum : ' + genAuthNum);
    console.log('inputAuthNum : ' + inputAuthNum);

    if (timerCount > 0) {
      if (genAuthNum === Number(inputAuthNum)) {
        const uuid = Utils.getLocalStorage(APP_KEY_USER_UUID);
        const ciphertext = CryptoJS.AES.encrypt(inputPhoneNum, 'vooroomKey').toString();
        if (uuid && ciphertext) {
          Service.updateUserPhone(uuid, ciphertext).then(() => {
            Utils.showAlertConfirm('수정 완료하였습니다.').then(() => {
              this.props.history.goBack();
            });
          });
        } else {
          Utils.showAlertConfirm('오류가 발생하였습니다.\n고객센터로 문의 바랍니다.').then(() => {
            this.props.history.goBack();
          });
        }
      } else {
        console.log('인증번호가 일치하지 않습니다.');
        Utils.showAlertConfirm('인증번호가 일치하지 않습니다.');
      }
    } else {
      Utils.showAlertConfirm('인증 시간이 만료되었습니다.\n재시도 해주세요.');
    }
  };

  handlePhoneNumber = e => {
    this.setState({inputPhoneNum: e.target.value});
  };

  handleAuthNumber = e => {
    this.setState({inputAuthNum: e.target.value});
  };

  // -- render --------------------------------------------------------------------------------
  renderTimerCount = () => {
    const {timerCount} = this.state;

    let min = Math.floor(timerCount / 60);
    let sec = timerCount % 60;
    if (sec < 10) sec = '0' + sec.toString();
    return (
      <>
        {min}:{sec}
      </>
    );
  };

  render() {
    const {genAuthNum, inputPhoneNum, inputAuthNum, timerCount, phoneNumLock} = this.state;
    return (
      <React.Fragment>
        <header className="header">
          <div className="hd">
            <button
              type="button"
              className="hd-back"
              onClick={() => {
                this.props.history.goBack();
              }}></button>
            <div className="hd-tit">휴대폰 재인증</div>
          </div>
        </header>

        <section className="auth-phone">
          <div className="auth-phone-txt">휴대폰 번호</div>

          <div className="box-auth-phone">
            <input
              type="number"
              pattern="\d*"
              className="inp-frm"
              placeholder="번호 입력(숫자만)"
              value={inputPhoneNum}
              disabled={phoneNumLock}
              onChange={this.handlePhoneNumber}
              style={{borderColor: genAuthNum !== null && '#222'}}
            />
            {genAuthNum === null ? (
              <button type="button" className="btn btn-custom btn-custom-v2" onClick={this.handleAuthRequest()}>
                인증요청
              </button>
            ) : (
              <button type="button" className="btn btn-custom btn-custom-v2-1" onClick={this.handleAuthRequest('retry')}>
                재요청
              </button>
            )}
          </div>
          <div className="box-auth-phone">
            <input
              type="number"
              pattern="\d*"
              className="inp-frm"
              placeholder="인증번호 입력"
              value={inputAuthNum}
              onChange={this.handleAuthNumber}
              style={{borderColor: genAuthNum !== null && '#222'}}
            />
            {genAuthNum && <div className="auth-phone-timer">{timerCount > -1 && this.renderTimerCount()}</div>}
          </div>

          {genAuthNum && (
            <div className="auth-phone-btn">
              <button type="button" className="btn btn-custom" onClick={this.handleAuthComplete()}>
                인증완료
              </button>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
  };
}

export default withRouter(connect(select)(ReAuthPhone));
