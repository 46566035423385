import {api} from '../api';
import * as Utils from './util';
import {APP_KEY_TOID, APP_KEY_TOID_CONCIERGE} from './Constants';

// -------------------------------- [ 컨시어지 ] -------------------------------- //

export const createConciergeTOID = userId => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      user_id: userId,
    };
    api
      .post(`/v1/concierge/toid`, postParams)
      .then(res => {
        console.log('[OK] createConciergeTOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] createConciergeTOID');
      });
  });
};

export const getConciergeToidByUser = user_id => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/concierge/toidByUser?user_id=${user_id}`)
      .then(res => {
        console.log('[OK] getConciergeToidByUser');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getConciergeToidByUser');
      });
  });
};

export const getConciergeBoidByProduct = (toid, product_id) => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/concierge/boidByProduct?toid=${toid}&product_id=${product_id}`)
      .then(res => {
        console.log('[OK] getConciergeBoidByProduct');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getConciergeBoidByProduct');
      });
  });
};

export const createConciergeBOID = (toid, product_id, store_id, boid_price, count) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      toid: toid,
      product_id: product_id,
      store_id: store_id,
      boid_price: boid_price,
      count: count,
    };
    api
      .post(`/v1/concierge/boid`, postParams)
      .then(res => {
        console.log('[OK] createConciergeBOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] createConciergeBOID');
      });
  });
};

export const updateConciergeBOID = (toid, product_id, count) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      toid: toid,
      product_id: product_id,
      count: count,
    };
    api
      .post(`/v1/concierge/boid/update`, postParams)
      .then(res => {
        console.log('[OK] updateConciergeBOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateConciergeBOID');
      });
  });
};

export const getConciergeMyBasket = () => {
  return new Promise(function (resolve, reject) {
    const myToid = Utils.getLocalStorage(APP_KEY_TOID_CONCIERGE);
    if (myToid !== null) {
      api
        .get(`/v1/concierge/basket?toid=${myToid}`)
        .then(res => {
          console.log('[OK] getConciergeMyBasket');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getConciergeMyBasket');
        });
    } else {
      console.log('[getConciergeMyBasket] TOID NULL.');
    }
  });
};

export const getConciergePoidList = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/concierge/poidByUser?user_id=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getConciergePoidList');
        });
    } else {
      console.log('[getConciergePoidList] userId NULL.');
    }
  });
};

export const getCurrentConciergePoidByLogin = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/concierge/poidByLogin?user_id=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getCurrentConciergePoidByLogin');
        });
    } else {
      console.log('[getCurrentConciergePoidByLogin] userId NULL.');
    }
  });
};

export const getConciergePoidInfo = poid => {
  return new Promise(function (resolve, reject) {
    if (poid !== null) {
      api
        .get(`/v1/concierge/poid?poid=${poid}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getConciergePoidInfo');
        });
    } else {
      console.log('[getConciergePoidInfo] poid NULL.');
    }
  });
};

/**
 * 칸시어지 POID 상태 업데이트
 */
export const updateConciergePoidState = (state, poid) => {
  return new Promise(function (resolve, reject) {
    if (state && poid) {
      api
        .get(`v1/concierge/update/poid?state=${state}&poid=${poid}`)
        .then(res => {
          console.log('[OK] updateConciergePoidState');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateConciergePoidState');
        });
    } else {
      console.log('[updateConciergePoidState] state or poid NULL.');
    }
  });
};

/**
 * 칸시어지 BOID 상태 업데이트
 */
export const updateConciergeBoidState = (state, poid) => {
  return new Promise(function (resolve, reject) {
    if (state && poid) {
      api
        .get(`v1/concierge/update/boid?state=${state}&poid=${poid}`)
        .then(res => {
          console.log('[OK] updateConciergeBoidState');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateConciergeBoidState');
        });
    } else {
      console.log('[updateConciergeBoidState] state or poid NULL.');
    }
  });
};
// --------------------------------------------------------------------------- //

// -------------------------------- [ 룸서비스 ] -------------------------------- //

export const createTOID = userId => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      user_id: userId,
    };
    api
      .post(`/v1/order/toid`, postParams)
      .then(res => {
        console.log('[OK] createTOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] createTOID');
      });
  });
};

export const getToid = toid => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/order/toid?toid=${toid}`)
      .then(res => {
        console.log('[OK] getToid');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getToid');
      });
  });
};

export const getToidByUser = user_id => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/order/toidByUser?user_id=${user_id}`)
      .then(res => {
        console.log('[OK] getToidByUser');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getToidByUser');
      });
  });
};

/**
 * BOID 조회 (TOID, PRODUCT_ID)
 */
export const getBoidByProduct = (toid, product_id) => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/order/boidByProduct?toid=${toid}&product_id=${product_id}`)
      .then(res => {
        console.log('[OK] getBoidByProduct');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getBoidByProduct');
      });
  });
};

/**
 * BOID 생성
 */
export const createBOID = (toid, product_id, store_id, boid_price, count) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      toid: toid,
      product_id: product_id,
      store_id: store_id,
      boid_price: boid_price,
      count: count,
    };
    api
      .post(`/v1/order/boid`, postParams)
      .then(res => {
        console.log('[OK] createBOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] createTOID');
      });
  });
};

/**
 * BOID 업데이트 (카운트)
 */
export const updateBOID = (toid, product_id, count) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      toid: toid,
      product_id: product_id,
      count: count,
    };
    api
      .post(`/v1/order/boid/update`, postParams)
      .then(res => {
        console.log('[OK] updateBOID');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateBOID');
      });
  });
};

/**
 * 나의 장바구니 현황
 */
export const getMyBasket = () => {
  return new Promise(function (resolve, reject) {
    const myToid = Utils.getLocalStorage(APP_KEY_TOID);
    if (myToid !== null) {
      api
        .get(`/v1/order/basket?toid=${myToid}`)
        .then(res => {
          console.log('[OK] getMyBasket');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getMyBasket');
        });
    } else {
      console.log('[getMyBasket] TOID NULL.');
    }
  });
};

/**
 * 장바구니 비우기
 */
export const clearMyBasket = () => {
  return new Promise(function (resolve, reject) {
    const myToid = Utils.getLocalStorage(APP_KEY_TOID);
    if (myToid !== null) {
      api
        .get(`/v1/order/boid/deleteAll?toid=${myToid}`)
        .then(res => {
          console.log('[OK] clearMyBasket');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] clearMyBasket : ' + myToid);
        });
    } else {
      console.log('[clearMyBasket] TOID NULL.');
    }
  });
};

/**
 * 구역
 */
export const getZoneAptList = zoneCode => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/zone/apt/list/${zoneCode}`)
      .then(res => {
        const {data} = res;
        console.log('[OK] getZoneAptList');
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getZoneAptList');
      });
  });
};

/**
 * 사용자 정보
 */
export const getUserInfoByUuid = uuid => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/user/get/uuid?uuid=${uuid}`)
      .then(res => {
        console.log('[OK] getUserInfoByUuid');
        const {data} = res;
        resolve(data[0]);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getUserInfoByUuid');
      });
  });
};

/**
 * 사용자 구매 완료 이력
 */
export const getUserBuyCompleteList = uuid => {
  return new Promise(function (resolve, reject) {
    api
      .get(`/v1/user/get/buy/complete?uuid=${uuid}`)
      .then(res => {
        console.log('[OK] getUserBuyCompleteList');
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getUserBuyCompleteList');
      });
  });
};

export const getPoidList = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/order/poidByUser?user_id=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getPoidList');
        });
    } else {
      console.log('[getPoidList] userId NULL.');
    }
  });
};

export const getCurrentPoidByLogin = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/order/poidByLogin?user_id=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getCurrentPoidByLogin');
        });
    } else {
      console.log('[getCurrentPoidByLogin] userId NULL.');
    }
  });
};

export const updateFreeCouponPlus = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/user/update/coupon/plus?uuid=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateFreeCouponPlus');
        });
    } else {
      console.log('[updateFreeCouponPlus] userId NULL.');
    }
  });
};

export const updateFreeCouponMinus = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/user/update/coupon/minus?uuid=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateFreeCouponMinus');
        });
    } else {
      console.log('[updateFreeCouponMinus] userId NULL.');
    }
  });
};

export const getZoneInfo = zoneCode => {
  return new Promise(function (resolve, reject) {
    if (zoneCode !== null) {
      api
        .get(`/v1/zone/get/${zoneCode}`)
        .then(res => {
          const {data} = res;
          resolve(data[0]);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getZoneInfo');
        });
    } else {
      console.log('[getZoneInfo] zoneCode NULL.');
    }
  });
};

export const getPoidInfo = poid => {
  return new Promise(function (resolve, reject) {
    if (poid !== null) {
      api
        .get(`/v1/order/poid?poid=${poid}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] getPoidInfo');
        });
    } else {
      console.log('[getPoidInfo] poid NULL.');
    }
  });
};

/**
 * POID 상태 업데이트
 */
export const updatePoidState = (state, poid) => {
  return new Promise(function (resolve, reject) {
    if (state && poid) {
      api
        .get(`v1/order/update/poid?state=${state}&poid=${poid}`)
        .then(res => {
          console.log('[OK] updatePoidState');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updatePoidState');
        });
    } else {
      console.log('[updatePoidState] state or poid NULL.');
    }
  });
};

/**
 * SOID 상태 업데이트
 */
export const updateSoidState = (state, poid) => {
  return new Promise(function (resolve, reject) {
    if (state && poid) {
      api
        .get(`v1/order/update/soid?state=${state}&poid=${poid}`)
        .then(res => {
          console.log('[OK] updateSoidState');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateSoidState');
        });
    } else {
      console.log('[updateSoidState] state or poid NULL.');
    }
  });
};

/**
 * BOID 상태 업데이트
 */
export const updateBoidState = (state, poid) => {
  return new Promise(function (resolve, reject) {
    if (state && poid) {
      api
        .get(`v1/order/update/boid?state=${state}&poid=${poid}`)
        .then(res => {
          console.log('[OK] updateBoidState');
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateBoidState');
        });
    } else {
      console.log('[updateBoidState] state or poid NULL.');
    }
  });
};

/**
 * 알림톡 전송
 * @param mTemplateCode 템플릿 코드
 * @param mRecipientNo 수신번호
 * @param mTemplateParameter 템플릿 치환 파라미터
 */
export const sendKakaoAlimTalk = (mTemplateCode, mRecipientNo, mTemplateParameter) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      templateCode: mTemplateCode,
      recipientNo: mRecipientNo,
      templateParameter: mTemplateParameter,
    };
    console.log('[sendKakaoAlimTalk] 알릭톡 전송');
    console.log(postParams);

    api
      .post('/common/noti/kakao/alimtalk', postParams)
      .then(res => {
        const {data} = res.data;
        console.log(data);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
      .finally(() => {
        console.log('[DONE] sendKakaoAlimTalk');
        resolve(true);
      });
  });
};

/**
 * 사용자 정보 가져오기
 */
export const getUserInfo = (email, type) => {
  return new Promise(function (resolve, reject) {
    if (email && type) {
      api
        .get(`/v1/user/get/info?email=${email}&type=${type}`)
        .then(res => {
          console.log('[OK] getUserInfo');
          const {data} = res;
          resolve(data[0]);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          console.log('[DONE] getUserInfo');
        });
    } else {
      console.log('[getUserInfo] email or type NULL.');
    }
  });
};

/**
 * 사용자 이름 업데이트
 */
export const updateUserRealName = (userId, userName) => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/user/update/name?uuid=${userId}&name=${userName}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateUserRealName');
        });
    } else {
      console.log('[updateUserRealName] userId, userName NULL.');
    }
  });
};

/**
 * 사용자 전화번호 업데이트
 */
export const updateUserPhone = (userId, userPhone) => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/user/update/phone?uuid=${userId}&phone=${userPhone}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateUserPhone');
        });
    } else {
      console.log('[updateUserPhone] userId, userPhone NULL.');
    }
  });
};
