import produce from 'immer';
import {SET_MENU_SCROLL_TOP, SET_MENU_POPUP_SHOW_1, SET_MENU_POPUP_SHOW_2, SET_MENU_POPUP_SHOW_3, SET_MENU_POPUP_SHOW_EMERGENCY, SET_MENU_POPUP_SHOW_HOLIDAY} from '../actionTypes';

const initialState = {
  menuScrollTop: 0,
  popupShow: true,
  popupShow2: true,
  popupShow3: true,
  emergencyAlertShow: true,
  holidayAlertShow: true,
};

export const appReducer = (state = initialState, action) => {
  if (action.type === SET_MENU_SCROLL_TOP) {
    return produce(state, draft => {
      draft.menuScrollTop = action.value;
    });
  }

  if (action.type === SET_MENU_POPUP_SHOW_1) {
    return produce(state, draft => {
      draft.popupShow = action.value;
    });
  }

  if (action.type === SET_MENU_POPUP_SHOW_2) {
    return produce(state, draft => {
      draft.popupShow2 = action.value;
    });
  }

  if (action.type === SET_MENU_POPUP_SHOW_3) {
    return produce(state, draft => {
      draft.popupShow3 = action.value;
    });
  }

  if (action.type === SET_MENU_POPUP_SHOW_EMERGENCY) {
    return produce(state, draft => {
      draft.emergencyAlertShow = action.value;
    });
  }

  if (action.type === SET_MENU_POPUP_SHOW_HOLIDAY) {
    return produce(state, draft => {
      draft.holidayAlertShow = action.value;
    });
  }

  return state;
};
